import React, { Fragment, useState, useContext, useEffect } from "react";
import AuthContext from "../context/auth/authContext";
import AlertContext from "../context/alerts/alertContext";
import { useHistory } from "react-router-dom";

import frontiniLogo from "../assets/img/frontini-logo.svg";

function Login() {
  const authContext = useContext(AuthContext);
  const { loginUser, auth, getUser, loadingLogin, username } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  let history = useHistory();

  /*   let location = useLocation();

  const dniSocio = new URLSearchParams(location.search).get("dniSocio");

  if(dniSocio) */

  useEffect(() => {
    getUser();
    if (auth) {
      if (username === "33448654") {
        history.push("/socios");
      } else {
        history.push("/");
      }
    }
  }, [auth]);

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (user.email.trim() === "") {
      setAlert("Ingresar Email", "red-300");
      return;
    }
    if (user.password.trim() === "") {
      setAlert("Ingresar Contraseña", "red-300");
      return;
    }
    loginUser(user);
  };

  return (
    <Fragment>
      <div className="flex login">
        <div className="flex-col flex ml-auto mr-auto items-center pt-40 w-full lg:w-2/4">
          <div className="flex justify-center mb-10 px-6">
            <img
              src={frontiniLogo}
              className="lg:h-24 h-16"
              alt="Logo Frontini y asoc"
            />
          </div>
          <form
            onSubmit={onSubmit}
            className="mt-2 flex flex-col lg:w-2/4 w-full"
          >
            <div className="border border-gray-400 p-6">
              <div className="text-center mb-10">
                <h2 className="text-gray-700 font-bold font-sans text-2xl">
                  Administrador de Socios
                </h2>
                <p className="text-gray-400 font-mono font-medium">
                  Sistema de administración de socios
                </p>
              </div>
              <label
                className="block uppercase tracking-wide text-gray-500 text-xs font-bold font-mono mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 items-center mb-6 border border-gray-400">
                <div className="flex -mr-px justify-center w-15 px-1">
                  <span className="flex items-center leading-normal px-2 border-0 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="flex-shrink flex-grow font-mono flex-auto leading-normal bg-gray-200 h-12 px-3 self-center relative outline-none"
                  placeholder="email@email.com"
                  onChange={onChange}
                  value={user.email}
                />
              </div>
              <label
                className="block uppercase tracking-wide text-gray-500 text-xs font-bold font-mono mb-2"
                htmlFor="password"
              >
                Contraseña
              </label>
              <div className="flex flex-wrap items-stretch w-full mb-4 relative h-15 items-center mb-6 border border-gray-400">
                <div className="flex -mr-px justify-center w-15 px-1">
                  <span className="flex items-center leading-normal px-2 border-0 text-2xl">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-5 w-5 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="flex-shrink flex-grow flex-auto leading-normal font-mono bg-gray-200 h-12 px-3 self-center relative outline-none"
                  placeholder="***********"
                  onChange={onChange}
                  value={user.password}
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-socios-primary flex items-center justify-center shadow-lg uppercase font-bold py-4 text-center px-17 md:px-12 md:py-4 text-white leading-tight text-lg transition duration-500 ease-in-out hover:bg-socios-primaryHover"
            >
              Ingresar
              {loadingLogin ? (
                <svg
                  className="animate-spin ml-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : null}
            </button>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
