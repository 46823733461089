import React, { useContext, useState } from "react";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";
import Formulario from "../../UiComponents/Formulario";

function ModalNuevaPersona() {
  const buscadorContext = useContext(BuscadorContext);
  const { setModalNuevaPersona } = buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    text: "No hay personas registradas con ese DNI",
  });

  const datosForm = {
    cantColumnas: 2,
    textoSubmit: "REGISTRAR PERSONA",
    style:
      "px-2 py-2 bg-gray-200 border-2 placeholder-gray-500 border-socios-primary",
    gap: 4,
    styleButton:
      "w-full bg-green-400 mt-8 py-2 border-2 border-socios-primary transition duration-300 hover:bg-green-500 uppercase text-white",
    styleButtonSubmitting:
      "w-full bg-gray-400 mt-8 py-2 border-2 border-socios-primary  uppercase text-white text-center flex items-center justify-center",
    id: "form_nueva_persona",
    campos: [
      {
        nombre: "Nombre *",
        apiName: "nombre",
        type: "text",
        placeholder: "Nombre de la Persona *",
        required: true,
      },
      {
        nombre: "Apellido *",
        apiName: "apellido",
        type: "text",
        placeholder: "Apellido de la Persona *",
        required: true,
      },
      {
        nombre: "DNI *",
        apiName: "dni",
        type: "number",
        placeholder: "DNI de la Persona *",
        required: true,
      },
      {
        nombre: "Sexo *",
        apiName: "genero",
        type: "select",
        required: true,
        opciones: [
          {
            clave: "P",
            nombre: "Prefiero no decir",
          },
          {
            clave: "M",
            nombre: "Masculino",
          },
          {
            clave: "F",
            nombre: "Femenino",
          },
          {
            clave: "O",
            nombre: "Otro",
          },
        ],
      },
      {
        nombre: "Fecha de Nacimiento *",
        apiName: "fechaNac",
        type: "date",
        required: true,
      },
      {
        nombre: "Email",
        apiName: "mail",
        type: "email",
        placeholder: "Email de la Persona",
      },
      {
        nombre: "Celular",
        apiName: "celular",
        type: "tel",
        placeholder: "Celular de la Persona",
      },
      {
        nombre: "Tel. Fijo",
        apiName: "telefono",
        type: "tel",
        placeholder: "Tel. Fijo de la Persona",
      },
    ],
  };

  const darDeAltaPersona = (valores, setSubmitting) => {
    axiosClient
      .post("add_persona", valores, header)
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        setAlert("Se registró la persona correctamente", "green-300");
        setModalNuevaPersona(false);
      })
      .catch((err) => {
        console.log(err);
        var newAlertInfo = { ...alertInfo };
        newAlertInfo.show = true;
        newAlertInfo.text =
          "Hubo un error procesando tu solicitud. Intentá más tarde";
        setAlertInfo(newAlertInfo);
        setSubmitting(false);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">Nueva Persona</h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Registrar una nueva Persona
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalNuevaPersona(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            {alertInfo.show ? (
              <div className="border-2 p-4 m-4 flex flex-row justify-between border-socios-primary bg-red-500 text-white">
                <div className="flex flex-row gap-2 items-center font-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{alertInfo.text}</p>
                </div>
                {!alertInfo.text.includes("Por favor") ? (
                  <button className="border-2 border-white px-4 py-1 bg-red-700 transition duration-300 hover:bg-red-800">
                    REGISTRAR DNI
                  </button>
                ) : null}
              </div>
            ) : null}

            <h1 className="text-lg text-left p-4 mt-2 font-bold">
              Por favor, completá los siguientes campos
            </h1>
            <div className="p-4 pt-0">
              <Formulario data={datosForm} funcionForm={darDeAltaPersona} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNuevaPersona;
