import React, { useState, useContext } from 'react';
import AuthContext from '../../../../context/auth/authContext'
import axiosClient from '../../../../config/axios';

export default function Turno({ setOpenModal }) {
    const [observacion, setObservacion] = useState('')
    const [confirmacion, setConfirmacion] = useState(false);
    const authContext = useContext(AuthContext);
    const { header, servicioSelect, estadoPtoServicio,setEstadoPtoServicio } = authContext;


    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenModal(false);
        setConfirmacion(true);
        setEstadoPtoServicio(1);             
 
    
        const body = {
            idPunto: servicioSelect.nombre,
            estado: estadoPtoServicio.estado,
            observacion: observacion
            
        }   
        if((body.estado=!null)&&(body.idPunto!=null)){
            axiosClient
            .post('set_ps_estado', body, header)
            .then(response => {                
            }) 
        }
        
    }

    return (
        <>
            <div className="fixed z-10 inset-0 overflow-y-auto py-0">
                <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="flex items-center justify-around flex-col p-2">
                            <h2>ATENCIÓN</h2>
                            {
                                confirmacion ?
                                    <div className='border-2 border-2 border-black rounded-full p-1 m-4 flex items-center justify-center' style={{ width: '8rem', height: '8rem' }}>
                                        <img className='w-5/6' src="https://cdn-icons.flaticon.com/png/512/5290/premium/5290611.png?token=exp=1643119841~hmac=5484203dce6d29bdaf181767cffc5bb6" alt="marca de verificación" />
                                    </div>
                                    : (
                                        <>
                                            <textarea className='w-3/4 my-4 rounded-md' value={observacion} onChange={e => setObservacion(e.target.value)} rows={4} style={{ resize: 'none' }} />
                                            <button className='rounded-md text-white bg-grey px-2 py-1' >Guardar</button>
                                        </>
                                    )
                            }
                            <p className='m-4'>¿Está seguro/a que quiere abrir el turno?</p>
                            <div className="flex justify-between px-6 pb-4 w-full">
                                <button className='bg-green px-3 py-2 text-white' onClick={e => handleSubmit(e)}>Confirmar</button>
                                <button className='bg-red px-3 py-2 text-white' onClick={() => setOpenModal(false)}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}