import React, { useContext, useReducer, useEffect, useState } from "react";
import buscadorContext from "./buscadorContext";
import buscadorReducer from "./buscadorReducer";
import AuthContext from "../auth/authContext";
import AlertContext from "../alerts/alertContext";
import axiosClient from "../../config/axios";
import { SEARCH_PERSONA } from "../../types";

const BuscadorState = (props) => {
  const authContext = useContext(AuthContext);
  const { header, getUser } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {
    data: null
  };

  const [state, dispatch] = useReducer(buscadorReducer, initialState);
  const [personasBuscadas, setPersonasBuscadas] = useState({});
  const [datosPersona, setDatosPersona] = useState({});
  const [datosSocio, setDatosSocio] = useState(null);
  const [datosTablas, setDatosTablas] = useState({});
  const [serviciosSocio, setServiciosSocio] = useState({});
  const [listaServicios, setListaServicios] = useState([]);
  const [loadingBusqueda, setLoadingBusqueda] = useState(false);
  const [serviciosUpdate, setServiciosUpdate] = useState(false);
  const [modalNuevaPersona, setModalNuevaPersona] = useState(false);

  // Liquidaciones
  const [modalDetalleLiqui, setModalDetalleLiqui] = useState(false);
  const [dataDetalle, setDataDetalle] = useState(null);

  //Solicitudes
  const [dataSolicitud, setDataSolicitud] = useState({});
  const [modalVer, setModalVer] = useState(false);
  const [modalAceptar, setModalAceptar] = useState(false);
  const [modalRechazar, setModalRechazar] = useState(false);
  const [updateSolicitudes, setUpdateSolicitudes] = useState(false);

  //Servicios
  const [modalBajaTotal, setModalBajaTotal] = useState(false);
  // MODAL FECHA VENCIMIENTO
  const [modalFechaVencimiento, setModalFechaVencimiento] = useState(false);
  const [fechaVenc,setFechaVenc]=useState("");
  useEffect(() => { }, []);

  //Funcion para buscar personas por NYAP (devuelve el array de coincidencias).
  const buscarPersonaNYAP = async (data) => {
    setDatosPersona({});
    setPersonasBuscadas({});
    setLoadingBusqueda(true);
    await axiosClient
      .post("get_personas", data, header)
      .then((response) => {
        if (response.data.data.length === 0) {
          setPersonasBuscadas({});
          setAlert("No se encontraron personas", "red-300");
        } else {
          setPersonasBuscadas(response.data.data);
        }
        setLoadingBusqueda(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
          setLoadingBusqueda(false);
        }
      });
  };

  //funcion para buscar una persona espepcífica por DNI o NRO de Socio
  const buscarPersonaEspecifica = async (data) => {
    setDatosPersona({});
    setPersonasBuscadas({});
    setLoadingBusqueda(true);
    
    await axiosClient
      .post("get_personas", data, header)
      .then((response) => {
        //console.log(response);
        if (response.data.data.length === 0) {
          //console.log('Sin encontrar persona');
          setDatosPersona({});
          setAlert("No se encontraron personas", "red-300");
        } else {
          
          setDatosPersona(response.data.data[0]);
          getDatosSocio(response.data.data[0].ID_PERSONA);
          buscarServiciosSocio(response.data.data[0].ID_PERSONA);
          buscarDatosTablas(response.data.data[0].ID_PERSONA);
          localStorage.removeItem("dniSocio");
          
        }
        setLoadingBusqueda(false);
        
        dispatch({
          type: SEARCH_PERSONA,
          payload: data
        })
      })
      
      .catch((err) => {
        
        
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
          setLoadingBusqueda(false);
        }
      );
  };

  //Funcion para buscar los servicios del socio.
  const buscarServiciosSocio = async (id) => {
    var payload = {
      idPersona: id,
    };
    await axiosClient
      .post("/get_socio_servicios", payload, header)
      .then((response) => {
        
        if (response.data.data.length !== 0) {
          setServiciosSocio(response.data.data);
        } else {
          setServiciosSocio([]);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
        }
      });
  };

  //Funcion para buscar servicios posibles de aplicar al socio
  const buscarNuevoServicioSocio = async (id) => {
    await axiosClient
      .get("/get_servicios_posibles_persona/" + id, header)
      .then((response) => {
        if (response.data.data.length === 0) {
          setAlert("No se encontraron personas", "red-300");
        } else {
          setListaServicios(response.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
        }
      });
  };

  //Funcion para buscar los datos de las tablas para llenar los campos con selectores.
  const buscarDatosTablas = async (id) => {
    const data = {
      idPersona: id,
    };
    await axiosClient
      .post("/get_tablas", data, header)
      .then((response) => {
        if (response.data.data.length === 0) {
          setAlert("No se encontraron personas", "red-300");
        } else {
          setDatosTablas(response.data.data);
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert("Hubo un error intentá de nuevo", "red-300");
          console.log(err);
        }
      });
  };

  //Busca todos los datos del socio por el ID de persona.
  const getDatosSocio = async (idPersona) => {
    setLoadingBusqueda(true);
    const data = {
      idPersona: idPersona,
    };
    await axiosClient
      .post("/get_socio", data, header)
      .then((response) => {
        setLoadingBusqueda(false);
        setDatosSocio(response.data.data);
        
      })
      .catch((err) => {
        setLoadingBusqueda(false);
        setAlert("Hubo un error intentá de nuevo", "red-300");
      });
  };

  //Edita todos los datos del socio por el ID de socio.
  const editDatosSocio = async (datos) => {
    const data = {
      idSocio: datosPersona.ID_SOCIO,
      fechaIngreso: datos.FECHAINGRESO,
      referencia: datos.REFERENCIA,
      observaciones: datos.OBSERVACIONES,
      transeunte: datos.TRANSEUNTE,
      urlCarnet: datos.urlCarnet,
    };
    await axiosClient
      .post("/edit_datos_socio", data, header)
      .then((response) => {
        
       /*  buscarPersonaEspecifica({
          "dni": datosPersona.NRO_DOCUMENTO,
	        "tipo": "F"
        }); */
        setAlert(response.data.data, "green");
        //getDatosSocio(datosPersona.ID_PERSONA);
      })
      .catch((err) => {
        console.log(err);
        setAlert(err.response.data.data, "red-300");
      });
  };

  //Funcion para editar Datos personales del socio.
  const editarDatosPersonales = async (datos) => {
    const data = {
      idPersona: datosPersona.ID_PERSONA,
      apellido: datos.apellido,
      nombre: datos.nombre,
      genero: datos.genero,
      fechaNac: datos.fechaNac,
      auxiliar1: "",
      auxiliar2: "",
      auxiliar3: "",
      auxiliar4: "",
    };
    await axiosClient
      .post("/set_datos_personales", data, header)
      .then((response) => {
        setAlert(response.data.data, "green-300");
        getDatosSocio(datosPersona.ID_PERSONA);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert(err.response.data.data, "red-300");
          console.log(err);
        }
      });
  };

  //Funcion para editar el estado del socio.
  const editarEstadoSocio = async (datos) => {
    const data = {
      idSocio: datosPersona.ID_SOCIO,
      fecha: datos.fecha,
      motivo: datos.motivo,
      observaciones: datos.observaciones,
      estado: "",
      categoria: "",
    };
    await axiosClient
      .post("/add_estado_socio", data, header)
      .then((response) => {
        console.log(response);
        getDatosSocio(datosPersona.ID_PERSONA);
        setAlert(response.data.data, "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          if (err.response !== undefined) {
            setAlert(err.response.data.data, "red-300");
          } else {
            setAlert(
              "Ocurrió un erro inesperado, intentá nuevamente más tarde",
              "red-300"
            );
          }
          console.log(err);
        }
      });
  };
  //Funcion para agregar asistencia.
  const agregarAsistencia = async (datos, setModalAsistencia) => {
    const data = {
      idPersona: datosPersona.ID_PERSONA,
      obs: datos.obs,
      mail: datos.mail,
    };
    await axiosClient
      .post("/add_asistencia_pc", data, header)
      .then((response) => {
        console.log(response);
        //getDatosSocio(datosPersona.ID_PERSONA);
        setModalAsistencia(false);
        setAlert(response.data.data, "green-300");
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setAlert(err.response.data.data, "red-300");
        } else {
          setAlert(
            "Ocurrió un erro inesperado, intentá nuevamente más tarde",
            "red-300"
          );
        }
        console.log(err);
      });
  };

  //funcion para agregar nuevo contacto
  const addNuevoContacto = async (datos, recibeInfo) => {
    const data = {
      idPersona: datosPersona.ID_PERSONA,
      tipoForma: datos.tipoForma,
      contacto: datos.contacto,
      auxiliar: "0",
      recibeInfo,
      tipoContacto: datos.tipoContacto,
      estado: datos.estado,
      observaciones: datos.observaciones,
    };

    await axiosClient
      .post("/add_fcontacto", data, header)
      .then((response) => {
        setAlert(response.data.data, "green-300");
        getDatosSocio(datosPersona.ID_PERSONA);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert(err.response.data.data, "red-300");
          setAlert(err.response.data.data, "red-300");
          console.log(err);
        }
      });
  };

  //Funcion para editar Datos de una Direccion del socio.
  const editarDatosDireccion = async (payload) => {
    await axiosClient
      .post("/edit_persona_direccion", payload, header)
      .then((response) => {
        console.log(response);
        getDatosSocio(datosPersona.ID_PERSONA);
        setAlert(response.data.data, "green-300");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert(err.response.data.data, "red-300");
          console.log(err);
        }
      });
  };

  //Función para editar dato de contacto especifico
  const editarContacto = async (
    datos,
    recibeInfo,
    idContacto,
    setModalEditarContacto
  ) => {
    const data = {
      idFContacto: idContacto,
      idPersona: datosPersona.ID_PERSONA,
      tipoForma: datos.tipoForma,
      contacto: datos.contacto,
      auxiliar: "0",
      recibeInfo,
      tipoContacto: datos.tipoContacto,
      estado: datos.estado,
      observaciones: datos.observaciones,
    };

    await axiosClient
      .post("/edit_fcontacto", data, header)
      .then((response) => {
        setAlert(response.data.data, "green-300");
        getDatosSocio(datosPersona.ID_PERSONA);
        setModalEditarContacto(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          resetStateBuscador();
          getUser();
        } else {
          setAlert(err.response.data.data, "red-300");
          console.log(err);
        }
      });
  };

  const formatDate = (data) => {
    var date = new Date(data);

    if (!isNaN(date.getTime())) {
      var day = date.getUTCDate().toString();
      var month = (date.getUTCMonth() + 1).toString();
      var fecha =
        (day[1] ? day : "0" + day[0]) +
        "/" +
        (month[1] ? month : "0" + month[0]) +
        "/" +
        date.getFullYear();
      return fecha;
    }
  };

  const formatImporte = (data) => {
    const total = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
    }).format(data);

    return "$ " + total;
  };

  const formatMiles = (data) => {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".  ");
  };

  const resetStateBuscador = () => {
    setPersonasBuscadas({});
    setDatosPersona({});
    setDatosSocio(null);
    setServiciosSocio({});
    setDatosTablas({});
    setListaServicios([]);
    setLoadingBusqueda(false);
  };

  return (
    <buscadorContext.Provider
      value={{
        data: state.data,
        loading: state.loading,
        personasBuscadas,
        setPersonasBuscadas,
        buscarPersonaNYAP,
        buscarPersonaEspecifica,
        getDatosSocio,
        datosSocio,
        editDatosSocio,
        buscarServiciosSocio,
        buscarNuevoServicioSocio,
        listaServicios,
        serviciosSocio,
        buscarDatosTablas,
        datosTablas,
        editarEstadoSocio,
        editarDatosPersonales,
        addNuevoContacto,
        editarContacto,
        loadingBusqueda,
        setLoadingBusqueda,
        datosPersona,
        setDatosPersona,
        formatDate,
        formatImporte,
        resetStateBuscador,
        editarDatosDireccion,
        serviciosUpdate,
        setServiciosUpdate,
        modalNuevaPersona,
        setModalNuevaPersona,
        modalDetalleLiqui,
        setModalDetalleLiqui,
        modalFechaVencimiento,
        setModalFechaVencimiento,
        dataDetalle,
        setDataDetalle,
        formatMiles,
        modalVer,
        setModalVer,
        modalAceptar,
        setModalAceptar,
        modalRechazar,
        setModalRechazar,
        dataSolicitud,
        setDataSolicitud,
        updateSolicitudes,
        setUpdateSolicitudes,
        modalBajaTotal,
        setModalBajaTotal,
        agregarAsistencia,
        fechaVenc,
        setFechaVenc,
      }}
    >
      {props.children}
    </buscadorContext.Provider>
  );
};

export default BuscadorState;
