import React from "react";
import MainButton from "./UiComponents/MainButton";

function BoxEstado({ data, botonHistorial, botonEditar }) {
  const dataParsed = data
  return (
    <div className="flex flex-col lg:flex-row lg:items-end lg:justify-between p-4 border-2 border-socios-primary">
      <div className="lg:grid lg:grid-cols-2 gap-3">
        {" "}
        <div className="lg:m-0 mb-2 lg:border-r-2 lg:border-socios-primary">
          <p className="text-md font-bold text-gray-800">ESTADO</p>
          <span
            className={
              data[0]=="ACTIVO"
                ? "bg-green text-white border border-socios-primary px-2 py-0.5"
                : "bg-red text-white border border-socios-primary px-2 py-0.5"
            }
          >
            {data[0]}
          </span>
        </div>
        <div className="lg:m-0 mb-2">
          <p className="text-md font-bold text-gray-800">CATEGORÍA</p>
          <span className="bg-blue-500 text-white border border-socios-primary px-2 py-0.5">
            {data[1]}
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 lg:gap-0 lg:flex-row lg:items-center">
        <div className="lg:mr-2">
          <MainButton
            fullBackground={false}
            onClick={botonHistorial}
            texto={"ver historial"}
          />
        </div>
        <MainButton
          fullBackground={true}
          onClick={botonEditar}
          texto={"editar"}
        />
      </div>
    </div>
  );
}
export default BoxEstado;
