import React from "react";

function E404() {
  return (
    <div className="bg-gray-300 py-10 px-4">
      <p>Error 404</p>
    </div>
  );
}
export default E404;
