import React, { useContext, useState, useEffect } from "react";
import BoxContacto from "../BoxContacto";
import EmptyData from "../UiComponents/EmptyData";
import BuscadorContext from "../../context/buscador/buscadorContext";
import ModalEditarContacto from "./Modals/ModalEditarContacto";
import ModalNuevoContacto from "./Modals/ModalNuevoContacto";
import MainButton from "../UiComponents/MainButton";

function DatosContacto() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    datosPersona,
    datosSocio,
    datosTablas,
    addNuevoContacto,
    editarContacto,
  } = buscadorContext;

  useEffect(() => {}, [datosSocio]);

  const [modalNuevoContacto, setModalNuevoContacto] = useState(false);
  const [modalEditarContacto, setModalEditarContacto] = useState(false);
  const [contactoID, setContactoID] = useState({});

  const handleClick = (ID) => {
    setModalEditarContacto(!modalEditarContacto);
    setContactoID(ID);
  };

  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    const { NOMBRE, APELLIDO, TIPO_DOCUMENTO, NRO_DOCUMENTO } =
      datosSocio.socio;
    const { contacto } = datosSocio;
    return (
      <>
        <div className="p-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-lg font-bold text-gray-700">
                Datos de Contacto
              </h1>
              <p className="font-medium font-mono text-gray-500">
                {NOMBRE} {APELLIDO} - {TIPO_DOCUMENTO} {NRO_DOCUMENTO}
              </p>
            </div>
            <div>
              <MainButton
                fullBackground={true}
                onClick={() => {
                  setModalNuevoContacto(true);
                }}
                texto={"Nuevo"}
              />
            </div>
          </div>
          <div className="mt-10">
            {contacto.length > 0
              ? contacto
                  .sort((a, b) => (a.ESTADO < b.ESTADO ? 1 : -1))
                  .map((c) => (
                    <BoxContacto
                      key={c.ID}
                      data={c}
                      botonEditar={() => handleClick(c)}
                    />
                  ))
              : null}
          </div>
        </div>
        {modalEditarContacto ? (
          <ModalEditarContacto
            setModalEditarContacto={setModalEditarContacto}
            data={contactoID}
            tablas={datosTablas}
            editarContacto={editarContacto}
          />
        ) : null}
        {modalNuevoContacto ? (
          <ModalNuevoContacto
            setModalNuevoContacto={setModalNuevoContacto}
            data={datosTablas}
            addNuevoContacto={addNuevoContacto}
          />
        ) : null}
      </>
    );
  } else {
    return <EmptyData texto={"Buscar un socio para comenzar"} />;
  }
}
export default DatosContacto;
