import React from "react";

function EmptyData({
  texto
}) {
  return (
    <div
      className="border-2 border-gray-800 text-center bg-gray-300 py-1"
    >
      {texto}
    </div>
  );
}
export default EmptyData;
