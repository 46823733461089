import React from "react";

import Routes from "./Routes";
import AlertState from "./context/alerts/alertState";
import AuthState from "./context/auth/authState";
import BuscadorState from "./context/buscador/buscadorState";

function App() {
  return (
    <AlertState>
      <AuthState>
        <BuscadorState>
          <Routes />
        </BuscadorState>
      </AuthState>
    </AlertState>
  );
}

export default App;
