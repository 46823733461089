import { useState, useEffect } from "react";
import axiosClient from "../../config/axios";
import { useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import Imprimir from "./Imprimir";

function Credencial() {
  let location = useLocation();
  const dni = new URLSearchParams(location.search).get("dni");
  const [loadingData, setLoadingData] = useState(false);
  // const [data, setData] = useState(null);
  const [imprimiendo, setImprimiendo] = useState(false);
  const [loadingImagen, setLoadingImagen] = useState(true);

  const data = {
    "APELLIDO": "FRONTINI",
    "CATEGORIA": "Activo",
    "ESTADO": "ACTIVO",
    "NOMBRE": "NICOLAS",
    "NROSOCIO": 3288,
    "NRO_DOCUMENTO": "22716054",
    "URL": "https://s3.amazonaws.com/fya.socios.imagenes/public/FOTOCARNET_22716054_2021-12-13T19:06:57."
  }

  useEffect(() => {
    // axiosClient
    //   .post("mostrar_datos_basicos", { dni: dni })
    //   .then((response) => {
    //     setData(response.data.data);
    //     setLoadingData(false);
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     setLoadingData(false);
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    if (imprimiendo) {
      window.print();
      setImprimiendo(false);
    }
  }, [imprimiendo]);

  return (
    <div className="flex flex-col justify-center">
      {loadingData ? (
        <div className="flex justify-center items-center mt-20">
          <h1 className="text-4xl">Cargando...</h1>
        </div>
      ) : imprimiendo ?
        <Imprimir data={data} loadingImagen={loadingImagen} setLoadingImagen={setLoadingImagen} /> :
        (
          <div className="bg-white py-2 rounded border-4 border-socios-primary m-4">
            {/* TITLE */}
            <h1 className="text-center font-bold text-2xl">
              CREDENCIAL DE SOCIO
            </h1>
            <div
              className={"flex flex-col justify-center lg:flex-row items-center"}
            >
              <div
                className={
                  loadingImagen
                    ? "h-32 w-32 border-socios-primary border rounded ml-4 flex justify-center items-center"
                    : "h-32 w-32 border-socios-primary border rounded ml-4 hidden"
                }
              >
                <svg
                  className="animate-spin h-12 w-12 text-socios-primary"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
              <img
                src={data.URL}
                className={
                  loadingImagen
                    ? "hidden"
                    : "h-32 border border-socios-primary rounded ml-4"
                }
                onLoad={() => {
                  setLoadingImagen(false);
                }}
              ></img>
              <div className="border border-socios-primary w-10/12 lg:w-2/4 h-32 m-4 flex flex-col justify-center rounded gap-4">
                <h2 className="ml-4 text-lg flex flex-row gap-1">
                  <p className="font-bold">NOMBRE: </p>
                  {data.NOMBRE + " " + data.APELLIDO}
                </h2>
                <h2 className="ml-4 text-lg flex flex-row gap-1">
                  <p className="font-bold">NRO DE SOCIO: </p>
                  {data.NROSOCIO}
                </h2>
                <h2 className="ml-4 text-lg flex flex-row gap-1">
                  <p className="font-bold">NRO DE DOCUMENTO: </p>
                  {data.NRO_DOCUMENTO}
                </h2>
              </div>
              <div
                className={"h-32 lg:w-1/4"}
              >
                <QRCode
                  size={126}
                  value={
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    "/credencial?dni=" +
                    data.NRO_DOCUMENTO
                  }
                />
              </div>
            </div>
          </div>
        )}
      {!imprimiendo && !loadingData ? (
        <div className="w-full">
          {" "}
          <div className="bg-white py-2 rounded border-4 border-socios-primary m-4">
            <h1 className="text-center font-bold text-2xl">
              DATOS ADCIONALES DEL SOCIO
            </h1>
            <div className="flex flex-row justify-center gap-8 lg:gap-32 mt-4 border border-socios-primary m-4 py-6 rounded text-lg">
              <div className="flex flex-col gap-1">
                <p className="font-bold text-xl">ESTADO</p>
                <span
                  className={
                    data.ESTADO === "ACTIVO"
                      ? "rounded border-socios-primary border-2 text-center px-4 w-32 text-white bg-green"
                      : "rounded border-socios-primary border-2 text-center px-4 w-32 text-white bg-red"
                  }
                >
                  {data.ESTADO}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="font-bold text-xl">CATEGORÍA</p>
                <span className="rounded border-socios-primary border-2 text-white bg-blue-600 text-center w-32">
                  {data.ESTADO}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {!loadingData && !imprimiendo ? (
        <button
          className="py-2 bg-green text-white mt-10 border-socios-primary border-2 rounded m-4 hover:bg-green-600 duration-300 transition text-2xl"
          onClick={() => setImprimiendo(true)}
        >
          IMPRIMIR CREDENCIAL
        </button>
      ) : null}
    </div>
  );
}

export default Credencial;
