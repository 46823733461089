import React, { useContext, useEffect, useState } from "react";
import Button from "../../UiComponents/Button";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";
import axiosClient from "../../../config/axios";

function ModalNuevoServicio({ setModalNuevoServicio, data, idPersona }) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatImporte, buscarServiciosSocio } = buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [servicioSeleccionado, setServicioSeleccionado] = useState(
    data[0] !== undefined ? data[0].ID : ""
  );
  const [fechaInicio, setFechaInicio] = useState("");
  const [bonificacion, setBonificacion] = useState("");
  const [dandoDeAlta, setDandoDeAlta] = useState(false);

  useEffect(() => {}, [data]);

  const darDeAltaServicio = async (e) => {
    e.preventDefault();
    setDandoDeAlta(true);
    var payload = {
      idPersona: idPersona,
      idServicio: servicioSeleccionado,
      fechaInicio: fechaInicio,
      bonificacion: bonificacion,
    };
    console.log(payload);
    await axiosClient
      .post("add_servicio_socio", payload, header)
      .then((response) => {
        setDandoDeAlta(false);
        setModalNuevoServicio(false);
        buscarServiciosSocio(idPersona);
        setAlert("Se agregó el servicio al socio correctamente", "green-300");
      })
      .catch((err) => {
        console.log(err);
        setDandoDeAlta(false);
        setModalNuevoServicio(false);
        setAlert(
          "Hubo un error agregando el servicio. Intentá más tarde",
          "red-300"
        );
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Nuevo Servicio
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Agregar nuevo servicio al socio.
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalNuevoServicio(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-gray-200 px-4 my-2 pb-4 sm:p-6 sm:pb-4">
            <form onSubmit={darDeAltaServicio}>
              <label
                className="font-mono uppercase font-bold"
                htmlFor="selectServicios"
              >
                SERVICIOS
              </label>
              <select
                id="selectServicios"
                name="selecServicios"
                className="mt-1 block w-full text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
                value={servicioSeleccionado}
                defaultValue=""
                onChange={(e) => setServicioSeleccionado(e.target.value)}
              >
               
                { data.length > 0
                  ? data.map((o) => (
                      
                      <option key={o.id} value={o.ID}>
                        {o.NOMBRE }
                      </option>

                    ))
                  : null} 
              </select>
              <label
                className="font-mono uppercase font-bold"
                htmlFor="fechaInicio"
              >
                FECHA DE INICIO
              </label>
              <input
                id="fechaInicio"
                name="fechaInicio"
                type="date"
                value={fechaInicio}
                required
                onChange={(e) => setFechaInicio(e.target.value)}
                className="mt-1 block w-full text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
              ></input>
              <label
                className="font-mono uppercase font-bold"
                htmlFor="bonificacion"
              >
                BONIFICACIÓN
              </label>

              <input
                id="bonificacion"
                name="bonificacion"
                type="number"
                value={bonificacion}
                onChange={(e) => {
                  if (!isNaN(e.target.value) && e.target.value >= 0) {
                    setBonificacion(e.target.value);
                  }
                }}
                placeholder="Bonificación"
                className="mt-1 block w-full text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
              ></input>
              <div className="pt-2 flex items-center justify-end">
                <Button
                  fullBackground={true}
                  texto={dandoDeAlta ? "Agregando..." : "Guardar"}
                  background={dandoDeAlta ? "gray-500" : "green"}
                  hoverBackground={dandoDeAlta ? "" : "green-600"}
                  color="white"
                  hoverColor="white"
                  type="submit"
                  disabled={dandoDeAlta}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNuevoServicio;
