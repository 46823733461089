import {
  LOGING_SUCCESS,
  LOGING_ERROR,
  GET_USER,
  LOG_OUT,
  SET_FECHA,
  SET_SERVICIO_SELECCIONADO,
  SET_ESTADO_PTO_SERVICIO
} from "../../types/index.js";

export default (state, action) => {
  switch (action.type) {
    case GET_USER:
      // console.log(action.payload)
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("fecha", action.payload.fecha);
      localStorage.setItem("hora", action.payload.hora);
      localStorage.setItem('rol', action.payload.rol);
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        puntoServicio: action.payload.puntoServicio,
        username: localStorage.getItem("username"),
        fecha: localStorage.getItem("fecha"),
        hora: localStorage.getItem("hora"),
        rol: localStorage.getItem("rol")
      };

    case LOGING_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("fecha", action.payload.fecha);
      localStorage.setItem("hora", action.payload.hora);
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem('rol', action.payload.rol[0]);
      return {
        ...state,
        auth: true,
        token: localStorage.getItem("token"),
        username: localStorage.getItem("username"),
        fecha: localStorage.getItem("fecha"),
        hora: localStorage.getItem("hora"),
        rol: localStorage.getItem("rol")
      };
    case LOGING_ERROR:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      localStorage.removeItem("rol")
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        fecha: null,
        hora: null,
        rol: null
      };
    case LOG_OUT:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("name");
      localStorage.removeItem("fecha");
      localStorage.removeItem("hora");
      localStorage.removeItem("rol");
      return {
        ...state,
        token: null,
        auth: false,
        username: null,
        fecha: null,
        hora: null,
        rol: null
      };
    case SET_FECHA:
      return {
        ...state,
        fecha: action.payload,
      };
    case SET_SERVICIO_SELECCIONADO:
      return {
        ...state,
        servicioSelect: action.payload
      };
    case SET_ESTADO_PTO_SERVICIO:
      return {
        ...state,
        estadoPtoServicio: action.payload,
      }
    default:
      return state;
  }
};
