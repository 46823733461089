import React, { useContext } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import BaseTemplate from "./components/BaseTemplate";

import Login from "./components/Login";
import DatosPersonales from "./components/Socio/DatosPersonales";
import DatosSocio from "./components/Socio/DatosSocio";
import DatosContacto from "./components/Socio/DatosContacto";
import Direcciones from "./components/Socio/Direcciones";
import Servicios from "./components/Socio/Servicios";
import Solicitudes from "./components/Admin/Solicitudes/Solicitudes";
import ServiciosConsulta from "./components/Admin/Servicios/Servicios";
import Liquidaciones from "./components/Admin/Liquidaciones/Liquidaciones";
import Socios from "./components/Admin/Socios/Socios";

// Public Routes
import Solicitud from "./components/Public/Solicitud";
import Confirmacion from "./components/Public/Confirmacion";

import SidebarSocioTemplate from "./components/SidebarSocioTemplate";

import E404 from "./components/E404";
import Alert from "./components/Alert";

import AuthContext from "./context/auth/authContext";
import Credencial from "./components/Public/Credencial";
import PuntoServicio from "./components/Admin/PuntoServicio.js/PuntoServicio";

function Routes() {
  const authContext = useContext(AuthContext);
  const { auth } = authContext;
  return (
    <Router>
      <div className="fixed right-0 top-0 mx-4 my-24 z-50">
        <Alert />
      </div>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/solicitud">
          <Solicitud />
        </Route>
        <Route exact path="/confirmacion">
          <Confirmacion />
        </Route>
        <Route exact path="/credencial">
          <Credencial />
        </Route>
        <ProtectedRoute
          path="/solicitudes"
          component={Solicitudes}
          auth={auth}
          exact
        />
        <ProtectedRoute
          path="/servicios-consulta"
          component={ServiciosConsulta}
          auth={auth}
          exact
        />
        <ProtectedRoute
          path="/liquidaciones"
          component={Liquidaciones}
          auth={auth}
          exact
        />
        <ProtectedRoute path="/socios" component={Socios} auth={auth} exact />
        <ProtectedRoute
          path="/punto-servicio"
          component={PuntoServicio}
          auth={auth}
          exact
        />
        <SidebarSocioTemplate>
          <ProtectedRoute
            path="/datos-personales"
            component={DatosPersonales}
            auth={auth}
            exact
          />
          <ProtectedRoute path="/" component={DatosSocio} auth={auth} exact />
          <ProtectedRoute
            path="/datos-contacto"
            component={DatosContacto}
            auth={auth}
            exact
          />
          <ProtectedRoute
            path="/direcciones"
            component={Direcciones}
            auth={auth}
            exact
          />
          <ProtectedRoute
            path="/servicios"
            component={Servicios}
            auth={auth}
            exact
          />
        </SidebarSocioTemplate>
        <Route component={E404} />
      </Switch>
    </Router>
  );
}

export default Routes;
