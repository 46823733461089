import { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function BoxServicios(props) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatImporte, setModalDetalleLiqui, setDataDetalle } =
    buscadorContext;

  const data = props.data;
  const idLiquidacion = { idLiquidacion: props.idLiquidacion };

  return (
    <div className="border-2 border-socios-primary m-4 p-4">
      <div className="flex flex-col gap-4 lg:grid lg:grid-cols-10">
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">CÓDIGO</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.CODIGO}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.DESCRIPCION.slice(0, -3)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">CATEGORÍA</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.CATEGORIA.toUpperCase()}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">SUBCATEGORÍA</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.SUBCATEGORIA === "" ? "NO POSEE" : data.SUBCATEGORIA}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">CANTIDAD</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.CANTIDAD}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">PRECIO</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatImporte(data.PRECIO)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">SUBTOTAL</p>
          <p className="text-md font-mono font-semibold text-gray-900">
            {formatImporte(data.SUBTOTAL)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">BONIFICACIÓN</p>
          <p className="text-md font-mono font-semibold text-gray-900">
            {formatImporte(data.BONIFICACION)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">TOTAL</p>
          <p className="text-md font-mono font-semibold text-gray-900">
            {formatImporte(data.TOTAL)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <button
            className="px-4 py-2 border-socios-primary border-2 flex items-center justify-center transition duration-300 hover:bg-socios-primary hover:text-white"
            onClick={() => {
              setDataDetalle({ ...idLiquidacion, ...data });
              setModalDetalleLiqui(true);
            }}
          >
            Ver Detalle
          </button>
        </div>
      </div>
    </div>
  );
}

export default BoxServicios;
