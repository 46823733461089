import React from "react";

function Button({
  background,
  hoverBackground,
  color,
  hoverColor,
  onClick,
  fullBackground,
  texto,
  type,
  disabled,
}) {
  return (
    <button
      onClick={onClick}
      className={
        fullBackground
          ? `border-2 border-${background} bg-${background} text-${color} hover:bg-${hoverBackground} hover:border-${hoverBackground} hover:text-${hoverColor} uppercase px-8 text-sm py-1 transition duration-300 ease-in-out`
          : `border-2 border-${background} text-${color} hover:${hoverBackground} hover:border-${hoverBackground} hover:text-${hoverColor} uppercase px-8 text-sm py-1 transition duration-300 ease-in-out`
      }
      type={type}
      disabled={disabled}
    >
      {texto}
    </button>
  );
}
export default Button;
