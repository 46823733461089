import { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function BoxSolicitud(props) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatDate, formatImporte } = buscadorContext;
  const data = props.data;

  return (
    <div className="border-2 border-socios-primary m-4 p-4">
      <div className="flex flex-col gap-4 lg:grid lg:grid-cols-7">
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">NOMBRE</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.NOMBRE + " " + data.APELLIDO}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">NRO. SOCIO</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.NROSOCIO !== null ? data.NROSOCIO : "NO POSEE"}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.DESCRIPCION}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">FECHA DE INICIO</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatDate(data.FECHAINICIO.slice(0, 10))}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">FECHA DE FIN</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.FECHAFIN !== null
              ? formatDate(data.FECHAFIN.slice(0, 10))
              : "EN CURSO"}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">IMPORTE</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatImporte(data.IMPORTE)}
          </p>
        </div>
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">ACCIONES</p>
          <button
            className="px-6 bg-red-500 text-white py-0.5 transition duration-300 hover:bg-red-600 border-2 border-socios-primary"
            onClick={() => {
              props.setIdSeleccionado(data.ID);
              props.setModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BoxSolicitud;
