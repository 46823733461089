import React, { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function ModalVer({ estados }) {
  const buscadorContext = useContext(BuscadorContext);
  const { dataSolicitud, setModalVer, formatDate } = buscadorContext;
  const estadoSoli = estados.filter((e) => e.key === dataSolicitud.estado)[0][
    "value"
  ];

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Detalle de Solicitud
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Acá podes ver el detalle completo de la solicitud
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalVer(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="border-2 border-socios-primary m-4 bg-white">
            <div className="flex justify-left text-lg ">
              {" "}
              <ul className="list-disc ml-6 py-2">
                <li>
                  <b>Estado</b>: {estadoSoli}
                </li>
                <li>
                  <div className="flex flex-row">
                    <b>Tipo</b>:
                    <p
                      className={
                        dataSolicitud.tipo === "Reincorporacion"
                          ? "ml-1 text-red-500"
                          : "ml-1"
                      }
                    >
                      {dataSolicitud.tipo === "Nuevo"
                        ? "ALTA"
                        : dataSolicitud.tipo !== null
                        ? "REINC."
                        : "NO ESPECIFICADA"}
                    </p>
                  </div>
                </li>
                <li>
                  <b>Nombre</b>:{" "}
                  {dataSolicitud.nombre + " " + dataSolicitud.apellido}
                </li>
                <li>
                  <b>DNI</b>: {dataSolicitud.dni}
                </li>
                <li>
                  <b>Fecha de Solicitud</b>:{" "}
                  {formatDate(dataSolicitud.fecha.date.slice(0, 10))}
                </li>
                {estadoSoli === "Aceptada" ? (
                  <li>
                    <b>Fecha de Aceptación</b>:{" "}
                    {dataSolicitud.fechaAceptacion !== null
                      ? formatDate(
                          dataSolicitud.fechaAceptacion.date.slice(0, 10)
                        )
                      : "-"}
                  </li>
                ) : null}
                {estadoSoli === "Aceptada" ? (
                  <li>
                    <b>Fecha de Ingreso</b>:{" "}
                    {dataSolicitud.fecha_ingreso !== ""
                      ? formatDate(dataSolicitud.fecha_ingreso.slice(0, 10))
                      : "-"}
                  </li>
                ) : null}
                {estadoSoli === "Rechazada" ? (
                  <li>
                    <b>Fecha de Rechazo</b>:{" "}
                    {dataSolicitud.fechaRechazo !== null
                      ? formatDate(dataSolicitud.fechaRechazo.date.slice(0, 10))
                      : "-"}
                  </li>
                ) : null}
                <li>
                  <b>Interesado en: </b>
                  {dataSolicitud.servicio}
                </li>
                <li>
                  <b>Observaciones</b>: {dataSolicitud.prestacion}
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-gray-200 flex lg:flex-row flex-wrap gap-4 lg:gap-2 justify-evenly my-2 px-4 lg:px-0 pb-4 sm:p-5 sm:pb-4">
            {dataSolicitud.tipo === "Reincorporacion" ? (
              <button
                className={
                  "px-12 bg-blue-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-blue-600 w-full m-4 mt-0 mb-0"
                }
                onClick={() => {
                  window
                    .open("/?dniSocio=" + dataSolicitud.dni, "_blank")
                    .focus();
                }}
              >
                Ver Socio
              </button>
            ) : null}

            <button
              className={
                "px-12 bg-green-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-green-600 w-full m-4 mt-0 mb-0"
              }
              onClick={() => {
                setModalVer(false);
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalVer;
