import React, { useState } from 'react';
import DetalleBusqueda from './DetalleBusqueda';

export default function Busqueda({ setBusqueda }) {
    const [seleccionado, setSeleccionado] = useState(' ');

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto py-0">
            <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    {
                        seleccionado ? <DetalleBusqueda seleccionado={seleccionado} setSeleccionado={setSeleccionado} setBusqueda={setBusqueda} /> : (
                            <>
                                <div className="p-3 flex items-center justify-between">
                                    <div className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem' }}>
                                        <img src="https://cdn-icons-png.flaticon.com/512/751/751463.png" alt="lupa" className='w-1/2' />
                                    </div>
                                    <button onClick={() => setBusqueda(false)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                                        <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                                    </button>
                                </div>
                                <div className='flex flex-col justify-between items-center mb-10'>
                                    <button onClick={() => setSeleccionado('QR')} className="bg-black opacity-80 text-white p-3 w-1/3 rounded-md m-3">Leer QR</button>
                                    <button onClick={() => setSeleccionado('NRO_SOCIO_DNI')} className="bg-black opacity-80 text-white p-3 w-1/3 rounded-md m-3">N° de socio / N° DNI</button>
                                    <button onClick={() => setSeleccionado('INVITADO')} className="bg-black opacity-80 text-white p-3 w-1/3 rounded-md m-3" disabled>Invitado</button>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}