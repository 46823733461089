import React, { useEffect } from "react";
import MainButton from "./UiComponents/MainButton";

function BoxContacto({ data, botonEditar }) {
  useEffect(() => { }, [RECIBEINFO]);

  const {
    TIPO_CONTACTO,
    TIPO_FORMA,
    ESTADO,
    CONTACTO,
    RECIBEINFO,
    AUXILIAR,
    OBSERVACIONES,
  } = data;
  

  return (
    <>
      <div className="border-2 border-socios-primary p-4 mb-4">
        <div className="xl:grid xl:grid-cols-4 gap-4">
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">CONTACTO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {CONTACTO}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">TIPO DE CONTACTO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {TIPO_CONTACTO}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">FORMA DE CONTACTO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {TIPO_FORMA}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">ESTADO</p>
            <p
              className={
                ESTADO === "01"
                  ? "text-sm font-mono font-medium text-white bg-green-500 p-1 w-3/12 text-center lg:text-left border border-socios-primary"
                  : "text-sm font-mono font-medium text-white bg-red-500 p-1  w-4/12 text-center lg:text-left border border-socios-primary"
              }
            >
              {ESTADO === "01" ? "ACTIVO" : "INACTIVO"}
            </p>
          </div>
          {/*           <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">AUXILIAR</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {AUXILIAR}
            </p>
          </div> */}
        </div>
        {OBSERVACIONES !== "" ? (
          <div className="bg-gray-300 p-4 mt-4">
            <p className="text-sm font-bold text-gray-800">OBSERVACIONES:</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {OBSERVACIONES}
            </p>
          </div>
        ) : null}
        <div className="flex items-center justify-between mt-4">
          <label className="inline-flex items-center mt-3">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-gray-600"
              disabled
              checked={RECIBEINFO}
              id={RECIBEINFO.toString()}
            />
            <span className="ml-2 text-xs text-gray-700">RECIBE INFO</span>
          </label>
          <MainButton
            fullBackground={true}
            onClick={botonEditar}
            texto={"EDITAR"}
          />
        </div>
      </div>
    </>
  );
}
export default BoxContacto;
