import React, { useEffect, useState, useContext } from "react";
import AuthContext from '../../../../context/auth/authContext'
import axiosClient from '../../../../config/axios';

export default function AllObservaciones({ setAll }) {
    const authContext = useContext(AuthContext);
    const [observaciones, setObs] = useState([])
    const { header,servicioSelect } = authContext;
    useEffect(() => {
        const body = {
            idPunto: servicioSelect.nombre,
            cantidad: '50'
        }
        axiosClient
            .post("get_ps_observaciones", body, header)
            .then((response) => {
                let fecha=new Date();
                response.data.data.forEach(element => {
                    fecha=new Date(element.FECHAHORA)
                    let hrs=new Date(fecha).getHours();
                    let min=new Date(fecha).getMinutes();
                    fecha=new Date(fecha).toISOString().split('T')[0];
                    if(hrs<10){hrs='0'+hrs};
                    if(min<10){min='0'+min};
                    element.FECHAHORA=fecha+' '+hrs+':'+min;
                });

                setObs(response.data.data);
               
                // setServiciosPosibles(response.data.data);
                // setServicioSeleccionado(response.data.data[0].ID);
            })
    }, [])

    return (
        <div className="flex flex-col p-2 justify-between items-center w-full">
            <div className='rounded-full flex items-center justify-center m-2' style={{ width: '3rem', height: '3rem' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/622/622218.png" alt="notas" className='w-3/4' />
            </div>
            <ol className='my-4 overflow-y-scroll w-1/2 bg-green' style={{ maxHeight: '20rem' }}>
                {
                    observaciones.length ? observaciones.map(el => {
                        return (
                            <li key={el.FECHAHORA} className='flex justify-center items-center m-2'>
                                <h1>{el.FECHAHORA}</h1>
                                <p className="mx-4"> - </p>
                                <p>{el.OBSERVACIONES}</p>
                            </li>
                        )
                    }) : null
                } 
                
            </ol>
            <div className='flex justify-start items-center mx-2 w-full'>
                <button onClick={() => setAll(false)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '1' }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/130/130882.png" alt="regresar" className='w-1/3' />
                </button>
            </div> 
        </div>)
}