import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  Tooltip,
} from "react-leaflet";
import Formulario from "../UiComponents/Formulario";
import axiosClient from "../../config/axios";
import { useState, useEffect } from "react";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";
import logoEmpresa from "../../assets/img/logo_caer.png";

function Solicitud() {
  const [servicios, setServicios] = useState([
    {
      clave: "cargando",
      nombre: "Cargando...",
    },
  ]);
  const [provincias, setProvincias] = useState([
    {
      clave: "BAI",
      nombre: "Cargando...",
    },
  ]);
  const [partidos, setPartidos] = useState([
    {
      clave: "1",
      nombre: "Cargando...",
    },
  ]);
  const [localidades, setLocalidades] = useState([
    {
      clave: "1122",
      nombre: "Cargando...",
    },
  ]);

  //AWS S3 Config
  Storage.configure({
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_S3_REGION, //OPTIONAL -  Amazon service region
    },
  });

  Auth.configure({
    region: process.env.REACT_APP_AWS_S3_REGION, // REQUIRED - Amazon Cognito Region
    identityPoolId: process.env.REACT_APP_AWS_S3_POOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: "", //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: "", //OPTIONAL - Amazon Cognito Web Client ID
  });

  const [serviciosAux, setServiciosAux] = useState([]);
  const [tipoSocio, setTipoSocio] = useState("socio");

  const subirCarnetA_S3 = async (archivo, dni) => {
    await Storage.put(
      "FOTOCARNET_" +
        dni +
        "_" +
        new Date().toISOString().slice(0, 10) +
        "T" +
        new Date().toLocaleTimeString() +
        "." +
        archivo.name.split(".")[1],
      archivo,
      {
        contentType: archivo.type,
        acl: "public-read",
        customPrefix: {
          public: "public/",
        },
      }
    )
      .then((result) => {
        console.log(result);
        //setAwsResponse(true);
      })
      .catch((err) => {
        console.log(err);
        //setLoadingSubida(false);
      });
  };

  const subirSolicitudAPI = (mergeado) => {
    axiosClient
      .post("add_solicitud", mergeado)
      .then((response) => {
        setEnviandoSolicitud(false);
        setAlertSuccess({
          show: true,
          text: "Solicitud enviada correctamente",
          error: false,
        });
      })
      .catch((err) => {
        setAlertSuccess({
          show: true,
          text: "Hubo un error procesando tu solicitud, por favor intentá más tarde",
          error: true,
        });
        setEnviandoSolicitud(false);
        console.log(err);
      });
  };

  const cambiarTipoSocio = (e) => {
    setTipoSocio(e.target.value);
    var tipoSocio = e.target.value.slice(0, 1).toUpperCase();
    var newServicios = [];
    serviciosAux.map((serv) => {
      if (serv.alcance === tipoSocio || serv.alcance === "T") {
        newServicios.push({ clave: serv.clave, nombre: serv.nombre });
      }
    });
    setServicios(newServicios);
  };

  useEffect(() => {}, [tipoSocio, servicios]);

  const dataFormulario = {
    cantColumnas: 4,
    textoSubmit: "Buscar en Mapa",
    style: "px-2 py-2 rounded border-2 border-socios-primary",
    gap: 4,
    styleButton: "w-full bg-green-400 p-2 m-2 py-2 uppercase text-white hidden",
    styleButtonSubmitting: "hidden",
    id: "form_personal",
    campos: [
      {
        nombre: "Nombre *",
        apiName: "nombre",
        type: "text",
        placeholder: "Tu Nombre *",
        required: true,
      },
      {
        nombre: "Apellido *",
        apiName: "apellido",
        type: "text",
        placeholder: "Tu Apellido *",
        required: true,
      },
      {
        nombre: "Nro.Doc *",
        apiName: "nrodoc",
        type: "number",
        placeholder: "Tu número de Documento *",
        required: true,
      },
      {
        nombre: "Sexo *",
        apiName: "sexo",
        type: "select",
        required: true,
        opciones: [
          {
            clave: "P",
            nombre: "Prefiero no decir",
          },
          {
            clave: "M",
            nombre: "Masculino",
          },
          {
            clave: "F",
            nombre: "Femenino",
          },
          {
            clave: "O",
            nombre: "Otro",
          },
        ],
      },
      {
        nombre: "Fecha de Nacimiento *",
        apiName: "fechanac",
        type: "date",
        required: true,
      },
      {
        nombre: "Teléfono Fijo *",
        apiName: "telefono",
        type: "tel",
        placeholder: "Tú télefono Fijo *",
        required: true,
      },
      {
        nombre: "Teléfono Celular *",
        apiName: "celular",
        type: "tel",
        placeholder: "Tú télefono Celular *",
        required: true,
      },
      {
        nombre: "Email *",
        apiName: "mail",
        type: "email",
        placeholder: "Tu Email de contacto *",
        required: true,
      },
      {
        nombre: "Quiero Ser *",
        apiName: "tipoPersona",
        type: "select",
        required: true,
        dinamicValue: tipoSocio,
        onChange: cambiarTipoSocio,
        opciones: [
          {
            clave: "socio",
            nombre: "Socio",
          },
          {
            clave: "nosocio",
            nombre: "No Socio",
          },
        ],
      },
      {
        nombre: "Estoy interesado en",
        apiName: "servicio",
        type: "select",
        required: true,
        opciones: servicios,
      },

      {
        nombre: "Foto Carnet",
        apiName: "fotocarnet",
        type: "file",
        style:
          "border-2 h-10 pt-0.5 pl-1 border-socios-primary rounded bg-white",
        accept: "image/*",
      },
      /*       {
        nombre: "Referencia",
        apiName: "referencia",
        type: "text",
        placeholder: "Vengo por parte de...",
      }, */
      {
        nombre: "Observaciones",
        apiName: "presentacion",
        type: "textarea",
        span: 4,
        placeholder: "Observaciones Adicionales",
      },
    ],
  };

  const dataFormularioDireccion = {
    cantColumnas: 2,
    textoSubmit: "Ver en Mapa",
    id: "Formulario_Mapa",
    gap: 4,
    style: "px-2 py-2 rounded border-2 border-socios-primary",
    styleButton:
      "w-full bg-blue-500 py-2 rounded text-white transition duration-300 hover:bg-blue-600 mb-6 mt-4",
    styleButtonSubmitting:
      "w-full bg-gray-500 flex justify-center py-2 rounded text-white cursor-not-allowed mb-6 mt-4",
    campos: [
      {
        nombre: "Provincia *",
        apiName: "provincia",
        type: "select",
        opciones: provincias.sort((a, b) => (a.nombre > b.nombre ? 1 : -1)),
      },
      {
        nombre: "Partido *",
        apiName: "partido",
        type: "select",
        opciones: partidos.sort((a, b) => (a.nombre > b.nombre ? 1 : -1)),
      },
      {
        nombre: "Localidad *",
        apiName: "ciudad",
        type: "select",
        opciones: localidades.sort((a, b) => (a.nombre > b.nombre ? 1 : -1)),
      },
      {
        nombre: "Calle *",
        apiName: "calle",
        type: "text",
        placeholder: "Ej: Calle 14",
        required: true,
      },
      {
        nombre: "Número *",
        apiName: "numero",
        type: "number",
        placeholder: "Ej: 500",
        required: true,
      },
      {
        nombre: "Piso",
        apiName: "piso",
        type: "number",
        placeholder: "Ej: 1",
      },

      {
        nombre: "Dpto",
        apiName: "dpto",
        type: "text",
        placeholder: "Ej: A",
      },
    ],
  };

  const centroMapa = [-36.270691, -61.197114];
  const [pin, setPin] = useState(null);
  const [datosDireccion, setDatosDireccion] = useState({});
  const [datosDireccionAPI, setDatosDireccionAPI] = useState({});
  const [datosPersonales, setDatosPersonales] = useState({});
  const [alert, setAlert] = useState({
    show: false,
    text: "",
  });
  const [alertSuccess, setAlertSuccess] = useState({
    show: false,
    text: "",
    error: false,
  });
  const [enviandoSolicitud, setEnviandoSolicitud] = useState(false);

  useEffect(() => {
    axiosClient
      .get("get_tablas_libre")
      .then((response) => {
        setServiciosAux(response.data.data.SERVICIOS);
        var auxServ = [];
        var auxTipoSocio = tipoSocio.slice(0, 1).toUpperCase();
        response.data.data.SERVICIOS.map((serv) => {
          if (serv.alcance === auxTipoSocio || serv.alcance === "T") {
            auxServ.push({ clave: serv.clave, nombre: serv.nombre });
          }
        });
        setProvincias(response.data.data.PROVINCIA);
        setLocalidades(response.data.data.LOCALIDADES);
        setPartidos(response.data.data.PARTIDOS);
        setServicios(auxServ);
      })
      .catch((err) => {
        setAlertSuccess({
          show: true,
          text: "Estamos teniendo problemas técnicos, por favor intentá más tarde",
          error: true,
        });
        console.log(err);
      });
  }, []);

  const buscarDireccion = async (values, setSubmitting) => {
    var provincia = provincias.filter(
      (prov) => prov.clave === values.provincia
    )[0].nombre;
    var ciudad = localidades.filter((loc) => loc.clave === values.ciudad)[0]
      .nombre;
    var partido = partidos.filter((part) => part.clave === values.partido)[0]
      .nombre;

    var queryString = `${values.calle} ${values.numero}, ${ciudad}, ${partido}, ${provincia}`;
    await axiosClient
      .post("query_api", { q: queryString })
      .then((response) => {
        if (response.data.data.items.length > 0) {
          setDatosDireccion(values);
          setDatosDireccionAPI(response.data.data.items);
          setPin(response.data.data.items[0].position);
        } else {
          setDatosDireccion(values);
          setAlert({
            show: true,
            text: "No pudimos ubicar tu dirección en el mapa. No te preocupes, podés enviar la solicitud igualmente",
          });
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          show: true,
          text: "Hubo un error consultando la dirección, intentá más tarde",
        });
        setSubmitting(false);
      });
  };

  const guardarDatosPersonales = (values, setSubmitting) => {
    setDatosPersonales(values);
    setSubmitting(false);
  };

  function MyComponent(props) {
    const map = useMap();
    if (pin !== null) {
      map.flyTo(props.latlng, 15);
    }
    return null;
  }

  const submitToAPI = async () => {
    var formPersonal = document.getElementById("form_personal");
    var formDireccion = document.getElementById("Formulario_Mapa");
    if (!formPersonal.checkValidity() || !formDireccion.checkValidity()) {
      formPersonal.requestSubmit();
      formDireccion.requestSubmit();
      return false;
    }

    if (pin === null && !formDireccion.checkValidity()) {
      formDireccion.requestSubmit();
    } else {
      if (
        Object.keys(datosDireccion).length === 0 &&
        Object.keys(datosDireccionAPI).length === 0
      ) {
        setAlert({
          show: true,
          text: "Por favor, hacé click en 'Ver en Mapa' para verificar tu dirección antes de enviar la solicitud",
        });
        return false;
      }
    }

    if (formPersonal.checkValidity() && formDireccion.checkValidity()) {
      setEnviandoSolicitud(true);
      var payload1 = Object.fromEntries(new FormData(formPersonal));

      payload1.servicio = servicios.filter(
        (serv) => serv.clave.toString() === payload1.servicio
      )[0].nombre;

      var payload2 = Object.fromEntries(new FormData(formDireccion));

      var payload3 = {
        codPais: "0",
        codProv:
          Object.keys(datosDireccionAPI).length > 0
            ? datosDireccionAPI[0].address.stateCode
            : "0000",
        CP:
          Object.keys(datosDireccionAPI).length > 0
            ? datosDireccionAPI[0].address.postalCode
            : "0000",
        lat:
          Object.keys(datosDireccionAPI).length > 0
            ? datosDireccionAPI[0].position.lat
            : "0.000",
        long:
          Object.keys(datosDireccionAPI).length > 0
            ? datosDireccionAPI[0].position.lng
            : "0.000",
      };

      var payload4 = {
        tipocomplemento: "00",
        complemento: "0",
      };

      payload1.urlCarnet = "";
      if (payload1.fotocarnet.size > 0) {
        payload1.urlCarnet =
          process.env.REACT_APP_AWS_URL +
          "FOTOCARNET_" +
          payload1.nrodoc +
          "_" +
          new Date().toISOString().slice(0, 10) +
          "T" +
          new Date().toLocaleTimeString() +
          "." +
          payload1.fotocarnet.name.split(".")[1];
        subirCarnetA_S3(payload1.fotocarnet, payload1.nrodoc)
          .then(() => {
            delete payload1.fotocarnet;
            var mergeado = {
              ...payload1,
              ...payload2,
              ...payload3,
              ...payload4,
            };
            subirSolicitudAPI(mergeado);
          })
          .catch(() => {
            delete payload1.fotocarnet;
            var mergeado = {
              ...payload1,
              ...payload2,
              ...payload3,
              ...payload4,
            };
            subirSolicitudAPI(mergeado);
          });
      } else {
        delete payload1.fotocarnet;
        var mergeado = {
          ...payload1,
          ...payload2,
          ...payload3,
          ...payload4,
        };
        subirSolicitudAPI(mergeado);
      }
    }
  };

  useEffect(() => {}, [pin]);

  useEffect(() => {
    var element = document.getElementById("alert");
    if (element !== null) {
      var rect = element.getBoundingClientRect();
      window.scrollTo({ top: rect.top - 20, bottom: 0, behavior: "smooth" });
    }
  }, [alert]);

  useEffect(() => {
    var element = document.getElementById("alert2");
    if (element !== null) {
      var rect = element.getBoundingClientRect();
      window.scrollTo({ top: rect.top - 20, bottom: 0, behavior: "smooth" });
    }
  }, [alertSuccess]);

  return (
    <div className="flex lg:p-4 mt-8 lg:mt-6 m-2 justify-center flex-col gap-4">
      {alertSuccess.show && alertSuccess.text !== "" ? (
        <div
          className={
            !alertSuccess.error
              ? "flex flex-col lg:flex-row lg:justify-between items-center mt-2 bg-green-500 text-left rounded py-2 lg:py-5 px-6 border-l-8 border-green-700"
              : "flex flex-col lg:flex-row lg:justify-between items-center mt-2 bg-red-500 text-left rounded py-2 lg:py-5 px-6 border-l-8 border-red-700"
          }
          id="alert2"
        >
          <div className="flex flex-col lg:flex-row items-center justify-center -ml-2 gap-2">
            {!alertSuccess.error ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-9 w-9 lg:h-7 lg:w-7 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="h-9 w-9 lg:h-7 lg:w-7 text-white"
                viewBox="0 0 256 256"
              >
                <path
                  d="M128 24a104 104 0 1 0 104 104A104.118 104.118 0 0 0 128 24zm-8 56a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12z"
                  fill="currentColor"
                ></path>
              </svg>
            )}

            <p className="text-lg lg:text-xl text-white">{alertSuccess.text}</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mt-3 mr-2 lg:mr-0 lg:mt-0 cursor-pointer text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => {
              var newAlert = { ...alertSuccess };
              newAlert.show = false;
              setAlertSuccess(newAlert);
            }}
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      ) : null}
      <div className="bg-gray-300 border-socios-primary border rounded">
        <div className="flex justify-center mt-2">
          <img src={logoEmpresa} className="h-24" />
        </div>
        <h1 className="text-center mt-2 text-4xl">ASOCIATE</h1>
        <p className="text-center mt-2 p-2">
          Los campos requeridos están marcados con un *
        </p>
        <div className="flex justify-center">
          <p className="text-center py-1 rounded bg-socios-primary text-white w-8/12 lg:w-2/12 mt-4 -mb-8">
            DATOS PERSONALES
          </p>
        </div>
        <div className="p-2 lg:p-4 mt-8">
          <Formulario
            data={dataFormulario}
            funcionForm={guardarDatosPersonales}
          />
        </div>
      </div>
      {alert.show && alert.text !== "" ? (
        <div
          className="flex flex-col lg:flex-row lg:justify-between items-center -mb-6 mt-2 bg-yellow-400 text-left rounded py-2 px-6 border-l-8 border-yellow-700"
          id="alert"
        >
          <div className="flex flex-col lg:flex-row items-center -ml-2  gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7 lg:h-5 lg:w-5 text-gray-700"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
                clip-rule="evenodd"
              />
            </svg>
            <p className="text-lg lg:text-xl">{alert.text}</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mt-3 mr-2 lg:mr-0 lg:mt-0 cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => {
              var newAlert = { ...alert };
              newAlert.show = false;
              setAlert(newAlert);
            }}
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      ) : null}

      <div className=" flex flex-col lg:flex-row gap-4 mt-2 py-6">
        <div className="bg-gray-300 border-socios-primary border rounded px-2 py-1 w-full lg:w-1/2">
          <div className="flex justify-center">
            <p className="text-center py-1 rounded bg-socios-primary text-white w-8/12 lg:w-4/12 mt-6 ">
              DATOS DE DIRECCIÓN
            </p>
          </div>
          <div className="mt-4 p-2">
            <Formulario
              data={dataFormularioDireccion}
              funcionForm={buscarDireccion}
            />
          </div>
        </div>
        <div className="border-2 border-socios-primary w-full lg:w-1/2 rounded">
          <MapContainer
            center={centroMapa}
            dragging={window.innerWidth > 800 ? true : false}
            touchZoom={true}
            zoom={6}
            scrollWheelZoom={true}
          >
            <MyComponent latlng={pin} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker draggable={false} position={pin !== null ? pin : [0, 0]}>
              <Tooltip direction="top" permanent>
                Esta es tu ubicación aproximada.
              </Tooltip>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <button
        className={
          enviandoSolicitud
            ? "px-2 py-3 text-2xl bg-gray-600 mt-2 text-gray-200 rounded cursor-not-allowed"
            : "px-2 py-3 text-2xl bg-green-400 mt-2 text-white rounded transition duration-300 hover:bg-green-500"
        }
        disabled={enviandoSolicitud}
        onClick={() => {
          submitToAPI();
        }}
      >
        {enviandoSolicitud ? (
          <div className="flex gap-4 items-center justify-center">
            <svg
              className="animate-spin h-6 w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <p>ENVIANDO SOLICITUD</p>
          </div>
        ) : (
          "ENVIAR SOLICITUD"
        )}
      </button>
    </div>
  );
}

export default Solicitud;
