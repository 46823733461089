import React ,{useContext, useEffect}from 'react';
import AuthContext from '../../../../context/auth/authContext';
import tilde from '../../../../assets/img/tilde_guardado.png'
import axiosClient from '../../../../config/axios';

export default function GuardarAsistencia({ setSeleccionado, setBusqueda, setSearch, checkbox, data, setCheckbox }) {
    const authContext = useContext(AuthContext);
    const {  servicioSelect, header} = authContext;
    
    
    const bodyAsistencia = {
        idPersona: (data.data.persona[0].ID_PERSONA).toString(),
	    idPuntoServicio: servicioSelect.nombre,
	    observacion : document.querySelector("#observacionAsistencia").value
    } 
     useEffect(() => {
        
        axiosClient
            .post("add_ps_asistencia",bodyAsistencia, header)
            .then((response) => {
                console.log(response);
                
            })
            .catch((err) => {
                console.log(err);
                
            }); 
    }, []); 

    return (
        <>
            <div className='flex w-full justify-end'>
                <button
                    onClick={() => {
                        setBusqueda(false)
                        setSeleccionado(false)
                        setCheckbox(null)
                        setSearch(null)
                    }}
                    className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                </button>
            </div>
            <div className='w-full mx-6 mb-4 '>
                <h2>Nombre: {data.data.persona[0].NOMBRE} {data.data.persona[0].APELLIDO}</h2>
                <h2>N° de Socio: {data.data.persona[0].NROSOCIO}</h2>
                <h2>Estado: {data.data.persona[0].ESTADO === "A" ? "Activo" : "No activo"}</h2>
            </div>
            <div className='flex items-center justify-around flex-col mt-4 mb-10 w-full'>
                <h2>Asistencia Guardada</h2>
                <div className='flex items-center justify-around w-1/4 m-2'>
                    <img src={tilde} alt="tilde" className='w-1/2' />
                </div>
            </div>
        </>
    )
}