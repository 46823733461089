export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";

export const LOGING_SUCCESS = "LOGING_SUCCESS";
export const LOGING_ERROR = "LOGING_ERROR";
export const GET_USER = "GET_USER";
export const LOG_OUT = "LOG_OUT";
export const SET_FECHA = "SET_FECHA";

export const SEARCH_PERSONA = "SEARCH_PERSONA";

export const SET_SERVICIO_SELECCIONADO = "SET_SERVICIO_SELECCIONADO";
export const SET_ESTADO_PTO_SERVICIO = "SET_ESTADO_PTO_SERVICIO";