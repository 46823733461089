import React, {useContext,useState, useEffect} from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";

function ModalFechaVencimiento({fechaVenc}) {
    const buscadorContext = useContext(BuscadorContext);
    const { setModalFechaVencimiento,setFechaVenc} = buscadorContext;
    const [fecha,setFecha] = useState(fecha);
    const [fechaMinima, setFechaMinima] = useState(new Date().toISOString().split('T')[0]);  
    const authContext = useContext(AuthContext);
    function setFechaVencEnModal(e){    
      setFechaVenc(e);
      setModalFechaVencimiento(false);   
    }

    useEffect(() => {
      setFecha( fechaSugerida());
    }, [fechaVenc]);
   
    function fechaSugerida(){
      let fecha=new Date();
      let fechaSugerida;
      if((fecha.getMonth()+1)<12){
        fechaSugerida=fecha.getFullYear()+"-"+(fecha.getMonth()+2)+"-"+10;
      }else{
        fechaSugerida=(fecha.getFullYear()+1)+"-"+(fecha.getMonth()-10)+"-"+10;
      }
      fecha = new Date(fechaSugerida).toISOString().split('T')[0];
      return fecha;
    }


    
    
  
    return (
      <div className="fixed z-10 inset-0 overflow-y-auto py-0">
            <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >                    
                  <div className="p-3 flex items-center justify-between">
                      <div className='rounded-full flex items-center justify-center' >
                        <h4 className="text-3xl font-bold">Fecha de Vencimiento</h4>
                      </div>
                      
                  </div>
                  <div className='flex flex-col justify-between items-center mb-10'>
                    <input type="date" id="fecha"  className='p-2 rounded-md bg-black opacity-50 text-white my-4' defaultValue={fecha} min={fechaMinima}></input>
                    <button className="bg-black opacity-80 text-white p-3 w-1/3 rounded-md m-3" id="continue" onClick={(e) =>setFechaVencEnModal(document.querySelector("#fecha").value)}>Continuar</button>
                  </div>
                </div>
            </div>
        </div>
    )
    
  }
  export default ModalFechaVencimiento;
  