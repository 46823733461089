import React ,{useContext, useEffect, useState}from 'react';
import AuthContext from '../../../../context/auth/authContext';
import axiosClient from '../../../../config/axios';


export default function NuevoServicio({ setSeleccionado, setBusqueda, setSearch, data, setCheckbox,handlePage,page }) {
    const authContext = useContext(AuthContext);
    const {  servicioSelect, header} = authContext;
    //let fechaHoy=obtenerFechaHora();
    const today = new Date();
    const date = today.setDate(today.getDate() ); 
    const fechaHoy = new Date(date).toISOString().split('T')[0] // aaaa-mm-dd
    const hora  = controlHora();
    function controlHora(){
        let hrs=new Date().getHours();
        let min=new Date().getMinutes();
        if(hrs<10){hrs='0'+hrs};
        if(min<10){min='0'+min};
        let horaAct=(hrs+':'+min).toString();
        return horaAct;
    }

    function removeItemFromArr ( arr, item ) {
        var i = arr.indexOf( item ).id;     
        if ( i !== -1 ) {
            arr.splice( i, 1 );
        }
    }
    
    function guardarYcobrar(e){
        removeItemFromArr(data.data.servPosibles,data.data.servicioNuevo);
        

        const bodyNuevoServicio={
            idPersona: data.data.persona[0].ID_PERSONA,
            idServicio:data.data.servicioNuevo[0],
            fechaInicio:new Date(document.querySelector('#fecha').value+" "+document.querySelector('#hora').value),
            fechaFin:null,
            bonificacion: "0"
        };
        data.data.servicios=[];
        data.data.servicioNuevo=[];   
        axiosClient
            .post("add_ps_nuevoservicio",bodyNuevoServicio, header)
            .then((response) => {
                console.log(response);
                axiosClient
                .post("get_ps_persona", { dniPersona:data.data.persona[0].NROSOCIO , idPuntoServicio:servicioSelect.nombre}, header)
                .then((response) => {
                    data.data.servAdquiridos=response.data.servAdquiridos;   
                    handlePage(page = 1);
                })
                .catch((err) => {
                    console.log(err);       
                });
                
            })
            .catch((err) => {
                console.log(err);
                
            });  
            
    }
    return (
        <>
            <div className='flex w-full justify-end'>
                <button
                    onClick={() => {
                        setBusqueda(false)
                        setSeleccionado(false)
                        setCheckbox(null)
                        setSearch(null)
                    }}
                    className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                </button>
            </div>
            <div className='w-full mx-6 mb-4 '>
                <h2>Nombre: {data.data.persona[0].NOMBRE} {data.data.persona[0].APELLIDO}</h2>
                <h2>N° de Socio: {data.data.persona[0].NROSOCIO}</h2>
                <h2>Estado: {data.data.persona[0].ESTADO === "A" ? "Activo" : "No activo"}</h2>
            </div>
            <div className='flex col pl-8 my-10 w-full'>
                <div className='d-flex col w-6/12'>
                    <div className='d-flex'>
                        <input type="date" id="fecha" className='p-2 rounded-md bg-black opacity-50 text-white my-4' defaultValue={fechaHoy} min={fechaHoy}></input>
                        <input type="time" id="hora" className='p-2 rounded-md bg-black opacity-50 text-white ml-4' defaultValue={hora}></input>
                        

                    </div>
                    <div className='d-flex'>
                        <button type="button" className='bg-black opacity-80 text-white p-2 w-1/8 rounded-md' onClick={(e)=>guardarYcobrar(e)}>Guardar y cobrar</button>
                        <button type='button' className='bg-black opacity-80 text-white p-2 ml-4 w-1/8 rounded-md'>Guardar</button>                        
                    </div>                 
                   
                </div>
                <div className='d-flex mr-5 row w-6/12'>                   
                    <textarea className='w-full  my-15  rounded-md' rows={4} style={{ resize: 'none' }} >Observación </textarea>                                    
                </div>
            </div>
            
        </>
    )
}