import React, { useState, useContext } from 'react'
import Asistencia from './Modals/Asistencia';
import Busqueda from './Modals/Busqueda';
import Estadisticas from './Modals/Estadisticas';
import Observaciones from './Modals/Observaciones';
import AuthContext from '../../../context/auth/authContext'
import tilde from '../../../assets/img/tilde_guardado.png'
export default function Menu({ open }) {
    const [busqueda, setBusqueda] = useState(false);
    const [estadisticas, setEstadisticas] = useState(false);
    const [asistencia, setAsistencia] = useState(false);
    const [observaciones, setObservaciones] = useState(false);
    const authContext = useContext(AuthContext);
    const { header,servicioSelect } = authContext;
    
    function control(){
        if(servicioSelect!=null){
            setObservaciones(true);
        }
    }
    return (
        <>
            {
                busqueda  ? authContext.estadoPtoServicio.estado==1?<Busqueda setBusqueda={setBusqueda} /> : null : null
            }
            {
                asistencia ? <Asistencia setAsistencia={setAsistencia} /> : null
            }
            {
                estadisticas ? <Estadisticas setEstadisticas={setEstadisticas} /> : null
            }
            {
                observaciones ? <Observaciones setObservaciones={setObservaciones} /> : null
            }
            <button onClick={(e) => setBusqueda(true)} className='rounded-full bg-slate_600 flex items-center justify-center' style={{ width: '5rem', height: '5rem' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/751/751463.png" alt="lupa" className='w-1/2' />
            </button>
            <button onClick={() => setEstadisticas(true)} className='rounded-full bg-slate_600 flex items-center justify-center' style={{ width: '5rem', height: '5rem' }}>
                <img src={tilde} alt="tilde" className='w-1/2' />
            </button>
            <button onClick={e => control()} className='rounded-full bg-slate_600 flex items-center justify-center' style={{ width: '5rem', height: '5rem' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/622/622218.png" alt="notas" className='w-1/2' />
            </button>
            <button onClick={() => setAsistencia(true)} className='rounded-full bg-slate_600 flex items-center justify-center' style={{ width: '5rem', height: '5rem' }}>
                <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" className='w-1/2' />
            </button>
        </>
    )
}