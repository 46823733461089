import { SEARCH_PERSONA } from '../../types/index'

export default (state, action) => {
    switch (action.type) {
        case SEARCH_PERSONA:
            return {
                data: action.payload
            }
        default: 
            return state
    }
};
