import React, { useContext, useState } from "react";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";

function ModalBajaTotal(props) {
  const buscadorContext = useContext(BuscadorContext);
  const { setModalBajaTotal, serviciosUpdate, setServiciosUpdate } =
    buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [datosAlert, setDatosAlert] = useState({
    mostrar: false,
    mensaje: "Hubo un error, intentá más tarde",
  });
  const [loading, setLoading] = useState(false);
  const [fechaBaja, setFechaBaja] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const bajaTotalAPI = () => {
    if (fechaBaja === "") {
      setDatosAlert({
        mostrar: true,
        mensaje: "Completá la fecha de baja",
      });
      return false;
    }
    var payload = {
      idServicio: props.servicios.filter(
        (serv) => serv.NOMBRE === props.nombreServicio
      )[0].ID,
      fechaFin: fechaBaja,
    };
    axiosClient
      .post("terminar_servicio_general", payload, header)
      .then((response) => {
        setAlert("Baja de contratos total realizado con éxito", "green-300");
        setModalBajaTotal(false);
        setServiciosUpdate(!serviciosUpdate);
      })
      .catch((err) => {
        console.log(err);
        setDatosAlert({
          mostrar: true,
          mensaje: err.response.data.data
            ? err.response.data.data
            : "Ocurrió un error, intentá más tarde",
        });
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Baja de todos los contratos
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Completá la fecha de baja, y hacé click en "Baja Total"
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalBajaTotal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          {datosAlert.mostrar ? (
            <div className="bg-red-500 p-4 m-4 border-socios-primary border-2 text-md text-white font-bold flex justify-between items-center">
              <p className="flex flex-row items-center gap-2 lg:gap-1">
                <svg className="w-6 h-6" viewBox="0 0 24 24">
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                    fill="currentColor"
                  ></path>
                </svg>{" "}
                {datosAlert.mensaje}
              </p>
              <svg
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  setDatosAlert({ mostrar: false, mensaje: "" });
                }}
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14v14M17 8.4L13.4 12l3.6 3.6l-1.4 1.4l-3.6-3.6L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7L17 8.4z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          ) : null}

          <div className="border-4 border-socios-primary p-4 m-4 rounded">
            <div className="flex flex-col">
              <p className="text-xl font-bold text-center bg-red-500 py-1 text-white rounded">
                ATENCIÓN
              </p>
              <h1 className="text-lg text-center p-4 mt-2 font-bold ">
                Esta acción dará de baja TODOS los contratos ACTIVOS del
                SERVICIO
              </h1>
            </div>
          </div>
          <div className="p-4 flex flex-col gap-1">
            <label className="font-bold">Fecha de Baja *</label>
            <input
              type="date"
              className="w-full h-9 bg-gray-200 border-socios-primary border-2 px-2"
              value={fechaBaja}
              onChange={(e) => setFechaBaja(e.target.value)}
            />
          </div>

          <div className="bg-gray-200 flex lg:flex-row flex-wrap gap-4 lg:gap-2 justify-between m-4 mt-6">
            <button
              className={
                loading
                  ? "px-12 bg-gray-500 text-white py-2 border-socios-primary border-2 cursor-not-allowed w-full lg:w-5/12"
                  : "px-12 bg-yellow-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-yellow-600 lg:w-5/12 w-full"
              }
              onClick={() => {
                bajaTotalAPI();
              }}
              disabled={loading}
            >
              {loading ? "Procesando..." : "Baja Total"}
            </button>
            <button
              className={
                loading
                  ? "px-12 bg-gray-500 text-white py-2 border-socios-primary border-2 cursor-not-allowed w-full lg:w-5/12"
                  : "px-12 bg-red-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-red-600 w-full lg:w-5/12"
              }
              onClick={() => {
                setModalBajaTotal(false);
              }}
              disabled={loading}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalBajaTotal;
