import React, { useContext } from "react";
import Formulario from "../../UiComponents/Formulario";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function ModalEditarDirección({ setModalEditarDireccion, data }) {
  const buscadorContext = useContext(BuscadorContext);
  const { datosTablas, datosPersona, editarDatosDireccion } = buscadorContext;
  const formData = {
    cantColumnas: 4,
    gap: 2,
    textoSubmit: "GUARDAR",
    id: "form_editar_direccion",
    style:
      "mt-1 block w-full leading-6 text-sm text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out disabled:bg-gray-400 disabled:text-gray-900 disabled:border-gray-500 duration-300 opacity-100",
    styleButton:
      "bg-green-500 disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-200 text-white uppercase px-8 text-sm py-1 border-green-500 border-2 hover:bg-green-600 hover:border-green-600 transition duration-300 ease-in-out mt-2",
    styleButtonSubmitting:
      "bg-gray-500 py-1 mt-2 flex justify-center text-gray-200 w-32",
    styleLabel: "uppercase text-gray-800",
    //disabled: editar,
    campos: [
      {
        nombre: "Tipo",
        apiName: "tipo",
        type: "select",
        required: true,
        value: datosTablas.TIPO_DIRE.filter(
          (tipoD) => tipoD.nombre === data.TIPO_DIRE
        )[0]["clave"],
        span: 2,
        opciones: datosTablas.TIPO_DIRE,
      },
      {
        nombre: "Estado",
        apiName: "estado",
        type: "select",
        required: true,
        value: datosTablas.ESTADO_DIR.filter(
          (estado) => estado.nombre === data.ESTADO
        )[0]["clave"],
        opciones: datosTablas.ESTADO_DIR,
        span: 2,
      },
      {
        nombre: "País",
        apiName: "pais",
        type: "select",
        required: true,
        value: datosTablas.PAIS.filter(
          (pais) => pais.clave === data.CODPAIS
        )[0]["clave"],
        opciones: datosTablas.PAIS.sort((a, b) =>
          a.nombre > b.nombre ? 1 : -1
        ),
      },
      {
        nombre: "Provincia",
        apiName: "provincia",
        type: "select",
        required: true,
        value: data.CODPROVINCIA,
        opciones: datosTablas.PROVINCIA.sort((a, b) =>
          a.nombre > b.nombre ? 1 : -1
        ),
      },
      {
        nombre: "Partido",
        apiName: "partido",
        type: "select",
        required: true,
        value: datosTablas.PARTIDOS.filter(
          (part) => part.clave === data.PARTIDO
        )[0]["clave"],
        opciones: datosTablas.PARTIDOS.sort((a, b) =>
          a.nombre > b.nombre ? 1 : -1
        ),
      },
      {
        nombre: "Localidad",
        apiName: "localidad",
        type: "select",
        required: true,
        placeholder: "Localidad",
        value: datosTablas.LOCALIDADES.filter(
          (localidad) => localidad.clave === data.CIUDAD
        )[0]["clave"],
        opciones: datosTablas.LOCALIDADES.sort((a, b) =>
          a.nombre > b.nombre ? 1 : -1
        ),
      },
      {
        nombre: "Código Postal",
        apiName: "codigo_postal",
        type: "number",
        required: true,
        placeholder: "Código Postal",
        value: data.CP,
      },
      {
        nombre: "Calle",
        apiName: "calle",
        type: "text",
        required: true,
        placeholder: "Calle",
        value: data.CALLE,
      },
      {
        nombre: "Número",
        apiName: "numero",
        type: "number",
        placeholder: "Número",
        value: data.NUMERO,
      },
      {
        nombre: "Piso",
        apiName: "PISO",
        type: "number",
        placeholder: "Piso",

        value: data.PISO,
      },
      {
        nombre: "Departamento",
        apiName: "DEPARTAMENTO",
        type: "number",
        placeholder: "Dpto",

        value: data.DEPARTAMENTO,
      },
      {
        nombre: "Tipo Complemento",
        apiName: "tipo_complemento",
        type: "select",
        required: true,
        value: datosTablas.TIPOCOMPLEMENTO.filter(
          (comp) => comp.nombre === data.TIPOCOMPLEMENTO
        )[0]["clave"],
        opciones: datosTablas.TIPOCOMPLEMENTO.sort((a, b) =>
          a.nombre > b.nombre ? 1 : -1
        ),
      },
      {
        nombre: "Complemento",
        apiName: "complemento",
        type: "text",
        required: true,
        placeholder: "Complemento",
        value: data.COMPLEMENTO,
      },
      {
        nombre: "Descripción",
        apiName: "DESCRIPCION",
        value: data.DESCRIPCION,
        type: "textarea",
        span: 4,
        row: 5,
      },
      {
        nombre: "Observaciones",
        apiName: "OBSERVACIONES",
        value: data.OBSERVACIONES,
        type: "textarea",
        span: 4,
        row: 5,
      },
    ],
  };

  const editarDireccion = (valores, setSubmitting) => {
    var payload = {
      idPersoDire: data.ID,
      idPersona: datosPersona.ID_PERSONA,
      tipoDire: valores.tipo,
      pais: valores.pais,
      codPais: valores.pais,
      provincia: valores.provincia,
      codProv: valores.provincia,
      partido: valores.partido,
      localidad: valores.localidad,
      codPos: valores.codigo_postal,
      tipoCalle: "Calle",
      ciudad: valores.localidad,
      calle: valores.calle,
      numero: valores.numero,
      tipoComplemento: valores.tipo_complemento,
      complemento: valores.complemento,
      piso: valores.PISO,
      dpto: valores.DEPARTAMENTO,
      desc: valores.DESCRIPCION,
      obs: valores.OBSERVACIONES,
      lat: data.LAT,
      long: data.LNG,
      estado: valores.estado,
    };
    editarDatosDireccion(payload)
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-4/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Editar Dirección
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Editar la dirección del socio.
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalEditarDireccion(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-gray-200 px-4 my-2 pb-4 sm:p-6 sm:pb-4">
            <Formulario data={formData} funcionForm={editarDireccion} />
          </div>
          {/*           <div className="border-t-2 border-gray-500 p-4 flex items-center justify-end">
            <Button
              onClick={() => alert("hola")}
              fullBackground={true}
              texto="guardar"
              background="green-500"
              hoverBackground="green-600"
              color="white"
              hoverColor="white"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default ModalEditarDirección;
