import React, { useState, Fragment, useContext } from "react";
import BodyAsistencia from "./BodyAsistencia";
import Nav from "../../Nav";
import Footer from "../../Footer";
import SideNav from "../../SideNav/SideNav";
import Buscador from "../../Socio/Buscador";
import Resultados from "../../Socio/Resultados";
import BuscadorContext from "../../../context/buscador/buscadorContext";
function Socios() {
  const [abrirMenu, setAbrirMenu] = useState(true);

  const buscadorContext = useContext(BuscadorContext);
  const { datosPersona, personasBuscadas } = buscadorContext;
  return (
    <Fragment>
      <Nav />
      <div
        className={abrirMenu ? "xl:grid xl:grid-cols-6 xl:gap-0" : "xl:flex"}
      >
        <div className="col-span-1 p-4 border border-t-0 border-l-0 border-b-0 border-gray-400">
          <div className={abrirMenu ? "" : "flex justify-center"}>
            <button onClick={() => setAbrirMenu(!abrirMenu)}>
              {abrirMenu ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              )}
            </button>

            {abrirMenu ? <Buscador /> : null}

            {personasBuscadas.length > 0 ? (
              abrirMenu ? (
                <div className="mt-10 pt-4 border border-b-0 border-r-0 border-l-0 border-gray-500">
                  <Resultados />
                </div>
              ) : null
            ) : null}

            {datosPersona &&
              Object.keys(datosPersona).length !== 0 &&
              datosPersona.constructor === Object ? (
              abrirMenu ? (
                <div className="mt-10 pt-8 border border-b-0 border-r-0 border-l-0 border-gray-500">
                  <SideNav />
                </div>
              ) : null
            ) : null}
          </div>
        </div>
        <div
          className={
            abrirMenu
              ? "col-span-5 px-4 py-4 min-h-screen border border-gray-400 xl:border-0"
              : "px-4 py-4 min-h-screen border border-gray-400 xl:border-0 w-full"
          }
        >
          <BodyAsistencia />
        </div>
      </div>
      <Footer />
    </Fragment>
  );
}
export default Socios;
