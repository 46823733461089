import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosClient from "../../config/axios";

function Confirmacion() {
  const [confirmando, setConfirmando] = useState(true);
  const [errorConfirmando, setErrorConfirmando] = useState(false);
  let location = useLocation();
  const codigoUnico = new URLSearchParams(location.search).get("token");
  const nombrePersona = new URLSearchParams(location.search).get("nombre");
  const idSolicitud = new URLSearchParams(location.search).get("idSolicitud");

  if (codigoUnico === null || codigoUnico === "") {
    window.location.href = "/";
  }

  if (nombrePersona === null || nombrePersona === "") {
    window.location.href = "/";
  }

  useEffect(() => {
    var data = {
      codigo: codigoUnico,
      idSolicitud: idSolicitud,
    };
    axiosClient
      .post("validar_codigo", data)
      .then((response) => {
        console.log(response);
        setConfirmando(false);
      })
      .catch((err) => {
        console.log(err);
        setConfirmando(false);
        setErrorConfirmando(true);
      });
  }, []);

  return (
    <div className="m-4 bg-gray-400 rounded p-4 lg:my-40 border-2 flex justify-center border-socios-primary">
      <div className="p-4 my-12 lg:w-1/2 text-center text-white bg-socios-primary rounded">
        <p className="text-2xl lg:text-3xl">¡Hola Usuario!</p>
        <div className="mt-4">
          <p className="text-xl lg:text-2xl">
            Aguardá unos momentos mientras confirmamos tu Email
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          <div className="rounded gap-2 flex flex-row justify-center items-center text-lg text-socios-primary bg-gray-50 py-2 px-4 lg:w-1/2">
            {confirmando ? (
              <svg
                className="animate-spin h-6 w-6 text-socios-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : !errorConfirmando ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            )}

            <p>
              {confirmando
                ? "Confirmando Email"
                : !errorConfirmando
                ? "Confirmación Exitosa"
                : "Error Confirmando Email"}
            </p>
          </div>
          <button
            className={
              confirmando || errorConfirmando
                ? "mt-4 flex flex-row gap-2 rounded font-bold px-9 lg:px-12 py-2 bg-gray-400 cursor-not-allowed"
                : "mt-4 flex flex-row gap-2 rounded transition duration-300 hover:bg-blue-700 font-bold px-9 lg:px-12 py-2 bg-blue-600"
            }
            disabled={confirmando || errorConfirmando}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
            <p>Ir a Inicio de Sesión</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Confirmacion;
