import React, { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function ModalHistorialEstados({ setModalHistorialEstados, data }) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatDate } = buscadorContext;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-4/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Historial de Estados
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Historial de estados del socio
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalHistorialEstados(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-gray-200 px-4 my-2 pb-4 sm:p-6 sm:pb-4">
            {data.length > 0
              ? data.map((i) => (
                  <div className="border-2 border-socios-primary p-4 mb-4">
                    <div className={`xl:grid xl:grid-cols-4 gap-6`}>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">FECHA</p>
                        <p className="text-sm font-mono font-medium text-gray-600">
                          {formatDate(i.FECHA)}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          ESTADO
                        </p>
                        <p className="text-sm font-mono font-medium text-gray-600">
                          {i.ESTADO_POS}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          CATEGORÍA
                        </p>
                        <p className="text-sm font-mono font-medium text-gray-600">
                          {i.CATEGORIA_POS}
                        </p>
                      </div>
                      <div className=" lg:m-0 mb-2">
                        <p className="text-sm font-bold text-gray-800">
                          MOTIVO
                        </p>
                        <p className="text-sm font-mono font-medium text-gray-600">
                          {i.MOTIVO}
                        </p>
                      </div>
                    </div>
                    {i.OBSERVACIONES !== "" ? (
                      <div className="bg-gray-300 p-4 mt-4">
                        <p className="text-sm font-bold text-gray-800">
                          OBSERVACIONES:
                        </p>
                        <p className="text-sm font-mono font-medium text-gray-600">
                          {i.OBSERVACIONES}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalHistorialEstados;
