import React, { useState, useContext, useEffect } from 'react';
import axiosClient from '../../../config/axios';
import AuthContext from '../../../context/auth/authContext';
import Timer from '../../UiComponents/Timer';
import Weather from '../../UiComponents/Weather';
import Menu from './Menu';
import Turno from './Modals/Turno';

export default function Body({ open }) {
    const authContext = useContext(AuthContext);
    const [openModal, setOpenModal] = useState(false)
    const { username, header,servicioSelect,setEstadoPtoServicio, puntoServicio, getServicioSeleccionado } = authContext;
    const [serviciosPosibles, setServiciosPosibles] = useState([]);
    const [servicioSeleccionado, setServicioSeleccionado] = useState([]);


    
    useEffect(() => {
        // GET servicios para ese usuario
        axiosClient
            .get("get_serviciosactivos", header)
            .then((response) => {
                setServiciosPosibles(response.data.data);
                setServicioSeleccionado(response.data.data[0].ID);
                handleSelect(document.querySelector('#servicio').value);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        if (open) {
            setOpenModal(true);
        }
    }, [open])

    const handleSelect = (e) => {
        setServicioSeleccionado(e);
        getServicioSeleccionado(e);
        axiosClient
            .post("get_ps_estado",{idPunto:e}, header)
            .then((response) => {
                if(response.data.data[0].ESTADO=="1"){                   
                    setEstadoPtoServicio(1);
                   // console.log("puerta abierta");
                }else{                    
                    setEstadoPtoServicio(0);
                  //  console.log("puerta cerrada");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            {
                openModal ? <Turno setOpenModal={setOpenModal} /> : null
            }
            <div className='mt-5'>
                <div className='flex items-center justify-center mt-10 mb-5'>
                    <p className='text-xl'>
                        ¡Hola {username}! Estás asociado a
                        <select className='mx-2 text-sm p-1' id='servicio' multiple={false}  value={servicioSeleccionado} onChange={e => handleSelect(e.target.value)}>
                            {                                
                                puntoServicio.length ? puntoServicio.map(el =>
                                    <option value={el.IDPUNTOSERVICIO} key={el.IDPUNTOSERVICIO}>{el.NOMBRE}</option>)
                                    : null
                            }
                        </select>
                    </p>
                </div>
                <div className='mx-auto my-5 flex items-center justify-center w-1/3'>
                    <Timer />
                    <Weather />
                </div>
                <div className='w-1/3 my-5 flex mx-auto items-center justify-around'>
                    <Menu open={open} />
                </div>
            </div>
        </>
    )
}