import React, { useContext, useState } from "react";
import BoxDirecciones from "../BoxDirecciones";
import EmptyData from "../UiComponents/EmptyData";
import BuscadorContext from "../../context/buscador/buscadorContext";
import ModalEditarDireccion from "../../components/Socio/Modals/ModalEditarDireccion";

function Direcciones() {
  const buscadorContext = useContext(BuscadorContext);
  const { datosSocio } = buscadorContext;

  const [modalEditarDireccion, setModalEditarDireccion] = useState(false);
  const [direccionID, setDireccionID] = useState({});

  const handleClick = (ID) => {
    setModalEditarDireccion(!modalEditarDireccion);
    setDireccionID(ID);
  };

  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    const { NOMBRE, APELLIDO, TIPO_DOCUMENTO, NRO_DOCUMENTO } =
      datosSocio.socio;
    const { direcciones } = datosSocio;
    return (
      <>
        <div className="p-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-lg font-bold text-gray-700">Direcciones</h1>
              <p className="font-medium font-mono text-gray-500">
                {NOMBRE} {APELLIDO} - {TIPO_DOCUMENTO} {NRO_DOCUMENTO}
              </p>
            </div>
            <div></div>
          </div>
          <div className="mt-10">
            {direcciones.length > 0
              ? direcciones.map((d) => (
                  <BoxDirecciones
                    key={d.ID}
                    data={d}
                    botonEditar={() => handleClick(d)}
                  />
                ))
              : null}
          </div>
        </div>
        {modalEditarDireccion ? (
          <ModalEditarDireccion
            setModalEditarDireccion={setModalEditarDireccion}
            data={direccionID}
          />
        ) : null}
      </>
    );
  } else {
    return <EmptyData texto={"Buscar un socio para comenzar"} />;
  }
}
export default Direcciones;
