import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ToggleButton from "../UiComponents/ToggleButton";
import BoxEstado from "../BoxEstado";
import ModalHistorialEstados from "./Modals/ModalHistorialEstados";
import Formulario from "../UiComponents/Formulario";
import EmptyData from "../UiComponents/EmptyData";
import BuscadorContext from "../../context/buscador/buscadorContext";
import ModalEditarEstado from "./Modals/ModalEditarEstado";
import Auth from "@aws-amplify/auth";
import Storage from "@aws-amplify/storage";


function DatosSocio() {
  const buscadorContext = useContext(BuscadorContext);
  const { datosSocio, datosTablas, editarEstadoSocio, editDatosSocio, loadingBusqueda } =
    buscadorContext;

  const [modalHistorialEstados, setModalHistorialEstados] = useState(false);
  const [modalEditarEstado, setModalEditarEstados] = useState(false);
  const [editar, setEditar] = useState(true);
  const [transeunte, setTranseunte] = useState(
    datosSocio !== null
      ? datosSocio.socio !== undefined
        ? datosSocio.socio.TRANSEUNTE
        : false
      : false
  );
  const fileInput = useRef(null);
  const [fileValue, setFileValue] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const [loadingImage, setLoadingImage] = useState(true);
  let location = useLocation();
  
  


  //AWS S3 Config
  Storage.configure({
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_S3_REGION, //OPTIONAL -  Amazon service region
    },
  });

  Auth.configure({
    region: process.env.REACT_APP_AWS_S3_REGION, // REQUIRED - Amazon Cognito Region
    identityPoolId: process.env.REACT_APP_AWS_S3_POOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    userPoolId: "", //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: "", //OPTIONAL - Amazon Cognito Web Client ID
  });

  const subirCarnetA_S3 = async (archivo, dni) => {
    setLoadingImage(true);
    await Storage.put(
      "FOTOCARNET_" +
      dni +
      "_" +
      new Date().toISOString().slice(0, 10) +
      "T" +
      new Date().toLocaleTimeString() +
      "." +
      archivo.name.split(".")[1],
      archivo,
      {
        contentType: archivo.type,
        acl: "public-read",
        customPrefix: {
          public: "public/",
        },
      }
    )
      .then((result) => {
        datosSocio.imagenes[0].url = process.env.REACT_APP_AWS_URL + result.key;
        setLoadingImage(false);
        
      })
      .catch((err) => {
        console.log(err);
        setLoadingImage(false);
      });
  };



  useEffect(() => {
    if (fileUrl !== null) {
      document.getElementById("edit_socio_submit").click();
      setEditar(!editar);
    }
  }, [fileUrl]);

  useEffect(() => {
    if (
      fileInput.current !== null &&
      fileInput.current.files[0] !== undefined &&
      datosSocio.socio !== undefined
    ) {
      subirCarnetA_S3(
        fileInput.current.files[0],
        datosSocio.socio.NRO_DOCUMENTO
      );
    }
  }, [fileValue]);


  useEffect(() => {
    if (datosSocio !== null) {
      setTranseunte(datosSocio.socio.TRANSEUNTE);
      
    }
  }, [datosSocio]);



  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    const {
      NOMBRE,
      APELLIDO,
      NROSOCIO,
      FECHAINGRESO,
      REFERENCIA,
      OBSERVACIONES,
      TIPO_DOCUMENTO,
      NRO_DOCUMENTO,
      FECHANAC,
      TRANSEUNTE,
    } = datosSocio.socio;

    const { resumen, historico, categorias_posibles } = datosSocio;
    
    const formData = {
      cantColumnas: 4,
      textoSubmit: "GUARDAR",
      style:
        "mt-1 block w-full leading-6 text-sm text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out disabled:bg-gray-400 disabled:text-gray-900 disabled:border-gray-500 duration-300 opacity-100",
      styleButton:
        "bg-green disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-200 text-white uppercase px-8 text-sm py-1 border-green-500 border-2 hover:bg-green-600 hover:border-green-600 transition duration-300 ease-in-out",
      styleButtonSubmitting:
        "bg-gray-500 px-4 py-2 m-2 mt-4 flex justify-center text-gray-200 w-40",
      styleLabel: "uppercase text-gray-800",
      submitButtonID: "edit_socio_submit",
      disabled: editar,
      campos: [
        {
          nombre: "Fecha de ingreso",
          apiName: "FECHAINGRESO",
          type: "date",
          required: true,
          value: FECHAINGRESO,
          span: 4,
          min: "1900-01-01",
          max: new Date().toISOString().slice(0, 10),
        },
        {
          nombre: "OBSERVACIONES",
          apiName: "OBSERVACIONES",
          type: "textarea",
          span: 4,
          rows: 5,
          value: OBSERVACIONES,
        },
        /*         {
          nombre: "REFERENCIA",
          apiName: "REFERENCIA",
          type: "text",
          span: 4,
          value: REFERENCIA,
        }, */
      ],
    };

    
    
    const handleEditar = (valores, setSubmitting) => {
      
      formData.campos.find((c) => c.apiName === "OBSERVACIONES").value = valores.OBSERVACIONES;
      datosSocio.socio.OBSERVACIONES = valores.OBSERVACIONES;
      datosSocio.socio.FECHAINGRESO = valores.FECHAINGRESO;
      let image='';
      if(datosSocio.imagenes.length > 0){
        image=datosSocio.imagenes[0].url;
      }
      
      var valoresMerged = {
        ...valores,
        ...{
          TRANSEUNTE: transeunte,
          urlCarnet: image , 
          
        },
      };
      editDatosSocio(valoresMerged).then(() => {
       
        setEditar(!editar);
      });
      //setEditar(false);
      setSubmitting(false);
    };

    const calcularEdad = (birthDate) =>
      Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

    //if (  datosSocio.imagenes.length > 0) { setLoadingImage(false);}
    return (
      <>
        {
          loadingBusqueda ?
            <div className="flex items-center justify-center m-10">
              <svg
                className="animate-spin ml-3 h-10 w-10 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            :
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h1 className="text-lg font-bold text-gray-700">
                    Datos de Socio
                  </h1>
                  <p className="font-medium text-lg font-mono text-gray-600">
                    {NOMBRE} {APELLIDO} - {TIPO_DOCUMENTO} {NRO_DOCUMENTO}
                  </p>
                </div>
                <div>
                  <ToggleButton
                    texto={"Editar"}
                    value={editar}
                    setEditar={setEditar}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-col lg:flex-row lg:justify-between">
                  { loadingImage ? (
                    <div className="h-60 2xl:h-72 w-full lg:w-60 object-cover mb-2 lg:mb-0 border-socios-primary flex flex-col justify-center items-center text-xl text-center absolute ">
                      {
                        datosSocio.imagenes.length > 0 ? (
                      <svg
                        className="animate-spin -ml-10 h-12 w-12 text-socios-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>) : null
                    }</div>
                  ) : null}
                  <div>
                    {datosSocio.imagenes.length > 0 &&
                      datosSocio.imagenes[0].url !== "" ? (
                      <img
                        src={
                          datosSocio.imagenes[0].url !== ""
                            ? datosSocio.imagenes[0].url
                            : fileUrl
                        }
                        onLoad={() => setLoadingImage(false)}
                        onClick={() => {
                          if (!editar) {
                            fileInput.current.click();
                          }
                        }}
                        className={
                          loadingImage
                            ? "lg:h-60 2xl:h-72 object-cover border-2 mb-2 lg:mb-0 border-socios-primary invisble w-full lg:w-60 opacity-50"
                            : !editar
                              ? "lg:h-60 2xl:h-72 lg:w-60 object-cover border-2 mb-2 lg:mb-0 border-socios-primary cursor-pointer"
                              : "lg:h-60 2xl:h-72 lg:w-60 object-cover border-2 mb-2 lg:mb-0 border-socios-primary cursor-not-allowed"
                        }
                      ></img>
                    
                    ) : (
                      <div className="h-60 2xl:h-72 w-full lg:w-60 object-cover border-2 mb-2 lg:mb-0 border-socios-primary flex flex-col justify-center items-center text-xl text-center">
                        { 
                          <div>
                            {" "}
                            <p>NO POSEE FOTO CARNET</p>
                            <button
                              className={
                                editar
                                  ? "mt-2 border-2 border-socios-primary px-2 py-1   uppercase cursor-not-allowed opacity-50"
                                  : "mt-2 border-2 border-socios-primary px-2 py-1 hover:text-white hover:bg-socios-primaryHover transition duration-300 uppercase"
                              }
                              onClick={() => {
                                fileInput.current.click();
                              }}
                              disabled={editar}
                            >
                              Subir foto
                            </button>
                          </div>
                        }
                      </div>
                    )}
                    <input
                      type="file"
                      id="file"
                      ref={fileInput}                      
                      onChange={(e) => {
                        setFileValue(e.target.value);
                      }}
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                    />
                    
                  </div>
                  <div
                    id="infoSocio"
                    className="lg:pl-2 lg:w-11/12 flex flex-col justify-between gap-2 lg:gap-0"
                  >
                    <div className="border-2 border-socios-primary justify-between p-4 flex flex-row">
                      <div>
                        <p className="text-md font-bold text-gray-800">
                          NÚMERO DE SOCIO
                        </p>
                        <p className="text-lg font-mono font-medium text-gray-600">
                          {NROSOCIO}
                        </p>
                      </div>
                      <div className="ml-3 flex justify-center">
                        <button
                          className="border-2 hover:text-white hover:bg-socios-primary duration-300 transition px-6 border-socios-primary"
                          onClick={() => {
                            window
                              .open("/credencial?dni=" + NRO_DOCUMENTO, "_blank")
                              .focus();
                          }}
                        >
                          VER CREDENCIAL
                        </button>
                      </div>
                    </div>
                    <div className="">
                      <BoxEstado
                        data={[datosSocio.socio.ESTADO,datosSocio.socio.CATEGORIA]}
                        botonHistorial={() => setModalHistorialEstados(true)}
                        botonEditar={() => setModalEditarEstados(true)}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between flex-row items-center">
                  <p className="text-md text-gray-800 mt-0.5">
                    <b>EDAD</b>: {calcularEdad(FECHANAC)} AÑOS
                  </p>
                  <label className="inline-flex items-center mb-4 mt-3">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-gray-600"
                      disabled={editar}
                      checked={transeunte}
                      value={transeunte}
                      onChange={() => {
                        setTranseunte(!transeunte);
                      }}
                      id="checkboxTranseunte"
                    />
                    <span className="ml-2 mt-0.5 text-md text-gray-800">
                      TRANSEUNTE
                    </span>
                  </label>
                </div>
                
                <Formulario data={formData} funcionForm={handleEditar} />
              </div>
            </div>
        }
        {modalHistorialEstados ? (
          <ModalHistorialEstados
            setModalHistorialEstados={setModalHistorialEstados}
            data={historico}
          />
        ) : null}
        {modalEditarEstado ? (
          <ModalEditarEstado
            setModalEditarEstados={setModalEditarEstados}
            data={datosTablas.MOTIVO}
            editarEstadoSocio={editarEstadoSocio}
            categoriasPosibles={categorias_posibles}
          />
        ) : null}
      </>
    );
  } else {
    return <EmptyData texto={"Buscar un socio para comenzar"} />;
  }
}
export default DatosSocio;
