import React, { useContext, useEffect, useState } from "react";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";

function ModalEliminar({ setModalEliminar, data }) {
  const buscadorContext = useContext(BuscadorContext);
  const { setServiciosUpdate, serviciosUpdate } = buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;

  const [options, setOptions] = useState([
    {
      clave: "cargando",
      nombre: "Cargando ...",
    },
  ]);
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("cargando");
  const [fechaBaja, setFechaBaja] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [observaciones, setObservaciones] = useState("");
  const [datosAlert, setDatosAlert] = useState({
    mostrar: false,
    mensaje: "",
  });

  const eliminarServicio = () => {
    if (opcionSeleccionada === "cargando") {
      setDatosAlert({
        mostrar: true,
        mensaje: "Por favor, esperá a que terminen de cargar los motivos",
      });
      return false;
    } else {
      if (fechaBaja === "") {
        setDatosAlert({
          mostrar: true,
          mensaje: "Por favor, completá la fecha de baja",
        });
        return false;
      }
    }
    var payload = {
      idPersoServicio: data,
      fechaFin: fechaBaja,
      motivo: opcionSeleccionada,
      observaciones: observaciones,
    };
    axiosClient
      .post("terminar_servicio_socio", payload, header)
      .then((response) => {
        console.log(response);
        setModalEliminar(false);
        setServiciosUpdate(!serviciosUpdate);
      })
      .catch((err) => {
        console.log(err);
        setDatosAlert({
          mostrar: true,
          mensaje: err.response.data.data
            ? err.response.data.data
            : "Ocurrió un error, intentá más tarde",
        });
      });
  };

  useEffect(() => {
    var payload = {
      idPersona: 12877, // Esto esta hardcodeado porque no cambia el resultado en base a la ID...
    };
    axiosClient
      .post("get_tablas", payload, header)
      .then((response) => {
        setOptions(response.data.data.MOTIVO);
        setOpcionSeleccionada(response.data.data.MOTIVO[0].clave);
      })
      .catch((err) => {
        console.log(err);
        setDatosAlert({
          mostrar: true,
          mensaje: err.response.data.data
            ? err.response.data.data
            : "Ocurrió un error, intentá más tarde",
        });
      });
  }, []);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Baja de Contrato
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Completá la fecha y el motivo, y hacé click en "Aceptar"
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalEliminar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          {datosAlert.mostrar ? (
            <div className="bg-red-500 p-4 mt-4 mb-0 m-4 border-socios-primary border-2 text-md text-white font-bold flex justify-between items-center">
              <p className="flex flex-row items-center gap-2 lg:gap-1">
                <svg className="w-6 h-6" viewBox="0 0 24 24">
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                    fill="currentColor"
                  ></path>
                </svg>{" "}
                {datosAlert.mensaje}
              </p>
              <svg
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  setDatosAlert({ mostrar: false, mensaje: "" });
                }}
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14v14M17 8.4L13.4 12l3.6 3.6l-1.4 1.4l-3.6-3.6L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7L17 8.4z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          ) : null}
          <div className="flex flex-col p-4 gap-4">
            <div className="flex flex-col">
              <label className="font-bold mb-1">Fecha de Baja *</label>
              <input
                type="date"
                defaultValue={new Date().toISOString().slice(0, 10)}
                className="px-2 py-1 border-socios-primary border-2 bg-gray-200 h-9"
                value={fechaBaja}
                onChange={(e) => setFechaBaja(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col">
              <label className="font-bold mb-1">Motivo *</label>

              <select
                className={
                  options[0].clave === "cargando"
                    ? "border-2 bg-gray-200 px-1 py-1 border-socios-primary h-9 cursor-not-allowed opacity-50"
                    : "border-2 bg-gray-200 px-1 py-1 border-socios-primary h-9"
                }
                disabled={options[0].clave === "cargando"}
                value={opcionSeleccionada}
                onChange={(e) => setOpcionSeleccionada(e.target.value)}
                children={options.map((motivo) => (
                  <option value={motivo.clave}>{motivo.nombre}</option>
                ))}
              ></select>
            </div>

            <div className="flex flex-col">
              <label className="font-bold mb-1">Observaciones</label>
              <textarea
                placeholder="Observaciones"
                className="px-2 py-1 border-socios-primary border-2 bg-gray-200"
                rows="4"
                value={observaciones}
                onChange={(e) => setObservaciones(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="bg-gray-200 flex justify-between p-4">
            <button
              className="px-12 bg-green-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-green-600"
              onClick={() => {
                eliminarServicio();
              }}
            >
              Aceptar
            </button>
            <button
              className="px-12 bg-red-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-red-600"
              onClick={() => {
                setModalEliminar(false);
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalEliminar;
