import React, { useEffect, useState } from 'react';
import NuevoServicio from '../PuntoServicio.js/Modals/NuevoServicio';
import Servicios from '../PuntoServicio.js/Modals/Servicios';
import GuardarAsistencia from '../PuntoServicio.js/Modals/GuardarAsistencia';

export default function Wizard({ data, page, handlePage, setBusqueda,  setSearch}) {
    const [checkbox,setCheckbox]=useState();
    const [selecAsistencia,setSeleccionado]=useState(false);
    useEffect(() => {
        document.querySelector("#botonSteWizard"). hidden=true;
         
      }, []); 
    
    return (
        <div className="border-b-2 border-gray-500 p-4 flex flex-col items-center">
            {
                page===1 ? <Servicios setSeleccionado={setSeleccionado} setSearch={setSearch} setBusqueda={setBusqueda} data={data} setCheckbox={setCheckbox} checkbox={checkbox}  page={page} handlePage={handlePage} />
                : ((selecAsistencia==true)&&(data.data.servicios)) ? <GuardarAsistencia setSeleccionado={setSeleccionado} setBusqueda={setBusqueda} setSearch={setSearch} checkbox={checkbox} data={data} setCheckbox={setCheckbox}/> 
                : ((page ===2)&&(data.data.servicioNuevo!=[])) ? <NuevoServicio setSeleccionado={setSeleccionado} setBusqueda={setBusqueda} setSearch={setSearch} checkbox={checkbox} data={data} setCheckbox={setCheckbox} handlePage={handlePage} />
                : console.log("debera seleccionar algo")
     
                
                /* page === 1 ? (
                    <Servicios setSeleccionado={setSeleccionado} setSearch={setSearch} setBusqueda={setBusqueda} data={data} setCheckbox={setCheckbox} checkbox={checkbox}  page={page} handlePage={handlePage} />
                ) : selecAsistencia==true ? (<GuardarAsistencia setSeleccionado={setSeleccionado} setBusqueda={setBusqueda} setSearch={setSearch} checkbox={checkbox} data={data} setCheckbox={setCheckbox}/> 
                ) :  page === 2 ? (
                    <NuevoServicio setSeleccionado={setSeleccionado} setBusqueda={setBusqueda} setSearch={setSearch} checkbox={checkbox} data={data} setCheckbox={setCheckbox} />
                ) : null */
            }
            <div className='w-full flex items-center justify-between'>
                <button
                    onClick={() => {
                        if (page === 1) {
                            setBusqueda(false)
                            setSeleccionado(false)
                            setCheckbox([])
                            
                        } else {
                            handlePage(page - 1)
                            setSeleccionado(false)
                            
                        }

                    }}
                >
                    {
                        page === 1 ? 'Inicio' : '← Anterior'
                    }
                </button>
                <button
                    type='button'
                    id="botonSteWizard"    
                                    
                    onClick={() => {
                        if(page === 2) {
                            setBusqueda(false)
                            setSeleccionado(false)
                            setSearch(null)
                        } else {
                            if((data.data.servicios.length!=0)||(data.data.servicioNuevo.length!=0)){
                                handlePage(page + 1);
                                
                            }
                        }
                    }}
                >
                    {
                        page === 2 ? "Ir al Inicio" : 'Siguiente →'
                    }
                    
                </button>
            </div>
        </div>
    )
}