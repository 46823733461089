import React, { useContext, useState } from "react";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";

function ModalNuevo({ setModalNuevo, serviciosPosibles }) {
  const hoy = new Date();
  const buscadorContext = useContext(BuscadorContext);
  const { setServiciosUpdate, setModalNuevaPersona, serviciosUpdate } =
    buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [dniPersona, setDNIPersona] = useState("");
  const [datosPersona, setDatosPersona] = useState({});
  const [buscandoPersona, setBuscandoPersona] = useState(false);
  const [fechaInicio, setFechaInicio] = useState(
    hoy.toISOString().slice(0, 10)
    );
    const [fechaFin,setFechaFin] = useState(fechaSugerida());
  const [bonificacion, setBonificacion] = useState("");
  const [importe, setImporte] = useState("");
  const [servicioSeleccionado, setServicioSeleccionado] = useState(
    serviciosPosibles[0].ID
  );
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    text: "No hay personas registradas con ese DNI",
  });


  const buscarPersona = () => {
    var newAlertInfo = { ...alertInfo };
    newAlertInfo.show = false;
    newAlertInfo.text = "";
    setAlertInfo(newAlertInfo);
    var payload = {
      tipo: "F",
      dni: dniPersona,
    };
    if (dniPersona !== "") {
      setBuscandoPersona(true);
      axiosClient
        .post("get_personas", payload, header)
        .then((response) => {
          var datos = response.data.data[0];
          setDatosPersona({
            id: datos.ID_PERSONA,
            nombre: datos.NOMBRE + " " + datos.APELLIDO,
            dni: datos.NRO_DOCUMENTO,
            nrosocio: datos.NROSOCIO,
            estado:datos.ESTADO,
          });
          setBuscandoPersona(false);
          buscarImporte(servicioSeleccionado);
          
          
        })
        .catch((err) => {
          console.log(err);
          var newAlertInfo = { ...alertInfo };
          newAlertInfo.show = true;
          newAlertInfo.text = "No hay personas registradas con ese DNI";
          setAlertInfo(newAlertInfo);
          setBuscandoPersona(false);
        });
    } else {
      var newAlertInfo = { ...alertInfo };
      newAlertInfo.show = true;
      newAlertInfo.text = "Por favor, ingresá un DNI";
      setAlertInfo(newAlertInfo);
    }
  };

  function buscarImporte(serv){
    
    serviciosPosibles.forEach((servicio)=>{
      if(servicio.ID==serv){
        
        if(datosPersona.estado==="A"){
          setImporte(servicio.importeSocio);
        }else{
          setImporte(servicio.importeNoSocio);
        }
      }
    });
  }

  const darDeAltaServicio = async () => {
    var payload = {
      idPersona: datosPersona.id,
      idServicio: parseInt (servicioSeleccionado),
      fechaInicio: fechaInicio,
      fechaFin: fechaFin,
      bonificacion:
        serviciosPosibles.filter(
          (serv) => serv.ID === parseInt(servicioSeleccionado)
        )[0].ACEPTABONIFICACION === 1
          ? bonificacion
          : "",
    };
    await axiosClient
      .post("add_servicio_persona", payload, header)
      .then((response) => {
        setAlert("Se dió de alta el servicio correctamente", "green-300");
        setServiciosUpdate(!serviciosUpdate);
        setModalNuevo(false);
      })
      .catch((err) => {
        console.log(err);
        setAlert("Hubo un error dando de alta el servicio", "red-300");
        setModalNuevo(false);
      });
  };

  function fechaSugerida(){
    let fecha=new Date();
    let fechaSugerida;
    if((fecha.getMonth()+1)<12){
      fechaSugerida=fecha.getFullYear()+"-"+(fecha.getMonth()+2)+"-"+fecha.getDate();
    }else{
      fechaSugerida=(fecha.getFullYear()+1)+"-"+(fecha.getMonth()-10)+"-"+fecha.getDate();
    }
    fecha = new Date(fechaSugerida).toISOString().split('T')[0];
    return fecha;
  }


  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-0">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Nuevo Servicio
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Dar de alta un nuevo servicio para una persona o socio
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalNuevo(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div>
            {alertInfo.show ? (
              <div className="border-2 p-4 m-4 flex flex-row justify-between border-socios-primary bg-red-500 text-white">
                <div className="flex flex-row gap-2 items-center font-medium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{alertInfo.text}</p>
                </div>
                {!alertInfo.text.includes("Por favor") ? (
                  <button
                    className="border-2 border-white px-4 py-1 bg-red-700 transition duration-300 hover:bg-red-800"
                    onClick={() => {
                      setModalNuevaPersona(true);
                      setModalNuevo(false);
                    }}
                  >
                    REGISTRAR DNI
                  </button>
                ) : null}
              </div>
            ) : null}

            <h1 className="text-lg text-left p-4 mt-2 font-bold">
              Ingresá el DNI de la Persona
            </h1>
            <div className="m-4 mt-0 gap-2 flex flex-col lg:flex-row justify-between">
              <input
                className="lg:w-3/4 px-2 py-2 bg-gray-200 border-socios-primary border-2"
                placeholder="DNI"
                value={dniPersona}
                onChange={(e) => {
                  setDNIPersona(e.target.value);
                }}
                type="number"
              ></input>
              <button
                className="lg:w-1/4 border-2 border-socios-primary transition duration-300 hover:bg-socios-primary hover:text-white"
                onClick={() => {
                  buscarPersona();
                }}
              >
                Buscar
              </button>
            </div>
          </div>
          <div className="p-4">
            <h1 className="text-lg text-left font-bold">Datos de la Persona</h1>
            <div className="flex flex-col lg:grid lg:grid-cols-3 mt-2 border-2 border-socios-primary p-2">
              <div className="xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">NOMBRE</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {buscandoPersona ? (
                    <div className="py-2.5 rounded px-4 w-1/2 bg-gray-400 animate-pulse"></div>
                  ) : datosPersona !== null ? (
                    datosPersona.nombre
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">DNI</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {buscandoPersona ? (
                    <div className="py-2.5 rounded px-4 w-1/2 bg-gray-400 animate-pulse"></div>
                  ) : datosPersona !== null ? (
                    datosPersona.dni
                  ) : (
                    "-"
                  )}
                </p>
              </div>
              <div className="xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">NRO. SOCIO</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {buscandoPersona ? (
                    <div className="py-2.5 rounded px-4 w-1/2 bg-gray-400 animate-pulse"></div>
                  ) : datosPersona !== null ? (
                    datosPersona.estado == "A" ? datosPersona.nrosocio : "S/N" 
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="p-4">
            <h1 className="text-lg text-left font-bold">
              Completá los datos del Servicio
            </h1>
            <div className="flex flex-col gap-3 justify-between mt-2">
              <div className="flex flex-col">
                <label
                  className="text-sm text-gray-800 font-bold"
                  htmlFor="select_servicio"
                >
                  SERVICIO
                </label>
                <select
                  id="select_servicio"
                  className={
                    datosPersona === null
                      ? "px-2 bg-gray-200 border-socios-primary border-2 h-9 cursor-not-allowed opacity-40"
                      : "px-2 bg-gray-200 border-socios-primary border-2 h-9"
                  }
                  value={servicioSeleccionado}
                  onChange={(e) => {                    
                    setServicioSeleccionado(e.target.value);
                    buscarImporte(e.target.value);
                  }}
                  children={serviciosPosibles.map((servicio) => (
                    <option value={servicio.ID}>{servicio.NOMBRE}</option>
                  ))}
                  disabled={datosPersona === null}
                ></select>
              </div>
              <div className="flex flex-col">
                <label
                  className="text-sm text-gray-800 font-bold"
                  htmlFor="fecha_incio"
                >
                  FECHA DE INICIO
                </label>
                <input
                  className={
                    datosPersona === null
                      ? "px-2 bg-gray-200 border-socios-primary border-2 h-9 cursor-not-allowed opacity-40"
                      : "px-2 bg-gray-200 border-socios-primary border-2 h-9"
                  }
                  type="date"
                  id="fecha_inicio"
                  disabled={datosPersona === null}
                  value={fechaInicio}
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label
                  className="text-sm text-gray-800 font-bold"
                  htmlFor="fecha_fin"
                >
                  FECHA DE FIN
                </label>
                <input
                  className={
                    datosPersona === null
                      ? "px-2 bg-gray-200 border-socios-primary border-2 h-9 cursor-not-allowed opacity-40"
                      : "px-2 bg-gray-200 border-socios-primary border-2 h-9"
                  }
                  type="date"
                  id="fecha_fin"
                  disabled={datosPersona === null}
                  onChange={(e) => {setFechaFin(e.target.value)}}
                  defaultValue={fechaFin} 
                  min={fechaFin}
                ></input>
              </div>
              <div className="flex flex-col">
                <label
                  className="text-sm text-gray-800 font-bold"
                  htmlFor="bonificacion"
                >
                  BONIFICACIÓN
                </label>
                <input
                  className={
                    datosPersona === null ||
                    serviciosPosibles.filter(
                      (serv) => serv.ID === parseInt(servicioSeleccionado)
                    )[0].ACEPTABONIFICACION === 0
                      ? "px-2 bg-gray-200 border-socios-primary border-2 h-9 cursor-not-allowed opacity-40"
                      : "px-2 bg-gray-200 border-socios-primary border-2 h-9"
                  }
                  type="number"
                  id="bonificacion"
                  placeholder="$ 0.00"
                  disabled={
                    datosPersona === null ||
                    serviciosPosibles.filter(
                      (serv) => serv.ID === parseInt(servicioSeleccionado)
                    )[0].ACEPTABONIFICACION === 0
                  }
                  value={bonificacion}
                  onChange={(e) => {
                    setBonificacion(e.target.value);
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label
                  className="text-sm text-gray-800 font-bold"
                  htmlFor="bonificacion"
                >
                  IMPORTE
                </label>
                <input
                  className="px-2 bg-gray-200 border-socios-primary border-2 h-9 cursor-not-allowed opacity-40"                     
                  type="number"
                  id="importe"
                  placeholder="$ 0.00"
                  disabled={true}
                  value={importe}               
                  ></input>
              </div> 
            </div>
          </div>
          <div className="p-4">
            <button
              className={
                datosPersona === null
                  ? "w-full px-2 py-2 text-white border-2 border-socios-primary bg-gray-400 cursor-not-allowed"
                  : "w-full px-2 py-2 text-white border-2 border-socios-primary transition duration-300 hover:bg-green-600 bg-green-500"
              }
              disabled={datosPersona === null}
              onClick={() => {
                darDeAltaServicio();
              }}
            >
              DAR DE ALTA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalNuevo;
