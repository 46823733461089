import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTable, usePagination } from "react-table";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";

function ModalDetalle() {
  const buscadorContext = useContext(BuscadorContext);
  const { setModalDetalleLiqui, dataDetalle, formatImporte, formatMiles } =
    buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const [cargandoDatos, setCargandoDatos] = useState(true);
  const [datos, setDatos] = useState([]);
  const [cantPaginas, setCantPaginas] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nombreApellido, setNombreApellido] = useState("");

  const data = useMemo(() => datos, [datos]);

  const columns = useMemo(
    () => [
      {
        Header: () => <div className="text-center">NOMBRE</div>,
        accessor: "NOMBRE",
      },
      {
        Header: () => <div className="text-center">APELLIDO</div>,
        accessor: "APELLIDO",
      },
      {
        Header: () => <div className="text-center">NRO. DOCUMENTO</div>,
        accessor: "NRO_DOCUMENTO",
        Cell: ({ value }) => (
          <div className="text-right">{formatMiles(value)}</div>
        ),
      },
      {
        Header: () => <div className="text-center">NRO. SOCIO</div>,
        accessor: "NROSOCIO",
        Cell: ({ value }) => (
          <div className="text-right">{formatMiles(value)}</div>
        ),
      },
      {
        Header: () => <div className="text-center">PRECIO</div>,
        accessor: "PRECIO",
        Cell: ({ value }) => (
          <div className="text-right">{formatImporte(value)}</div>
        ),
      },
      {
        Header: () => <div className="text-center">BONIFICACIÓN</div>,
        accessor: "BONIFICACION",
        Cell: ({ value }) => (
          <div className="text-right">{formatImporte(value)}</div>
        ),
      },
    ],
    []
  );

  //const tableInstance = useTable({ columns, data });

  useEffect(() => {
    var payload = {
      idLiquidacion: dataDetalle.idLiquidacion
        ? dataDetalle.idLiquidacion
        : dataDetalle.IDLIQUIDACION,
      codigo: dataDetalle.codigo
        ? dataDetalle.codigo
        : dataDetalle.CODIGO
        ? dataDetalle.CODIGO
        : "",
      categoria: dataDetalle.categoria
        ? dataDetalle.categoria
        : dataDetalle.CATEGORIA
        ? dataDetalle.CATEGORIA
        : "",
      subcategoria: dataDetalle.SUBCATEGORIA ? dataDetalle.SUBCATEGORIA : "",
      pagina: 1,
      cantidad: 10,
    };
    axiosClient
      .post("get_detalle_liquidacion_por_codigoycategorias", payload, header)
      .then((response) => {
        console.log(response.data.data);
        console.log(payload);
        setCantPaginas(response.data.data.cantidad_de_paginas);
        setDatos(response.data.data.resultado);
        setCargandoDatos(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: cantPaginas,
    },
    usePagination
  );

  const fetchData = useCallback((data) => {
    setLoading(true);
    var payload = {
      idLiquidacion: dataDetalle.idLiquidacion
        ? dataDetalle.idLiquidacion
        : dataDetalle.IDLIQUIDACION,
      codigo: dataDetalle.codigo
        ? dataDetalle.codigo
        : dataDetalle.CODIGO
        ? dataDetalle.CODIGO
        : "",
      categoria: dataDetalle.categoria
        ? dataDetalle.categoria
        : dataDetalle.CATEGORIA
        ? dataDetalle.CATEGORIA
        : "",
      subcategoria: dataDetalle.SUBCATEGORIA ? dataDetalle.SUBCATEGORIA : "",
      pagina: data.pageIndex + 1,
      cantidad: data.pageSize,
    };
    axiosClient
      .post("get_detalle_liquidacion_por_codigoycategorias", payload, header)
      .then((response) => {
        console.log(response.data.data);
        setCantPaginas(response.data.data.cantidad_de_paginas);
        setDatos(response.data.data.resultado);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  });

  useEffect(() => {
    if (!cargandoDatos) {
      fetchData({ pageIndex, pageSize });
    }
  }, [pageIndex, pageSize]);

  const buscarPorNYA = () => {
    setLoading(true);
    var payload = {
      idLiquidacion: dataDetalle.idLiquidacion
        ? dataDetalle.idLiquidacion
        : dataDetalle.IDLIQUIDACION,
      codigo: dataDetalle.codigo
        ? dataDetalle.codigo
        : dataDetalle.CODIGO
        ? dataDetalle.CODIGO
        : "",
      categoria: dataDetalle.categoria
        ? dataDetalle.categoria
        : dataDetalle.CATEGORIA
        ? dataDetalle.CATEGORIA
        : "",
      subcategoria: dataDetalle.SUBCATEGORIA ? dataDetalle.SUBCATEGORIA : "",
      pagina: pageIndex + 1,
      cantidad: pageSize,
      nyap: nombreApellido,
    };
    axiosClient
      .post("get_detalle_liquidacion_por_codigoycategorias", payload, header)
      .then((response) => {
        console.log(response.data.data);
        setCantPaginas(response.data.data.cantidad_de_paginas);
        setDatos(response.data.data.resultado);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-5/6 2xl:w-4/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Detalle de Liquidación
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                {dataDetalle.descripcion
                  ? dataDetalle.descripcion
                  : dataDetalle.DESCRIPCION}
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalDetalleLiqui(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          {cargandoDatos && datos.length === 0 ? (
            <p className="text-center text-4xl font-bold py-20">Cargando ...</p>
          ) : (
            <div>
              <div className="m-4 mb-0 lg:w-1/2">
                <div className="flex flex-col">
                  <label
                    htmlFor="buscador"
                    className="mb-2 text-lg text-left font-bold"
                  >
                    Buscar por Nombre y/o Apellido
                  </label>
                  <div className="flex flex-col lg:flex-row gap-2">
                    <input
                      className={
                        cargandoDatos || loading
                          ? "w-full lg:w-3/4 2xl:w-2/4 px-2 py-2 bg-gray-200 border-socios-primary border-2 opacity-50 cursor-not-allowed"
                          : "w-full lg:w-3/4 2xl:w-2/4 px-2 py-2 bg-gray-200 border-socios-primary border-2"
                      }
                      id="buscador"
                      placeholder="Nombre y/o Apellido"
                      value={nombreApellido}
                      onChange={(e) => {
                        setNombreApellido(e.target.value);
                      }}
                      disabled={cargandoDatos || loading}
                    ></input>
                    <button
                      className={
                        cargandoDatos || loading
                          ? "w-full lg:w-1/4 px-2 py-2 bg-gray-200 border-socios-primary border-2 opacity-50 cursor-not-allowed"
                          : "w-full lg:w-1/4 px-2 py-2 bg-gray-200 border-socios-primary border-2 transition duration-300 hover:text-white hover:bg-socios-primaryHover"
                      }
                      onClick={() => buscarPorNYA()}
                      disabled={cargandoDatos || loading}
                    >
                      BUSCAR
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <table className="w-full m-4" {...getTableProps()}>
                  <thead className="border-2 border-socios-primary bg-socios-primary text-white">
                    {
                      // Loop over the header rows
                      headerGroups.map((headerGroup) => (
                        // Apply the header row props
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {
                            // Loop over the headers in each row
                            headerGroup.headers.map((column) => (
                              // Apply the header cell props
                              <th {...column.getHeaderProps()}>
                                {
                                  // Render the header
                                  column.render("Header")
                                }
                              </th>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </thead>
                  {/* Apply the table body props */}
                  {!loading && !cargandoDatos ? (
                    <tbody
                      className="border border-socios-primary"
                      {...getTableBodyProps()}
                    >
                      {
                        // Loop over the table rows
                        page.map((row) => {
                          // Prepare the row for display
                          prepareRow(row);
                          return (
                            // Apply the row props
                            <tr
                              className="border-socios-primary border-2"
                              {...row.getRowProps()}
                            >
                              {
                                // Loop over the rows cells
                                row.cells.map((cell) => {
                                  // Apply the cell props
                                  return (
                                    <td
                                      className="border-r-2 border-socios-primary"
                                      {...cell.getCellProps()}
                                    >
                                      {
                                        // Render the cell contents
                                        cell.render("Cell")
                                      }
                                    </td>
                                  );
                                })
                              }
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  ) : (
                    <tbody className="border-2 h-72 text-center text-lg border-socios-primary w-full">
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-center text-2xl flex flex-row items-center gap-2 justify-center mt-32 ml-28">
                          <svg
                            className="animate-spin mt-2 h-7 w-7 text-socios-primary"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          <p className="mt-2">Cargando Datos...</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              <div className="pagination m-4 mt-0 flex flex-row gap-4 items-center">
                <button
                  className={
                    loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                  }
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage || loading}
                >
                  {"<<"}
                </button>{" "}
                <button
                  className={
                    loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                  }
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage || loading}
                >
                  {"<"}
                </button>{" "}
                <button
                  className={
                    loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                  }
                  onClick={() => nextPage()}
                  disabled={!canNextPage || loading}
                >
                  {">"}
                </button>{" "}
                <button
                  className={
                    loading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                  }
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage || loading}
                >
                  {">>"}
                </button>{" "}
                <span>
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>{" "}
                </span>
                <select
                  value={pageSize}
                  className={
                    loading
                      ? "bg-gray-200 border-socios-primary border-2 px-1 h-7 cursor-not-allowed opacity-50"
                      : "bg-gray-200 border-socios-primary border-2 px-1 h-7"
                  }
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  disabled={loading}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ModalDetalle;
