import React from 'react';
import QRCode from "react-qr-code";
import LogoFrontini from '../../assets/img/frontini-logo.svg';
import LogoCaer from '../../assets/img/logo_caer.jpg';

export default function Imprimir({ data, loadingImagen, setLoadingImagen }) {
    return (
        <div className='bg-white rounded m-4'>
            <div className='flex justify-evenly md:hidden md:mb-4'>
                <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-8"></div>
                <div className="md:w-1/4 sm:w-1/12 md:hidden md:block bg-black h-8"></div>
                <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-8"></div>
            </div>
            <div className="flex justify-around flex-col-reverse align-center md:flex-row m-2 md:m-0">
                <div className="rounded md:w-2/3">
                    {/* <div className='flex hidden md:block justify-evenly'>
                        <div className="md:w-1/4 md:block bg-black h-8"></div>
                        <div className="md:w-1/4 md:hidden md:block bg-black h-8"></div>
                        <div className="md:w-1/4 md:block bg-black h-8"></div>
                    </div> */}
                    <h1 className="text-left font-bold m-4 text-2xl border-b-2 border-black hidden md:block">
                        CREDENCIAL DE SOCIO
                    </h1>
                    <div className="flex m-5 md:mt-0">
                        <div className="my-4 hidden md:block mr-4">
                            <div
                                className={
                                    loadingImagen
                                        ? "h-32 w-32 border-socios-primary border rounded ml-4 flex justify-center items-center"
                                        : "h-32 w-32 border-socios-primary border rounded ml-4 hidden"
                                }
                            >
                                <svg
                                    className="animate-spin h-12 w-12 text-socios-primary"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                            <img
                                src={data.URL}
                                className={
                                    loadingImagen
                                        ? "hidden"
                                        : "h-40"
                                }
                                onLoad={() => {
                                    setLoadingImagen(false);
                                }}
                            ></img>
                        </div>
                        <div className="md:m-2 flex flex-col justify-around mx-auto my-6">
                            <h2 className="ml-4 md:text-lg text-2xl flex flex-row gap-1">
                                <p className="font-bold hidden md:block">NOMBRE SOCIO: </p>
                                {data.NOMBRE + " " + data.APELLIDO}
                            </h2>
                            <h2 className="ml-4 md:text-lg text-2xl flex flex-row gap-1">
                                <p className="font-bold">N° DE SOCIO: </p>
                                {data.NROSOCIO}
                            </h2>
                            <h2 className="ml-4 text-lg md:flex-row gap-1 hidden md:flex">
                                <p className="font-bold">N° DE DOCUMENTO: </p>
                                {data.NRO_DOCUMENTO}
                            </h2>
                        </div>
                    </div>
                    {/* <div className='flex justify-evenly md:hidden mb-4'> */}
                    {/* <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-16"></div>
                        <div className="md:w-1/4 sm:w-1/12 md:hidden md:block bg-black h-16"></div>
                        <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-16"></div> */}
                    {/* </div> */}
                    <div className="m-12 flex justify-center md:hidden">
                        <img src={LogoFrontini} className='w-1/4 mr-12' alt="logo-frontini" />
                        <img src={LogoCaer} alt="logo-caer" className='w-16 ml-12' />
                    </div>
                    {/* <div className='flex hidden md:block justify-evenly'>
                        <div className="md:w-1/4 md:block bg-black h-8"></div>
                        <div className="md:w-1/4 md:hidden md:block bg-black h-8"></div>
                        <div className="md:w-1/4 md:block bg-black h-8"></div>
                    </div> */}
                    {/* <div className='flex justify-evenly md:hidden mt-4'> */}
                    {/* <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-8"></div>
                        <div className="md:w-1/4 sm:w-1/12 md:hidden md:block bg-black h-8"></div>
                        <div className="md:w-1/4 sm:w-1/12 md:block bg-black h-8"></div> */}
                    {/* </div> */}
                </div>
                <div className="md:w-1/4 md:bg-black flex justify-center items-center">
                    <QRCode
                        size={window.innerWidth > 640 ? 126 : 300}
                        value={
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            "/credencial?dni=" +
                            data.NRO_DOCUMENTO
                        }
                    />
                </div>
            </div>
        </div>
    )
}