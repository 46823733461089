import React, { useEffect, useState } from 'react';

export default function Servicios({ setSearch,setSeleccionado, setBusqueda, data}) {
    const [unico, setUnico]=useState(true);
    const deudaAmarillo = {
        backgroundColor:  '#E0DA4F',        
      }
      const sinDeuda = {
        backgroundColor:  '#45D66D',        
      }  

      useEffect(() => {
        setSeleccionado(false)
        
        data.data.servicios=[];
        data.data.servicioNuevo=[];
        console.log("LLEGA");
        if((data.data.servAdquiridos.length==1)&&(unico)){
            data.data.servicios=[];
            setSeleccionado(true);
            data.data.servicios.push((data.data.servAdquiridos[0].IDSERVICIO).toString());
        }  
      }, []);   
    if( data.data.servicios==null  ){
        Object.assign(data.data,{"servicios":[]});
    }else{
        if((data.data.servAdquiridos.length==1)&&(unico)){
            data.data.servicios=[];
            setSeleccionado(true);
            data.data.servicios.push((data.data.servAdquiridos[0].IDSERVICIO).toString());
        }    
    }
    if( data.data.servicioNuevo==null  ){
        Object.assign(data.data,{"servicioNuevo":[]});
    }
    const handleSelectNuevo=(e)=>{
        
        if(e.target.checked){
            data.data.servicioNuevo.push(e.target.name);   
            document.querySelector("#botonSteWizard"). hidden=false;
           
        }else{
            removeItemFromArr( data.data.servicioNuevo, e.target.name );
            if(data.data.servicioNuevo.length==0){
                document.querySelector("#botonSteWizard"). hidden=true;
            }
            
 

        }
    }

    const handleSelect =(e)=>{

       if((e.target.checked)&&(!data.data.servicios.includes((e.target.name)))){
         data.data.servicios.push(e.target.name); 
         setSeleccionado(true);
         setUnico(true);
         document.querySelector("#botonSteWizard"). hidden=false;

         if(data.data.servAdquiridos.length==data.data.servicios.length){
            document.querySelector("#todos").checked=true;
        }
       }else{
        setUnico(false);
        removeItemFromArr( data.data.servicios, e.target.name );
        document.querySelector("#todos").checked=false;
        setSeleccionado(false);
        if(data.data.servicios.length==0){
            document.querySelector("#botonSteWizard"). hidden=true;
        }

       }
       
    }
 
    function removeItemFromArr ( arr, item ) {
        var i = arr.indexOf( item );     
        if ( i !== -1 ) {
            arr.splice( i, 1 );
        }
    }

    function asisteAtodos(e){
        if(e.checked){
            document.querySelectorAll("#servParaAsistencia").forEach(el=>{
                el.checked=true;
                data.data.servicios.push(el.name);                
            });
            document.querySelector("#botonSteWizard"). hidden=false;

        }else{
            document.querySelectorAll("#servParaAsistencia").forEach(ele=>{
                ele.checked=false;
                removeItemFromArr( data.data.servicios, ele.name );
            }); 
            document.querySelector("#botonSteWizard"). hidden=true;
           
        }

        if(data.data.servicios.length>0){
            setSeleccionado(true);
        }else{
            setSeleccionado(false);
        }
    }
    

    return (
        <div >
            <div className='flex w-full justify-end'>
                <button
                    onClick={() => {
                        setSeleccionado(false)
                        setBusqueda(false)
                        setSearch(null)
                        //setCheckbox([])
                    }}
                    className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                </button>
            </div>
            <div className='w-full mx-6 mb-4 '>
                <h2>Nombre: {data.data.persona[0].NOMBRE} {data.data.persona[0].APELLIDO}</h2>
                <h2>N° de Socio: {data.data.persona[0].NROSOCIO}</h2>
                <h2>Estado: {data.data.persona[0].ESTADO ? "Activo" : "No activo"}</h2>
            </div>
            <div className='flex items-start justify-around w-1/8 mt-4 mb-10'>
                <div className='w-1/2'>
                    <div className='flex items-center justify-between bg-black text-white rounded-md px-2 py-1'>
                        <p className='text-sm'>Servicios Contratados</p>
                        <div className='w-1/4'>
                            <img className='w-1/2' src="https://w7.pngwing.com/pngs/276/576/png-transparent-arrow-computer-icons-logo-white-down-arrow-miscellaneous-angle-rectangle-thumbnail.png" alt="flecha hacia abajo" />
                        </div>
                    </div>
                    <div className='items-center justify-between bg-white text-black rounded-md px-3 py-2'>
                        {
                            data.data.servAdquiridos ? data.data.servAdquiridos.map(serv =>
                              {
                                  if(serv.IMPORTE>0){                                      
                                    return (
                                        <div key={serv.IDSERVICIO} className="flex justify-between py-2 rounded-md " style={deudaAmarillo}>
                                            <p className='text-sm'>{serv.NOMBRE}</p>
                                            <input
                                                id="servParaAsistencia"
                                                name={serv.IDSERVICIO} 
                                                type="checkbox"
                                                onChange={(e) => {
                                                    handleSelect(e)
                                                }}
                                                className='w-1/4'>
                                            </input>                                            
                                        </div>                                    
                                    )
                                    
                                  }else{
                                    if(data.data.servAdquiridos.length==1){
                                        return (
                                            <div key={serv.IDSERVICIO} className="flex justify-between py-2 rounded-md" style={sinDeuda}>
                                                <p className='text-sm'>{serv.NOMBRE}</p>
                                                <input
                                                    id="servParaAsistencia"
                                                    name={serv.IDSERVICIO} 
                                                    type="checkbox"
                                                    checked={unico}
                                                    onChange={(e) => {
                                                        handleSelect(e)

                                                    }}
                                                    className='w-1/4'>
                                                </input>                                            
                                            </div>                                    
                                        )
                                    }else{
                                        return (
                                            <div key={serv.IDSERVICIO} className="flex justify-between py-2 rounded-md" style={sinDeuda}>
                                                <p className='text-sm'>{serv.NOMBRE}</p>
                                                <input
                                                    id="servParaAsistencia"
                                                    name={serv.IDSERVICIO} 
                                                    type="checkbox"  
                                                    onChange={(e) => {
                                                        handleSelect(e)

                                                    }}
                                                    className='w-1/4'>
                                                </input>
                                            </div>                                    
                                        )
                                    }                                    
                                  }
                                
                                }):<p>El socio no ha utilizado ningún servicio en este punto</p>
                        
                        }                          

                    </div>
                    <div className='flex'>
                        <label className='w-3/4' >Marcar asistencia en todos</label>
                        <input type="checkbox" name="todos" id="todos"
                        onChange={(e)=>{asisteAtodos(e.target)}}></input>
                    </div>    
                </div>
                
                <div className='w-2/4 pl-5'>
                    <div className='flex row items-center justify-between bg-black text-white rounded-md px-2 py-1'>
                        <p className='text-sm'>Servicios a Contratar</p>
                        <div className='w-1/4'>
                            <img className='w-1/2' src="https://w7.pngwing.com/pngs/276/576/png-transparent-arrow-computer-icons-logo-white-down-arrow-miscellaneous-angle-rectangle-thumbnail.png" alt="flecha hacia abajo" />
                        </div>
                    </div>
                    <div className='items-center justify-between bg-white text-black rounded-md px-3 py-2'>
                    {
                            data.data.servPosibles!=[] ? data.data.servPosibles.map(servPo =>
                              {
                                return (
                                    <div key={servPo.id} className="flex justify-between py-2 ">
                                        <p className='text-sm'>{servPo.DESCRIPCION}</p>
                                        <input
                                            name={servPo.id} 
                                            type="checkbox"
                                            onChange={(e) => {                                                
                                                handleSelectNuevo(e)

                                            }}
                                            className='w-1/4'
                                        />
                                    </div>                                
                                )
                                }):<p>No puede adquirir ningún servicio</p>
                        }          
                    </div>                    
                </div>
                 <div className='d-flex w-1/2 justify-content-center pl-5'>
                    <textarea className='w-3/4 h-3/4 rounded-md' rows={5} style={{ resize: 'none' }} id="observacionAsistencia" onFocus={(e)=>{e.value=''}  }>Observación </textarea>
                </div> 
            </div>
        </div>
    )
}