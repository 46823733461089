import { useEffect, useState, useContext } from "react";
import AuthContext from "../../../context/auth/authContext";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AlertContext from "../../../context/alerts/alertContext";
import Nav from "../../Nav";
import BoxServicio from "./BoxServicio";
import ModalEliminar from "./ModalEliminar";
import ModalNuevo from "./ModalNuevo";
import ModalNuevaPersona from "./ModalNuevaPersona";
import ModalBajaTotal from "./ModalBajaTotal";
import axiosClient from "../../../config/axios";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

function Servicios() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const buscadorContext = useContext(BuscadorContext);
  const {
    serviciosUpdate,
    modalNuevaPersona,
    modalBajaTotal,
    setModalBajaTotal,
  } = buscadorContext;
  const [servicios, setServicios] = useState([]);
  const [serviciosPosibles, setServiciosPosibles] = useState([]);
  const [cargandoServicios, setCargandoServicios] = useState(true);
  const [servicioSeleccionado, setServicioSeleccionado] = useState("1");
  const [textoSeleccionado, setTextoSeleccionado] = useState(null);
  const [textoSeleccionadoMuestra, setTextoSeleccionadoMuestra] =
    useState(null);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalNuevo, setModalNuevo] = useState(false);
  const [idSeleccionado, setIdSeleccionado] = useState(null);
  const [exportando, setExportando] = useState(false);
  useEffect(() => {
    axiosClient
      .get("get_serviciosactivos", header)
      .then((response) => {
        setServiciosPosibles(response.data.data);
        setServicioSeleccionado(response.data.data[0].ID);
        setTextoSeleccionado(response.data.data[0].NOMBRE);
        setCargandoServicios(false);

      })
      .catch((err) => {
        console.log(err);
        setCargandoServicios(false);
      });
  }, []);

  const buscarServicios = () => {
    setCargandoServicios(true);
    setServicios([]);
    axiosClient
      .get("get_serviciospersonasactivos/" + servicioSeleccionado, header)
      .then((response) => {
        setTextoSeleccionadoMuestra(textoSeleccionado);
        setServicios(response.data.data);
        setCargandoServicios(false);
      })
      .catch((err) => {
        console.log(err);
        setCargandoServicios(false);
      });
  };

  const exportarAExcel = () => {
    if (servicios.length > 0 && !cargandoServicios) {
      setExportando(true);
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      axiosClient
        .get(
          "get_serviciospersonasactivoscondatos/" + servicioSeleccionado,
          header
        )
        .then((response) => {
          var data = [];
          var fecha = "";
          response.data.data.map((s) => {
            fecha = s.FechaNac.split("-");
            data.push({
              NOMBRE: s.Nombre,
              APELLIDO: s.Apellido,
              DNI: s.Dni,
              FECHA_NAC: fecha[2] + "/" + fecha[1] + "/" + fecha[0],
              SOCIO: s.Socio,
              VALOR_CUOTA: parseFloat(s.ValorCuota),
              MAIL: s.Mail,
              TELEFONO: s.Telefono,
            });
          });
          const worksheet = XLSX.utils.json_to_sheet(data);
          const workbook = {
            Sheets: { ReporteContratos: worksheet },
            SheetNames: ["ReporteContratos"],
          };
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          const preFile = new Blob([excelBuffer], { type: EXCEL_TYPE });
          saveAs(
            preFile,
            "Reporte_Contratos_Activos_" +
              textoSeleccionadoMuestra.replace(/ /g, "_") +
              EXCEL_EXTENSION
          );
          setExportando(false);
        })
        .catch((err) => {
          console.log(err);
          setExportando(false);
          setAlert(
            "Hubo un error exportando el listado a Excel, intentá más tarde",
            "red-300"
          );
        });
    }
  };

  useEffect(() => {
    if (textoSeleccionadoMuestra !== null) {
      buscarServicios();
    }
  }, [serviciosUpdate]);

  return (
    <div>
      <Nav />
      <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 lg:w-1/4">
        <h1 className="text-4xl bg-socios-primary text-white px-4 rounded py-2 ">
          SERVICIOS
        </h1>
        <p className="mt-4 text-lg text-left">
          Acá podés revisar todos los servicios activos en este momento
        </p>
      </div>
      <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 lg:w-1/4 flex flex-col">
        <button
          className={
            (serviciosPosibles.length !== 0)
              ? "flex lg:w-6/12 mb-2 bg-gray-500 text-white justify-center items-center h-8 border-2  border-socios-primary tracking-wide px-2 "
              : "flex lg:w-6/12 mb-2 bg-green-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-green-600  border-socios-primary cursor-not-allowed tracking-wide px-2"
          }
          onClick={() => {
            setModalNuevo(true);
          }}
          disabled={serviciosPosibles.length === 0}
        >
          Nuevo Servicio
        </button>
        <label className="font-bold text-left">Buscar por Servicio</label>
        <div className="justify-between gap-4 items-center flex mt-1 flex-row">
          <select
            className="border-2 w-3/4 bg-gray-200 px-1 py-1 border-socios-primary h-8"
            value={servicioSeleccionado}
            onChange={(e) => {
              setServicioSeleccionado(e.target.value);
              setTextoSeleccionado(
                e.target.options[e.target.selectedIndex].text
              );
            }}
            children={serviciosPosibles.map((servicio) => (
              <option value={servicio.ID}>{servicio.NOMBRE}</option>
            ))}
          ></select>
          <button
            className={
              serviciosPosibles.length === 0
                ? "w-1/4 h-8 border-2 transition duration-300 bg-gray-300 border-socios-primary tracking-wide cursor-not-allowed"
                : "w-1/4 h-8 border-2 transition duration-300 hover:bg-socios-primaryHover hover:text-white border-socios-primary tracking-wide"
            }
            onClick={buscarServicios}
            disabled={serviciosPosibles.length === 0}
          >
            Buscar
          </button>
        </div>
      </div>
      {!cargandoServicios && servicios.length > 0 ? (
        <div className="p-4 pb-0 gap-2 lg:gap-0 flex flex-col lg:items-center lg:flex-row lg:justify-between">
          <h1 className=" text-lg font-semibold">
            Cantidad de Contratos de tipo '{textoSeleccionadoMuestra}' activos:{" "}
            {servicios.length}
          </h1>
          <div className="flex flex-col lg:flex-row gap-2">
            <button
              className="flex flex-row gap-2 py-4 mb-2 bg-red-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-red-600  border-socios-primary tracking-wide px-6"
              onClick={() => {
                setModalBajaTotal(true);
              }}
            >
              {" "}
              <svg className="h-6 w-6" viewBox="0 0 24 24">
                <path
                  d="M13 9h5.5L13 3.5V9M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m4.5 9C8 11 6 13 6 15.5S8 20 10.5 20s4.5-2 4.5-4.5s-2-4.5-4.5-4.5m0 1.5a3 3 0 0 1 3 3c0 .56-.15 1.08-.42 1.5L9 12.92c.42-.27.94-.42 1.5-.42m-3 3c0-.56.15-1.08.42-1.5L12 18.08c-.42.27-.94.42-1.5.42a3 3 0 0 1-3-3z"
                  fill="currentColor"
                ></path>
              </svg>
              Baja Total{" "}
            </button>
            <button
              className={
                !exportando
                  ? "flex flex-row gap-2 py-4 mb-2 bg-green-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-green-600  border-socios-primary tracking-wide px-6"
                  : "flex flex-row gap-2 py-4 mb-2 bg-gray-500 text-white justify-center items-center h-8 border-2 border-socios-primary tracking-wide px-6 cursor-not-allowed"
              }
              onClick={() => exportarAExcel()}
              disabled={exportando}
            >
              {!exportando ? (
                <div className="flex flex-row gap-2">
                  <svg className="w-6 h-6" viewBox="0 0 24 24">
                    <path
                      d="M21.17 3.25q.33 0 .59.25q.24.24.24.58v15.84q0 .34-.24.58q-.26.25-.59.25H7.83q-.33 0-.59-.25q-.24-.24-.24-.58V17H2.83q-.33 0-.59-.24Q2 16.5 2 16.17V7.83q0-.33.24-.59Q2.5 7 2.83 7H7V4.08q0-.34.24-.58q.26-.25.59-.25M7 13.06l1.18 2.22h1.79L8 12.06l1.93-3.17H8.22L7.13 10.9l-.04.06l-.03.07q-.26-.53-.56-1.07q-.25-.53-.53-1.07H4.16l1.89 3.19L4 15.28h1.78m8.1 4.22V17H8.25v2.5m5.63-3.75v-3.12H12v3.12m1.88-4.37V8.25H12v3.13M13.88 7V4.5H8.25V7m12.5 12.5V17h-5.62v2.5m5.62-3.75v-3.12h-5.62v3.12m5.62-4.37V8.25h-5.62v3.13M20.75 7V4.5h-5.62V7z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Exportar a Excel
                </div>
              ) : (
                <div className="flex flex-row gap-2 items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Exportando...
                </div>
              )}
            </button>
          </div>
        </div>
      ) : null}
      {cargandoServicios && servicios.length === 0 ? (
        <div className="m-4 p-4 flex justify-center px-2 text-xl lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
          <p className="bg-socios-primary flex w-full lg:w-3/4 justify-center flex-col items-center text-white text-center lg:px-40 py-4 rounded">
            {textoSeleccionado !== null
              ? "Cargando Servicios"
              : "Estamos cargando todo"}
            <svg
              className="animate-spin mt-2 h-7 w-7 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </p>
        </div>
      ) : servicios.length > 0 ? (
        servicios.map((servicio) => (
          <BoxServicio
            key={servicio.FECHAINICIO}
            data={servicio}
            setIdSeleccionado={setIdSeleccionado}
            setModal={setModalEliminar}
            loading={cargandoServicios}
          />
        ))
      ) : (
        <div className="m-4 p-4 flex justify-center text-xl px-2 lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
          <div className="bg-socios-primary w-full lg:w-3/4 flex justify-center flex-col items-center text-white lg:px-40 text-center py-4 rounded">
            <p>
              {textoSeleccionadoMuestra !== null
                ? "No se encontraron servicios de Tipo '" +
                  textoSeleccionadoMuestra +
                  "'"
                : "Elegí un Servicio y hacé click en 'Buscar'"}
            </p>
            {textoSeleccionadoMuestra !== null ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 lg:h-10 lg:w-10 mt-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 lg:h-10 lg:w-10 mt-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            )}
          </div>
        </div>
      )}
      {modalEliminar ? (
        <ModalEliminar
          setModalEliminar={setModalEliminar}
          data={idSeleccionado}
        />
      ) : null}
      {modalNuevo ? (
        <ModalNuevo
          setModalNuevo={setModalNuevo}
          serviciosPosibles={serviciosPosibles}
        />
      ) : null}
      {modalNuevaPersona ? <ModalNuevaPersona /> : null}
      {modalBajaTotal ? (
        <ModalBajaTotal
          servicios={serviciosPosibles}
          nombreServicio={textoSeleccionadoMuestra}
        />
      ) : null}
    </div>
  );
}

export default Servicios;
