import { useState, useEffect, useContext } from "react";
import axiosClient from "../../../config/axios";
import Nav from "../../Nav";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import BoxMensual from "./BoxMensual";
import BoxServicios from "./BoxServicios";
import BoxTotales from "./BoxTotales";
import ModalDetalle from "./ModalDetalle";
import ModalFechaVencimiento from "./ModalFechaVencimiento";

function Liquidaciones({setFechaVenc}) {
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const buscadorContext = useContext(BuscadorContext);
  const {
    formatImporte,
    modalDetalleLiqui,
    modalFechaVencimiento,
    setModalDetalleLiqui,
    setDataDetalle,
    setModalFechaVencimiento,
    fechaVenc=null,
  } = buscadorContext;
  const [tipoSeleccionado, setTipoSeleccionado] = useState("mensual");
  const [cargandoLiquidaciones, setCargandoLiquidaciones] = useState(false);
  const [cargandoGeneracion, setCargandoGeneracion] = useState(false);
  const [liquidaciones, setLiquidaciones] = useState([]);
  const [update, setUpdate] = useState(null);
  const [detalleSeleccionado, setDetalleSeleccionado] = useState("todos");
  const endpoints = {
    mensual: "get_generacion_mensual_provisoria",
    servicios: "get_generacion_servicios_provisoria",
    mensual_add: "add_generacion_mensual_definitiva",
    servicios_add: "add_generacion_servicios_definitiva",
  };

  const cargarFechaVencimiento = () => {
    setModalFechaVencimiento(true);    
  }


  const consultarServicios = async () => {
    
      setCargandoLiquidaciones(true);
      await axiosClient
        .get(endpoints[tipoSeleccionado], header)
        .then((response) => {
          console.log(response);
          if (tipoSeleccionado === "mensual") {
            setLiquidaciones(response.data.data);
          } else {
            setLiquidaciones(response.data.data);
          }
          setCargandoLiquidaciones(false);          
        })
        .catch((err) => {
          console.log(err);
          setCargandoLiquidaciones(false);
          setAlert(
            "Hubo un error consultando las liquidaciones. Intentá más tarde",
            "red-300"
          );
        });
    
  };
  
 
  
  const generarLiquidacionDefinitiva = async () => {
    
     if (fechaVenc!==''){
      setCargandoGeneracion(true);
      await axiosClient
        .get(
          endpoints[tipoSeleccionado + "_add"] +
            "/" +
            liquidaciones.idLiquidacion + "/" + fechaVenc,
          header
        )
        .then((response) => {
          console.log(response);
          setCargandoGeneracion(false);
          setLiquidaciones([]);
          setAlert(
            "Se generó la liquidación definitiva correctamente.",
            "green-300"
          );
        })
        .catch((err) => {
          console.log(err);
          setCargandoGeneracion(false);
          setLiquidaciones([]);
          setAlert(
            "Hubo un error generando la liquidación. Intentá más tarde",
            "red-300"
          );
        });   
    } 
  };

  const updateResumen = (e) => {
    setDetalleSeleccionado(e.target.value);
  };

 /*  useEffect(() => {
    if (update !== null) {
      consultarServicios();
    }
  }, [update]); */
  
    useEffect(() => {
    if ((fechaVenc !== '')&&(fechaVenc !== null)) {
      generarLiquidacionDefinitiva();
    }
  }, [fechaVenc]); 

 

  return (
    <div>
      <Nav />
      <div className="flex flex-wrap lg:grid lg:grid-cols-3 2xl:grid-cols-4">
        <div className="lg:col-span-1 2xl:col-span-1">
          <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 lg:h-40">
            <h1 className="text-4xl bg-socios-primary text-white px-4 rounded py-2 ">
              LIQUIDACIONES
            </h1>
            <p className="mt-4 text-lg text-left">
              Acá podés revisar las liquidaciones de forma previsoria
            </p>
          </div>
          <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 flex flex-col lg:h-36">
            <label className="font-bold text-left">
              Seleccionar Tipo de Liquidación
            </label>
            <div className="justify-between gap-2 items-center flex mt-1 flex-row">
              <select
                className="border-2 w-3/4 bg-gray-200 px-1 py-1 border-socios-primary h-8"
                value={tipoSeleccionado}
                onChange={(e) => {
                  setTipoSeleccionado(e.target.value);
                }}
              >
                <option value="mensual">Mensual Provisoria</option>
                <option value="servicios">Servicios Provisoria</option>
              </select>
              <button
                className="border-2 flex items-center bg-gray-200 px-2 py-1 border-socios-primary h-8 transition duration-300 hover:bg-socios-primaryHover hover:text-white"
                onClick={() => consultarServicios()}
              >
                Previsualizar
              </button>
            </div>
            
          </div>
        </div>
        <div className="w-full lg:col-span-2 2xl:col-span-3">
          <div className="m-4 border-2 border-socios-primary px-4 py-2 lg:h-80">
            {!cargandoLiquidaciones && "idLiquidacion" in liquidaciones > 0 ? (
              <div className="gap-4">
                {" "}
                <div className="lg:col-span-1">
                  <h2 className="text-3xl font-bold">Resumen de Liquidación</h2>
                  <p className="font-bold text-left mt-2">
                    Seleccionar una liquidación
                  </p>
                  <select
                    className="border-2 bg-gray-200 mt-2 px-1 py-1 border-socios-primary h-8 w-full"
                    value={detalleSeleccionado}
                    onChange={(e) => updateResumen(e)}
                  >
                    <option value="todos">TOTAL A LIQUIDAR</option>
                    {liquidaciones.resumen.map((re) => (
                      <option value={re.Clave}>{re.nombre}</option>
                    ))}
                  </select>
                </div>
                <div className="lg:col-span-2 mt-4 lg:mt-8 flex flex-col justify-center items-center gap-4">
                  <p className="text-xl lg:text-2xl font-bold">
                    {detalleSeleccionado === "todos"
                      ? "TOTAL A LIQUIDAR"
                      : liquidaciones.resumen.filter(
                          (re) => re.Clave === parseInt(detalleSeleccionado)
                        )[0].nombre}{" "}
                    | PERÍODO {liquidaciones.periodo}
                  </p>
                  <p className="text-2xl font-bold">TOTAL:</p>
                  <p className="text-4xl lg:text-5xl font-bold">
                    {detalleSeleccionado === "todos"
                      ? formatImporte(liquidaciones.totalCompleto)
                      : formatImporte(
                          liquidaciones.resumen.filter(
                            (re) => re.Clave === parseInt(detalleSeleccionado)
                          )[0].total
                        )}{" "}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <h2 className="text-3xl font-bold">Resumen de Detalle</h2>
                <p className="bg-socios-primary flex justify-center flex-col items-center text-white text-center lg:px-40 py-4 rounded lg:text-3xl mt-20">
                  Previsualizá una liquidación primero
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        {!cargandoLiquidaciones &&
        liquidaciones.tablas !== undefined &&
        liquidaciones.tablas.length > 0 ? (
          liquidaciones.tipo === "MENSUAL" ? (
            <div>
              <p className="m-4 text-3xl font-bold">Liquidación Completa</p>
              {liquidaciones.tablas.map((l) => (
                <BoxMensual
                  data={l}
                  idLiquidacion={liquidaciones.idLiquidacion}
                />
              ))}
              <BoxTotales data={liquidaciones} tipoLiqui={tipoSeleccionado} />
            </div>
          ) : liquidaciones.tipo === "SERVICIO" ? (
            <div>
              <p className="m-4 text-3xl font-bold">Liquidación Completa</p>
              {liquidaciones.tablas.map((l) => (
                <BoxServicios
                  data={l}
                  idLiquidacion={liquidaciones.idLiquidacion}
                />
              ))}
              <BoxTotales data={liquidaciones} tipoLiqui={tipoSeleccionado} />
            </div>
          ) : null
        ) : cargandoLiquidaciones ? (
          <div className="m-4 p-4 flex justify-center px-2 text-xl lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
            <p className="bg-socios-primary flex w-full lg:w-3/4 justify-center flex-col items-center text-white text-center lg:px-40 py-4 rounded">
              Cargando Liquidaciones
              <svg
                className="animate-spin mt-2 h-7 w-7 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </p>
          </div>
        ) : (
          <div className="m-4 p-4 flex justify-center px-2 text-xl lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
            <p className="bg-socios-primary flex w-full lg:w-3/4 justify-center flex-col items-center text-white text-center lg:px-40 py-4 rounded">
              Para previsualizar la liquidación, seleccioná un tipo y hacé click
              en "Previsualizar"
            </p>
          </div>
        )}
        {!cargandoLiquidaciones &&
        liquidaciones.tablas !== undefined &&
        liquidaciones.tablas.length > 0 ? (
          <div className="p-4">
            <button
              className={
                !cargandoGeneracion
                  ? "w-full border-2 border-socios-primary text-white text-lg bg-green-500 py-2 items-center flex justify-center transition duration-300 hover:bg-green-600"
                  : "w-full border-2 border-socios-primary text-white text-lg bg-gray-500 py-2 items-center flex justify-center cursor-not-allowed"
              }
              disabled={cargandoGeneracion}
              onClick={() => cargarFechaVencimiento()}
              
            >
              {!cargandoGeneracion ? (
                "GENERAR LIQUIDACIÓN DEFINITIVA"
              ) : (
                <svg
                  className="animate-spin h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        ) : null}
      </div>
      {modalDetalleLiqui ? <ModalDetalle /> : null}
      {modalFechaVencimiento ? <ModalFechaVencimiento /> : null}
                
    </div>
  );
}

export default Liquidaciones;
