import React, { useContext, useState, useEffect } from "react";
import axiosClient from "../../../config/axios";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import AuthContext from "../../../context/auth/authContext";
import AlertContext from "../../../context/alerts/alertContext";

function ModalAceptar() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    dataSolicitud,
    setModalAceptar,
    setUpdateSolicitudes,
    updateSolicitudes,
  } = buscadorContext;
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [fechaIngreso, setFechaIngreso] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [categoria, setCategoria] = useState("cargando");
  const [observaciones, setObservaciones] = useState("");
  const [datosAlert, setDatosAlert] = useState({
    mostrar: false,
    mensaje: "Hubo un error, intentá más tarde",
  });
  const [loading, setLoading] = useState(false);

  const [categorias, setCategorias] = useState([
    {
      clave: "cargando",
      nombre: "Cargando...",
    },
  ]);

  useEffect(() => {
    axiosClient
      .get("get_solicitud_datos_sugeridos/" + dataSolicitud.id, header)
      .then((response) => {
        var cates = {
          AC: "Activo",
          CA: "Cadete",
          JU: "Jugador",
        };
        var catesArray = [];
        response.data.data.categoria.map((cate) => {
          catesArray.push({ clave: cate, nombre: cates[cate] });
        });
        setCategoria(catesArray[0].clave);
        setCategorias(catesArray);
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const aceptarSolicitud = () => {
    if (fechaIngreso === "" || categoria === "cargando") {
      if (fechaIngreso === "") {
        setDatosAlert({
          mostrar: true,
          mensaje: "Por favor, ingresá una fecha de Ingreso",
        });
      } else {
        setDatosAlert({
          mostrar: true,
          mensaje: "Por favor, esperá a que se carguen las categorías",
        });
      }
      return false;
    } else {
      setLoading(true);
      var payload = {
        fechaIngreso: fechaIngreso,
        observaciones: observaciones,
        categoria: categoria,
      };
      axiosClient
        .post("set_solicitud_aceptada/" + dataSolicitud.id, payload, header)
        .then((response) => {
          console.log(response);
          setLoading(false);
          setAlert("Solicitud aceptada exitosamente", "green-300");
          setUpdateSolicitudes(!updateSolicitudes);
          setModalAceptar(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
          setDatosAlert({
            mostrar: true,
            mensaje:
              err.response.data.data.length < 20
                ? err.response.data.data
                : "Hubo un error, intentá más tarde",
          });
        });
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">
                Aceptar Solicitud
              </h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Por favor, confirmá si querés aceptar la solicitud
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalAceptar(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          {datosAlert.mostrar ? (
            <div className="bg-red-500 p-4 mt-4 mb-0 m-4 lg:m-12 lg:mb-0 lg:mt-4 border-socios-primary border-2 text-md text-white font-bold flex justify-between items-center">
              <p className="flex flex-row items-center gap-2 lg:gap-1">
                <svg className="w-6 h-6" viewBox="0 0 24 24">
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                    fill="currentColor"
                  ></path>
                </svg>{" "}
                {datosAlert.mensaje}
              </p>
              <svg
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  setDatosAlert({ mostrar: false, mensaje: "" });
                }}
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H5V5h14v14M17 8.4L13.4 12l3.6 3.6l-1.4 1.4l-3.6-3.6L8.4 17L7 15.6l3.6-3.6L7 8.4L8.4 7l3.6 3.6L15.6 7L17 8.4z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          ) : null}

          <div className="border-4 border-socios-primary ml-4 mr-4 lg:ml-12 lg:mr-12 mt-4 pb-6 mb-4">
            <h1 className="text-lg text-center p-4 mt-2 font-bold">
              ¿Desea aceptar la solicitud?
            </h1>
            <div className="flex justify-center text-lg">
              {" "}
              <ul className="p-6 pt-1 pb-1 m-4 mt-0 w-full flex justify-center flex-col items-start list-disc border-2 border-socios-primary bg-white font-bold">
                <li>
                  Nombre: {dataSolicitud.nombre + " " + dataSolicitud.apellido}
                </li>
                <li>DNI: {dataSolicitud.dni}</li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-center">
                <div className="flex flex-col justify-center w-full ml-4 mr-4">
                  <label className="font-bold" htmlFor="fechaIngreso">
                    Fecha de Ingreso *
                  </label>
                  <input
                    className="border-2 bg-gray-200 px-1 py-1 border-socios-primary h-8"
                    id="fechaIngreso"
                    type="date"
                    min={new Date().toISOString().slice(0, 10)}
                    value={fechaIngreso}
                    onChange={(e) => {
                      setFechaIngreso(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col justify-center w-full ml-4 mr-4">
                  <label className="font-bold" htmlFor="selectCate">
                    Categoría *
                  </label>
                  <select
                    className="border-2 bg-gray-200 px-1 py-1 border-socios-primary h-8"
                    id="selectCate"
                    children={categorias.map((cate) => (
                      <option value={cate.clave}>{cate.nombre}</option>
                    ))}
                    value={categoria}
                    onChange={(e) => {
                      setCategoria(e.target.value);
                    }}
                  ></select>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex flex-col justify-center w-full ml-4 mr-4">
                  <label className="font-bold" htmlFor="observaciones">
                    Observaciones
                  </label>
                  <input
                    className="border-2 bg-gray-200 px-1 py-1 border-socios-primary h-8"
                    id="observaciones"
                    type="text"
                    placeholder="Observaciones"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-200 flex lg:flex-row flex-wrap gap-4 lg:gap-2 justify-evenly my-2 px-4 lg:px-0 pb-4 sm:p-5 sm:pb-4">
            <button
              className={
                loading
                  ? "px-12 bg-gray-500 text-white py-2 border-socios-primary border-2 cursor-not-allowed w-full lg:w-5/12"
                  : "px-12 bg-green-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-green-600 lg:w-5/12 w-full"
              }
              onClick={() => {
                aceptarSolicitud();
              }}
              disabled={loading}
            >
              {loading ? "Procesando..." : "Aceptar"}
            </button>
            <button
              className={
                loading
                  ? "px-12 bg-gray-500 text-white py-2 border-socios-primary border-2 cursor-not-allowed w-full lg:w-5/12"
                  : "px-12 bg-red-500 text-white py-2 border-socios-primary border-2 transition duration-300 hover:bg-red-600 w-full lg:w-5/12"
              }
              onClick={() => {
                setModalAceptar(false);
              }}
              disabled={loading}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ModalAceptar;
