import React, { useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import BuscadorContext from "../../context/buscador/buscadorContext";

function Buscador() {
  const buscadorContext = useContext(BuscadorContext);
  const { buscarPersonaNYAP, buscarPersonaEspecifica, loadingBusqueda } =
    buscadorContext;

  const [tipoPersona, setTipoPersona] = useState("F");
  const [tipoDato, setTipoDato] = useState("dni");
  const [tipoDatoMostrado, setTipoDatoMostrado] = useState("DNI");
  const [datoABuscar, setDatoABuscar] = useState("");

  const location = useLocation();
  let history = useHistory();
  const dniSocio = new URLSearchParams(location.search).get("dniSocio");
  const l = new URLSearchParams(location.search).get("l");

  if (dniSocio !== null) {
    localStorage.setItem("dniSocio", dniSocio);
  }

  if (l !== null) {
    localStorage.setItem("l", l);
  }

  useEffect(() => {
    var dniSocioLS = localStorage.getItem("dniSocio");
    var l = localStorage.getItem("l");
    if (l !== null) {
      history.push("/socios");
      localStorage.removeItem("l");
    }
    if (dniSocioLS !== null) {
      setDatoABuscar(dniSocioLS);
      buscarPersonaEspecifica({ tipo: "F", dni: dniSocioLS });
      //localStorage.removeItem("dniSocio");
    }
  }, []);

  useEffect(() => {
    var selectDato = document.getElementById("tipo_dato");
    setTipoDato(selectDato.value);
    setTipoDatoMostrado(selectDato.options[selectDato.selectedIndex].text);
  }, [tipoPersona]);

  const getDataSelectValue = (e) => {
    setTipoDato(e.target.value);
    setTipoDatoMostrado(e.target.options[e.target.selectedIndex].text);
    setDatoABuscar("");
  };

  const updateInput = (e) => {
    setDatoABuscar(e.target.value);
  };

  const buscarPersona = (e) => {
    e.preventDefault();
    var data = {
      tipo: tipoPersona,
      [tipoDato]: datoABuscar,
    };
    if (tipoDato === "dni" || tipoDato === "nroSocio") {
      buscarPersonaEspecifica(data);
    } else {
      buscarPersonaNYAP(data);
    }
  };

  return (
    <div className="mb-3 mt-3">
      <form id="form_busqueda">
        <label className="font-mono font-bold" htmlFor="tipo_dato">
          BUSCAR POR
        </label>
        <select
          id="tipo_dato"
          name="tipo_dato"
          className="mt-1 block w-full text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
          onChange={getDataSelectValue}
        >
          <option value={tipoPersona === "F" ? "dni" : ""}>
            {tipoPersona === "F" ? "DNI" : ""}
          </option>
          <option value={tipoPersona === "F" ? "nroSocio" : ""}>
            {tipoPersona === "F" ? "Numero de Socio" : ""}
          </option>
          <option value={tipoPersona === "F" ? "ayn" : ""}>
            {tipoPersona === "F" ? "Nombre y Apellido" : ""}
          </option>
        </select>
        <label className="font-mono font-bold">{tipoDatoMostrado}</label>
        <input
          id="input_dato"
          name={tipoDato}
          value={datoABuscar}
          onChange={updateInput}
          placeholder={tipoDatoMostrado}
          className="mt-1 block w-full text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out duration-300 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
          disabled={loadingBusqueda}
        ></input>
        <button
          type="submit"
          className={
            datoABuscar === ""
              ? "cursor-not-allowed mt-3 bg-gray-400 border-2 border-gray-400 text-center px-7 text-white uppercase px-8 text-sm py-1"
              : loadingBusqueda
              ? "cursor-not-allowed mt-3 border-2 border-socios-primary bg-socios-primary text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"
              : "mt-3  mt-3 border-2 border-socios-primary bg-socios-primary text-white hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"
          }
          onClick={buscarPersona}
          disabled={datoABuscar === "" || loadingBusqueda}
        >
          {loadingBusqueda ? (
            <div className="flex items-center">
              <svg
                className="animate-spin ml-3 h-5 w-5 text-white mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <div>Buscar</div>
            </div>
          ) : (
            "BUSCAR"
          )}
        </button>
      </form>
    </div>
  );
}
export default Buscador;
