import React, { useContext, useEffect, useState } from 'react';
import AllObservaciones from './AllObservaciones';
import AuthContext from '../../../../context/auth/authContext'
import axiosClient from '../../../../config/axios';
import papel from '../../../../assets/img/Observaciones.png'

export default function Observaciones({ setObservaciones }) {
    const authContext = useContext(AuthContext);
    const [obs, setObs] = useState([]);
    const [confirmacion, setConfirmacion] = useState(null);
    const [all, setAll] = useState(false);
    const { header,servicioSelect } = authContext;
    useEffect(() => {
        
        const body = {
            idPunto: servicioSelect.nombre,
            cantidad: '50'
        }

        axiosClient
            .post("get_ps_observaciones", body, header)
            .then((response) => {
                let fecha=new Date();
                response.data.data.forEach(element => {
                    fecha=new Date(element.FECHAHORA)
                    let hrs=new Date(fecha).getHours();
                    let min=new Date(fecha).getMinutes();
                    fecha=new Date(fecha).toISOString().split('T')[0];
                    if(hrs<10){hrs='0'+hrs};
                    if(min<10){min='0'+min};
                    element.FECHAHORA=fecha+' '+hrs+':'+min;
                });
                setObs(response.data.data)
            })

    }, [])

    return (
        <>
            <div className="fixed z-10 inset-0 overflow-y-auto py-0">
                <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <div
                        className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        {
                            all ? <AllObservaciones setAll={setAll} /> : (
                                <div className="flex items-center justify-around flex-col p-2">
                                    <div className="flex items-center justify-between w-full">
                                        <div className='flex items-center flex-row'>
                                            <div className='rounded-full flex items-center justify-center m-2' style={{ width: '3rem', height: '3rem' }}>
                                                <img src="https://cdn-icons-png.flaticon.com/512/622/622218.png" alt="notas" className='w-3/4' />
                                            </div>
                                            <p>Observaciones</p>
                                        </div>
                                        <button onClick={() => setObservaciones(false)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                                            <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                                        </button>
                                    </div>
                                    {
                                        confirmacion ?
                                            <div className='border-2 border-2 border-black rounded-full p-1 m-4 flex items-center justify-center' style={{ width: '8rem', height: '8rem' }}>
                                                <img className='w-5/6' src="https://cdn-icons.flaticon.com/png/512/5290/premium/5290611.png?token=exp=1643119841~hmac=5484203dce6d29bdaf181767cffc5bb6" alt="marca de verificación" />
                                            </div>
                                            : (
                                                <>
                                                    <textarea className='w-3/4 my-4 rounded-md' rows={4} style={{ resize: 'none' }} />
                                                    <button className='rounded-md text-white bg-black py-2 px-3' onClick={() => setConfirmacion(true)}>Guardar</button>
                                                </>
                                            )
                                    }
                                    
                                    <div className="flex justify-between mt-8 w-full">
                                        {
                                            obs.length ? obs.map(el => {
                                                
                                                return (
                                                    <div key={el.FECHAHORA} className='flex flex-col justify-center items-center'>
                                                        <h2 className="text-sm">{el.OBSERVACIONES} {el.FECHAHORA}</h2>
                                                        <img className='w-1/3' src={papel} alt="papel" />
                                                    </div>
                                                )
                                            }) : null
                                        }
                                        
                                    </div>
                                    <div className='flex justify-end w-full my-4 mt-8 px-4'>
                                        <button onClick={() => setAll(true)} className='rounded-md text-white bg-grey py-1 px-5'>Ver más</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}