import React, { useEffect } from 'react';

export default function DetalleEstadisticas({ setSeleccionado, seleccionado, setEstadisticas }) {
    const sociosMap = (obj) => {
        console.log(obj)
        if(!Array.isArray(obj)) {
            for (const el in obj) {
                return (
                    <li className='flex items-center justify-start my-2'>
                        <h2>{el.Ayn}</h2>
                        <p className="mx-2"> - </p>
                        <p>{el.FechaHora}</p>
                    </li>
                )
            }
        } else {
            return obj.map(el => {
                return (
                    <li key={el.Ayn} className='flex items-center justify-start my-2'>
                        <h2>{el.Ayn}</h2>
                        <p className="mx-2"> - </p>
                        <p>{el.FechaHora}</p>
                    </li>
                )
            })
        }
    }
    return (
        <>
            <div className='flex justify-end'>
                <button
                    onClick={() => {
                        setSeleccionado(null)
                        setEstadisticas(false)
                    }}
                    className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                </button>
            </div>
            <div className='p-2 pt-0'>
                <div className='m-auto w-4/5 p-3 flex justify-start items-center bg-white rounded-lg'>
                    <h2 className='mx-2 w-1/6'>{seleccionado.Cantidad}</h2>
                    <p> | </p>
                    <p className='text-sm mx-4'>{seleccionado.Descripcion}</p>
                </div>
                <div className='mx-auto mt-5 w-4/5 p-3 flex justify-start items-center bg-white rounded-lg' >
                    <ol className='overflow-y-scroll w-full' style={{ maxHeight: '20vh' }}>
                        {sociosMap(seleccionado.Socios)}
                    </ol>
                </div>
                <div className='flex justify-start items-center'>
                    <button onClick={() => setSeleccionado(null)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/130/130882.png" alt="regresar" className='w-1/3' />
                    </button>
                </div>
            </div>
        </>
    )
}