import React from 'react';

export default function Weather() {
    return (
        <div className='flex justify-center items-center w-1/2'>
            <div className='flex flex-col justify-center items-center'>
                <p className='text-5xl'>27 °</p>
                <span>Máxima</span>
            </div>
            <img src="https://cdn-icons.flaticon.com/png/512/4398/premium/4398440.png?token=exp=1642791879~hmac=ed24da709fcc5674144abac09ff3d8cb" alt="soleado" className='w-1/4 ml-2 self-center justify-self-center	' />
        </div>
    )
}