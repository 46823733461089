import React from "react";

function MainButton({
  fullBackground,
  onClick,
  texto
}) {
  return (
    <button
      onClick={onClick}
      className={fullBackground ? "border-2 border-socios-primary bg-socios-primary text-white uppercase px-8 text-sm py-1 hover:bg-socios-primaryHover hover:border-socios-primaryHover transition duration-300 ease-in-out" : "border-2 border-socios-primary text-socios-primary hover:bg-socios-primary hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"}
    >
      {texto}
    </button>
  );
}
export default MainButton;
