import { useState, useEffect, useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import imagen_default from "../../../assets/img/persona_sin_imagen.png";
import EmptyData from "../../UiComponents/EmptyData";
import Scanner from "react-webcam-qr-scanner";
import ModalAsistencia from "./ModalAsistencia";
import Wizard from "./Wizard";
function BodyAsistencia() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    datosSocio,
    buscarPersonaEspecifica,
    agregarAsistencia,
    datosPersona,
  } = buscadorContext;
  const [semaforoSelected, setSemaforoSelected] = useState("R");
  const [modalAsistencia, setModalAsistencia] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [mostrarVideo, setMostrarVideo] = useState(false);
  const [url, setUrl] = useState("");


  // const data = {
  //   socio: {
  //     "APELLIDO": "FRONTINI",
  //     "CATEGORIA": "Activo",
  //     "ESTADO": "ACTIVO",
  //     "NOMBRE": "NICOLAS",
  //     "NROSOCIO": 3288,
  //     "NRO_DOCUMENTO": "22716054",
  //     "URL": "https://s3.amazonaws.com/fya.socios.imagenes/public/FOTOCARNET_22716054_2021-12-13T19:06:57.",
  //     "TRANSEUNTE": 1
  //   }
  // }

  const calcularEdad = (birthDate) =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

  useEffect(() => {
    if (datosSocio) {
      setSemaforoSelected(datosSocio.semaforo);
    } else {
      setSemaforoSelected("V"); //default
    }
  }, [datosSocio]);

  useEffect(() => {
    setPage(1)
  }, [])

  const handleGenerarAsistencia = () => {
    setModalAsistencia(semaforoSelected === "R");
    if (semaforoSelected != "R") {
      var valores = {
        idPersona: datosPersona.ID_PERSONA,
        obs: "",
        mail: "",
      };
      agregarAsistencia(valores, setModalAsistencia);
    }
  };

  const [page, setPage] = useState(1)

  const handlePage = (num) => {
    return setPage(page + num)
  }

  const handleDecode = (result) => {
    setUrl(result.data);
    setMostrarVideo(false);
    var dniSocioLS = /[^/]*$/.exec(result.data)[0];
    buscarPersonaEspecifica({ tipo: "F", dni: dniSocioLS });
    document.getElementById("input_dato").value = dniSocioLS;
  };

  const handleScannerLoad = (mode) => { };

  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    return (
      <div className="ml-4 mt-4">
        <div className="mb-6">
          <p className="font-extrabold">Datos de Socio</p>
          <p className="text-gray-500">
            {datosSocio.socio.APELLIDO + " " + datosSocio.socio.NOMBRE} - D.N.I.{" "}
            {datosSocio.socio.NRO_DOCUMENTO}
          </p>
          <p className="text-gray-500">
            EDAD: {calcularEdad(datosSocio.socio.FECHANAC)} AÑOS
          </p>
        </div>
        <div className="flex flex-row gap-4">
          <div className="relative">
            <img
              className="h-60"
              onLoad={() => setLoaded(true)}
              src={
                datosSocio.imagenes.length === 0
                  ? imagen_default
                  : datosSocio.imagenes[0].url
              }
            />
            <div
              className={
                loaded
                  ? "absolute -bottom-1 -right-2 bg-black h-36 w-16 rounded-xl flex flex-col justify-evenly items-center"
                  : "hidden"
              }
            >
              <div
                className={
                  semaforoSelected == "R"
                    ? "rounded-full bg-semaforo-Rojo h-8 w-8"
                    : "rounded-full bg-semaforo-Rojo h-8 w-8 opacity-50"
                }
              ></div>
              <div
                className={
                  semaforoSelected == "A"
                    ? "rounded-full bg-semaforo-Amarillo h-8 w-8"
                    : "rounded-full bg-semaforo-Amarillo opacity-50 h-8 w-8"
                }
              ></div>
              <div
                className={
                  semaforoSelected == "V"
                    ? "rounded-full bg-semaforo-Verde h-8 w-8"
                    : "rounded-full bg-semaforo-Verde opacity-50 h-8 w-8"
                }
              ></div>
            </div>
          </div>
          <div className="border-black border-2 w-5/6 h-16 py-1.5 px-4">
            <p>NUMERO SOCIO</p>
            <p>{datosSocio.socio.NROSOCIO}</p>
            <label className="inline-flex items-center mb-4 mt-3">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-gray-600"
                disabled={true}
                checked={datosSocio.socio.TRANSEUNTE == 1 ? true : false}
                id="checkboxTranseunte"
              />
              <span className="ml-2 mt-0.5 text-md text-gray-800">TRANSEUNTE</span>
            </label>
          </div>
        </div>
        <div className="grid lg:flex gap-2">
          {mostrarVideo ? (
            <Scanner
              className="mt-3 border-2 border-socios-primary bg-socios-primary text-white hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"
              onDecode={handleDecode}
              onScannerLoad={handleScannerLoad}
              constraints={{
                audio: false,
                video: {
                  facingMode: "environment",
                },
              }}
              captureSize={{ width: 1280, height: 720 }}
            />
          ) : null}
          <button
            onClick={() => setMostrarVideo(true)}
            className="mt-3 border-2 border-socios-primary bg-socios-primary text-white hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"
          >
            Leer QR
          </button>
          <button
            onClick={() => handleGenerarAsistencia()}
            className="mt-3 border-2 border-socios-primary bg-socios-primary text-white hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out"
          >
            Generar Asistencia
          </button>
          {/*           <button className="mt-3 border-2 border-socios-primary bg-socios-primary text-white hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white uppercase px-8 text-sm py-1 transition duration-300 ease-in-out">
            Cobrar
          </button> */}
        </div>
        {modalAsistencia ? (
          <ModalAsistencia
            setModalAsistencia={setModalAsistencia}
            agregarAsistencia={agregarAsistencia}
          />
        ) : null}
      </div>
    );
  } else return null
}
export default BodyAsistencia;
