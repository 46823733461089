import React, { useContext } from "react";
import MainButton from "./UiComponents/MainButton";

import BuscadorContext from "../context/buscador/buscadorContext";

function BoxServicio({ data, botonBaja }) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatDate, formatImporte } = buscadorContext;

  return (
    <div className="p-4 border-2 border-socios-primary mb-4">
      <div className="xl:grid xl:grid-cols-3 xl:gap-4">
        <div className=" lg:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">NOMBRE SERVICIO</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.SERVICIO}
          </p>
        </div>
        <div className=" lg:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">FECHA DE ALTA</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatDate(data.FECHA)}
          </p>
        </div>
        <div className=" lg:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">IMPORTE</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatImporte(data.IMPORTE)}
          </p>
        </div>
        {/*         <div className="xl:mt-0 mt-5 flex items-center justify-end">
          <div>
            <MainButton
              fullBackground={true}
              onClick={botonBaja}
              texto={"Realizar Baja"}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default BoxServicio;
