import React, { useState, useContext } from 'react';
import axiosClient from '../../../../config/axios';
import AuthContext from '../../../../context/auth/authContext'
import Wizard from '../../Socios/Wizard';
import Scanner from "react-webcam-qr-scanner";

export default function DetalleBusqueda({ seleccionado, setSeleccionado, setBusqueda }) {
    const [user, setUser] = useState(' ');
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(1);
    

    // const [DNI, setDNI] = useState(null)

    const authContext = useContext(AuthContext);
    const { name, username, header, servicioSelect } = authContext;

    const onSubmit = (e) => {
        e.preventDefault();
        
        axiosClient
            .post("get_ps_persona", { idPuntoServicio: servicioSelect.nombre, dniPersona: user }, header)
            .then((response) => {

                if (response.data.persona[0]!=null) {
                    setSearch(response)
                    
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleDecode = async (result) => {
        // console.log(loading)
        
        // if (!loading) {
            const dni = result.data.split('/')[3]
            if (dni.length === 8) {
                try {
                    // REVEER NO FUNCIONA REALIZA MUCHAS PETICIONES
                    const data = await axiosClient
                    .post('get_personas', { tipo: "F", dni: dni }, header)
                    .then(response => {
                        console.log('hola')
                        setSearch(response.data.data[0])
                    })
                    return data
                    // axiosClient
                    //     .post('get_personas', { tipo: "F", dni: dni }, header)
                    //     .then(response => {
                    //         setSearch(response.data.data[0])
                    //     })
                } catch (err) {
                    //setLoading(false)
                    console.log(err)
                }
            }
        // } else return null
    }

    const handleScannerLoad = (mode) => {
        console.log(mode);
    }

    return (
        search ? (
            <  >
                <Wizard data={search} page={page} handlePage={setPage} setBusqueda={setBusqueda} setSearch={setSearch} setSeleccionado={setSeleccionado}  />
            </>
        ) : (
            <>
                <div className='flex justify-end'>
                    <button
                        onClick={() => {
                            setSeleccionado(null)
                            setBusqueda(false)
                        }}
                        className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                    </button>
                </div>
                {
                    seleccionado === "QR" ? (
                        <>
                            <div className='w-2/3 mx-auto my-5' style={{ height: '20rem' }}>
                                <Scanner
                                    className="w-full"
                                    onDecode={handleDecode}
                                    onScannerLoad={handleScannerLoad}
                                    constraints={{
                                        audio: true,
                                        video: {
                                            facingMode: "environment"
                                        }
                                    }}
                                    captureSize={{ width: 1280, height: 720 }}
                                />
                            </div>
                            <div className='flex justify-between items-center mx-2'>
                                <button onClick={() => setSeleccionado(null)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '1' }}>
                                    <img src="https://cdn-icons-png.flaticon.com/512/130/130882.png" alt="regresar" className='w-1/3' />
                                </button>
                                <button onClick={() => setSeleccionado('NRO_SOCIO_DNI')} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                                    <img className='w-1/2' src="https://cdn-icons-png.flaticon.com/512/25/25308.png" alt="teclado" />
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='w-1/2 my-5 flex items-center flex-col justify-center m-auto'>
                                <h2>N° de Socio / N° de DNI</h2>
                                <input type="number" name="search" onChange={(e) => setUser(e.target.value)} value={user}  onKeyPress={(e)=>{if(e.key==='Enter'){onSubmit(e)} }} style={{appearance: 'textfield'}} className="p-2 rounded-md bg-black opacity-50 text-white my-4" />
                                <button className="bg-black opacity-80 text-white p-2 w-1/3 rounded-md" onClick={e => onSubmit(e)}>BUSCAR</button>
                            </div>
                            <div className='flex justify-start items-center mx-2'>
                                <button onClick={() => setSeleccionado(null)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '1' }}>
                                    <img src="https://cdn-icons-png.flaticon.com/512/130/130882.png" alt="regresar" className='w-1/3' />
                                </button>
                            </div>
                        </>
                    )
                }
            </>
        )
    )
}