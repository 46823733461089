import React from "react";

export default function ItemEstadistica({ el, itemSelect }) {
    return (
        <button
            key={el.id}
            value={el.id}
            onClick={() => itemSelect(el.id)}
            className='m-auto p-3 w-full flex justify-start items-center bg-slate_600 rounded-lg opacity-2.5'
        >
            <h2 className='mx-2 w-1/6'>{el.Cantidad}</h2>
            <p> | </p>
            <p className='text-sm mx-2'>{el.Descripcion}</p>
        </button>
    )
}