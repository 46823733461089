import React, { useContext, useState, useEffect } from "react";
import BuscadorContext from "../../context/buscador/buscadorContext";

function Resultados() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    setPersonasBuscadas,
    personasBuscadas,
    getDatosSocio,
    loadingBusqueda,
    setDatosPersona,
    buscarServiciosSocio,
    buscarDatosTablas,
  } = buscadorContext;

  return (
    <div className="mb-3">
      <h1 className="font-bold text-xl mb-3">Personas</h1>
      {loadingBusqueda ? (
        <h1 className="font-bold text-xl text-gray-500 mb-3 text-center">
          Cargando
        </h1>
      ) : null}
      {personasBuscadas.length > 0
        ? personasBuscadas.map((p) => (
            <div key={p.ID + p.ID_SOCIO}>
              <button
                key={p.ID}
                className={p.ESTADO === "A" ? "border-2 border-black p-2 w-full text-left mb-3" : "p-2 w-full text-left mb-3 bg-greylight"}
                onClick={() => {
                  setDatosPersona(p);
                  setPersonasBuscadas({});
                  getDatosSocio(p.ID_PERSONA);
                  buscarServiciosSocio(p.ID_PERSONA);
                  buscarDatosTablas(p.ID_PERSONA);
                }}
              >
                <p className="font-bold">{p.APELLIDO + " " + p.NOMBRE}</p>
                <p className="font-mono">DNI: {p.NRO_DOCUMENTO}</p>
                <p className="font-mono">Socio: {p.NROSOCIO} </p>
              </button>
            </div>
          ))
        : null}
    </div>
  );
}
export default Resultados;
