import React, { useContext, useEffect, useState } from "react";
import ToggleButton from "../UiComponents/ToggleButton";
import EmptyData from "../UiComponents/EmptyData";
import Formulario from "../UiComponents/Formulario";
import BuscadorContext from "../../context/buscador/buscadorContext";
import AlertContext from "../../context/alerts/alertContext";

function DatosPersonales() {
  const buscadorContext = useContext(BuscadorContext);
  const { editarDatosPersonales, datosSocio, datosTablas } = buscadorContext;
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [editar, setEditar] = useState(true);

  //useEffect(() => {}, [datosSocio]);

  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    const { NOMBRE, APELLIDO, TIPO_DOCUMENTO, NRO_DOCUMENTO, FECHANAC, SEXO } = datosSocio.socio;

    const generos = datosTablas.SEXO;

    const formData = {
      cantColumnas: 2,
      textoSubmit: "GUARDAR",
      style:
        "mt-1 block w-full leading-6 text-sm text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out disabled:bg-gray-400 disabled:text-gray-900 disabled:border-gray-500 duration-300 opacity-100",
      styleButton:
        "bg-green-500 disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-200 text-white uppercase px-8 text-sm py-1 border-green-500 border-2 hover:bg-green-600 hover:border-green-600 transition duration-300 ease-in-out",
      styleButtonSubmitting:
        "bg-gray-500 px-4 py-2 m-2 mt-4 flex justify-center text-gray-200 w-40",
      styleLabel: "uppercase text-gray-800",
      disabled: editar,
      campos: [
        {
          nombre: "Nombre",
          apiName: "nombre",
          type: "text",
          required: true,
          placeholder: "Nombre",
          value: NOMBRE,
        },
        {
          nombre: "Apellido",
          apiName: "apellido",
          type: "text",
          required: true,
          placeholder: "Apellido",
          value: APELLIDO,
        },
        {
          nombre: "Fecha de Nacimiento",
          apiName: "fechaNac",
          type: "date",
          required: true,
          placeholder: "dd-mm-yyyy",
          value: FECHANAC,
          min: "1900-01-01",
          max: new Date().toISOString().slice(0, 10),
        },
        {
          nombre: "Género",
          apiName: "genero",
          type: "select",
          value: SEXO.slice(0, 1),
          opciones: generos,
        },
      ],
    };

    const handleEditar = (valores, setSubmitting) => {
      editarDatosPersonales(valores);
      setEditar(true);
      setSubmitting(false);
    };

    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-lg font-bold text-gray-700">
              Datos Personales
            </h1>
            <p className="font-medium font-mono text-gray-500">
              {NOMBRE} {APELLIDO} - {TIPO_DOCUMENTO} {NRO_DOCUMENTO}
            </p>
          </div>
          <div>
            <ToggleButton
              texto={"Editar"}
              value={editar}
              setEditar={setEditar}
            />
          </div>
        </div>
        <div>
          <Formulario data={formData} funcionForm={handleEditar} />
        </div>
      </div>
    );
  } else {
    return <EmptyData texto={"Buscar un socio para comenzar"} />;
  }
}
export default DatosPersonales;
