import { useFormik } from "formik";

function Formulario(props) {
  let initValues = {};
  props.data.campos.map((campo) => {
    if (campo.type === "select") {
      if (campo.value) {
        initValues[campo.apiName] = campo.value;
      } else {
        initValues[campo.apiName] = campo.opciones
          ? campo.opciones[0].clave
          : "";
      }
    } else {
      if (campo.value) {
        initValues[campo.apiName] = campo.value;
      } else {
        initValues[campo.apiName] = "";
      }
    }
    if (campo.type === "textarea") {
      if (campo.value) {
        initValues[campo.apiName] = campo.value;
      } 
      
    } 
  });
  const formik = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      props.funcionForm(values, setSubmitting);
    },
    
  });
  
  


  return (
    <div>
      {/*       {props.data.titulo || props.data.subtitulo ? (
        <div className="flex justify-between border-b-2 p-4 m-4 mb-0 pb-0 border-gray-500">
          <div>
            <h1 className="font-bold text-xl">{props.data.titulo}</h1>
            <h2 className="font-medium text-gray-400 mb-3">
              {props.data.subtitulo}
            </h2>
          </div>
          <button
            onClick={() => {
              props.funcionCierre();
            }}
            className="bg-gray-200 border-2 border-gray-400 px-1 h-8 mt-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      ) : null} */}

      <div>
        <form id={props.data.id} onSubmit={formik.handleSubmit}>
          <div
            className={`flex flex-wrap lg:grid gap-${
              props.data.gap ? props.data.gap : 1
            } lg:grid-cols-${props.data.cantColumnas}`}
          >
            {props.data.campos.map((campo) => (
              <div
                key={campo.apiName}
                className={
                  !campo.span
                    ? "flex flex-col w-full"
                    : `flex flex-col w-full col-span-${campo.span}`
                }
              >
                <label
                  className={
                    props.data.styleLabel
                      ? props.data.styleLabel
                      : "font-mono uppercase font-bold"
                  }
                  htmlFor={campo.apiName}
                >
                  {campo.nombre}
                </label>
                {campo.type !== "select" && campo.type !== "textarea" ? (
                  <input
                    className={
                      campo.style ? campo.style : props.data.style + " h-10"
                    }
                    id={campo.apiName}
                    name={campo.apiName}
                    type={campo.type}
                    onChange={formik.handleChange}
                    minLength={campo.minLen}
                    maxLength={campo.maxLen}
                    min={campo.min}
                    max={campo.max}
                    required={campo.required}
                    placeholder={campo.placeholder}
                    value={formik.values[campo.apiName]}
                    disabled={
                      props.data.disabled
                        ? props.data.disabled
                        : formik.isSubmitting
                    }
                    accept={campo.accept}
                  />
                ) : campo.type === "select" ? (
                  <select
                    className={props.data.style + " h-10"}
                    id={campo.apiName}
                    name={campo.apiName}
                    onChange={campo.onChange || formik.handleChange}
                    value={campo.dinamicValue || formik.values[campo.apiName]}
                    children={
                      campo.opciones
                        ? campo.opciones.map((opcion) => (
                            <option
                              key={opcion.clave + opcion.nombre}
                              value={opcion.clave}
                            >
                              {opcion.nombre}
                            </option>
                          ))
                        : null
                    }
                    disabled={
                      props.data.disabled
                        ? props.data.disabled
                        : formik.isSubmitting
                    }
                  />
                ) : (
                  <textarea
                    className={props.data.style}
                    rows={campo.rows ? campo.rows : 3}
                    id={campo.apiName}
                    name={campo.apiName}
                    minLength={campo.min}
                    maxLength={campo.max}
                    required={campo.required}
                    placeholder={campo.placeholder}
                    onChange={formik.handleChange}
                    value={formik.values[campo.apiName]}
                    disabled={
                      props.data.disabled
                        ? props.data.disabled
                        : formik.isSubmitting
                    }
                  ></textarea>
                )}
              </div>
            ))}
          </div>

          <div className="flex justify-center items-center md:justify-end">
            <button
              type="submit"
              className={
                props.data.styleButton
                  ? formik.isSubmitting
                    ? props.data.styleButtonSubmitting
                      ? props.data.styleButtonSubmitting
                      : "bg-gray-500 transition duration-300 flex justify-center hover:bg-gray-500 px-6 py-2 uppercase m-2 text-white w-full text-center lg:w-32 2xl:w-1/12 cursor-not-allowed"
                    : props.data.styleButton
                  : formik.isSubmitting
                  ? "bg-gray-500 transition duration-300 flex justify-center hover:bg-gray-500 px-6 py-2 uppercase m-2 text-white w-full text-center lg:w-32 2xl:w-1/12 cursor-not-allowed"
                  : "bg-green-500 transition duration-300 hover:bg-green-600 px-6 py-2 uppercase m-2 text-white w-full text-center lg:w-32 2xl:w-1/12"
              }
              disabled={
                props.data.disabled ? props.data.disabled : formik.isSubmitting
              }
              id={props.data.submitButtonID}
            >
              {formik.isSubmitting ? (
                <svg
                  className="animate-spin h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                props.data.textoSubmit
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Formulario;
