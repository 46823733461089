import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import BuscadorContext from "../../context/buscador/buscadorContext";

function SideNav() {
  const buscadorContext = useContext(BuscadorContext);
  const { datosSocio, loadingBusqueda } = buscadorContext;
  //obtiene la locación para dejar la clase tipo "active" para la vista
  const location = window.location.pathname;
  const ctacteOnline = process.env.REACT_APP_CTACTE_ONLINE;

  const jwtToken = localStorage.getItem("token");

  useEffect(() => { }, [datosSocio]);

  const Items = [
    {
      nombre: "Datos de Socio",
      url: "/",
    },
    {
      nombre: "Datos Personales",
      url: "/datos-personales",
    },
    {
      nombre: "Datos de Contacto",
      url: "/datos-contacto",
    },
    {
      nombre: "Direcciones",
      url: "/direcciones",
    },
    {
      nombre: "Servicios",
      url: "/servicios",
    },
  ];

  if (loadingBusqueda) {
    return <div></div>;
  } else {
    return (
      <div className="w-full text-center">
        {Items.length > 0 && location != "/socios"
          ? Items.map((i) => (
            <Link
              key={i.nombre}
              className={
                location === i.url
                  ? "block border-2 border-socios-primary text-white bg-socios-primary px-6 py-2 mb-4 transition duration-300 ease-in-out"
                  : "block border-2 border-socios-primary text-socios-primary px-6 py-2 mb-4 hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white transition duration-300 ease-in-out"
              }
              to={i.url}
            >
              {i.nombre}
            </Link>
          ))
          : null}
        {location != "/socios" ? (
          ctacteOnline !== "false" && datosSocio !== null ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={
                process.env.REACT_APP_CTACTE_URL +
                `loginJWT?dniPersona=${datosSocio.socio.NRO_DOCUMENTO
                }&nombrePersona=${datosSocio.socio.APELLIDO + " " + datosSocio.socio.NOMBRE
                }&jwt=${jwtToken}`
              }
              className="block border-2 border-socios-primary text-socios-primary px-6 py-2 mb-4 hover:bg-socios-primaryHover hover:border-socios-primaryHover hover:text-white transition duration-300 ease-in-out"
            >
              {/* MODIFICAR POR ROL */}
              CTACTE
            </a>
          ) : (
            <button
              className="block border-2 bg-gray-400 text-center border-socios-primary text-gray-700 px-6 py-2 mb-4 w-full cursor-not-allowed transition duration-300 ease-in-out"
              disabled={true}
            >
              CARGANDO ...
            </button>
          )
        ) : null}
      </div>
    );
  }
}
export default SideNav;
