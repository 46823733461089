import React, { useState, useEffect, useContext } from "react";
import Body from "./Body";
import Nav from "./Nav";
import axiosClient from '../../../config/axios';
import AuthContext from '../../../context/auth/authContext';

export default function PuntoServicio() {
    const [open, setOpen] = useState(null);
    const authContext = useContext(AuthContext);
    const { header, servicioSelect , PuntoServicio} = authContext;

    useEffect(() => {
        // GET estado del servicio seleccionado
        if(servicioSelect!=0){
            axiosClient
            .post("get_ps_estado",{idPunto: servicioSelect.nombre}, header)
            .then((response) => {
                setOpen(!!response.data.data.ESTADO)
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, []);

    return (
        <div className="flex flex-col justify-start">
            <Nav open={open} setOpen={setOpen} />
            <Body open={open} />
        </div>
    )
}