import { useEffect, useState, useContext } from "react";
import AuthContext from "../../../context/auth/authContext";
import BuscadorContext from "../../../context/buscador/buscadorContext";
import Nav from "../../Nav";
import BoxSolicitud from "./BoxSolicitud";
import axiosClient from "../../../config/axios";
import ModalAceptar from "./ModalAceptar";
import ModalRechazar from "./ModalRechazar";
import ModalVer from "./ModalVer";

function Solicitudes() {
  const authContext = useContext(AuthContext);
  const { header } = authContext;
  const buscadorContext = useContext(BuscadorContext);
  const { modalAceptar, modalVer, modalRechazar, updateSolicitudes } =
    buscadorContext;
  const [solicitudes, setSolicitudes] = useState([]);
  const [cargandoSolicitudes, setCargandoSolicitudes] = useState(true);
  const [estadoSeleccionado, setEstadoSeleccionado] = useState("");
  const [textoSeleccionado, setTextoSeleccionado] = useState("Todas");
  const [textoSeleccionadoMuestra, setTextoSeleccionadoMuestra] =
    useState("Todas");

  const estados = [
    {
      key: "",
      value: "Todas",
    },
    {
      key: "00",
      value: "Sin Definir",
    },
    {
      key: "01",
      value: "Pedida",
    },
    {
      key: "02",
      value: "A Considerar",
    },
    {
      key: "03",
      value: "Aceptada",
    },
    {
      key: "04",
      value: "Rechazada",
    },
    {
      key: "05",
      value: "Cargada",
    },
  ];

  useEffect(() => {
    if (!cargandoSolicitudes) {
      filtrarSolicitudes();
    }
  }, [updateSolicitudes]);

  useEffect(() => {
    var payload = {
      estado: "",
    };
    axiosClient
      .post("get_solicitudes", payload, header)
      .then((response) => {
        setSolicitudes(response.data.data);
        setCargandoSolicitudes(false);
      })
      .catch((err) => {
        console.log(err);
        setCargandoSolicitudes(false);
      });
  }, []);

  const filtrarSolicitudes = () => {
    setCargandoSolicitudes(true);
    setSolicitudes([]);
    var payload = {
      estado: estadoSeleccionado,
    };
    axiosClient
      .post("get_solicitudes", payload, header)
      .then((response) => {
        setTextoSeleccionadoMuestra(textoSeleccionado);
        setSolicitudes(response.data.data);
        setCargandoSolicitudes(false);
      })
      .catch((err) => {
        console.log(err);
        setCargandoSolicitudes(false);
      });
  };

  return (
    <div>
      <Nav />
      <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 lg:w-1/4">
        <h1 className="text-4xl bg-socios-primary text-white px-4 rounded py-2 ">
          SOLICITUDES
        </h1>
        <p className="mt-4 text-lg text-left">
          Acá podés revisar todas las solicitudes registradas hasta el momento
        </p>
      </div>
      <div className="m-4 border-2 border-socios-primary lg:text-left text-center p-4 lg:w-1/4 flex flex-col">
        <label className="font-bold">Filtrar por Estado</label>
        <div className="justify-between gap-4 items-center flex mt-1 flex-row">
          <select
            className="border-2 w-3/4 bg-gray-200 px-1 py-1 border-socios-primary h-8"
            value={estadoSeleccionado}
            onChange={(e) => {
              setEstadoSeleccionado(e.target.value);
              setTextoSeleccionado(
                e.target.options[e.target.selectedIndex].text
              );
            }}
            children={estados.map((e) => (
              <option value={e.key}>{e.value}</option>
            ))}
          ></select>
          <button
            className="w-1/4 h-8 border-2 transition duration-300 hover:bg-socios-primaryHover hover:text-white border-socios-primary tracking-wide"
            onClick={filtrarSolicitudes}
          >
            Filtrar
          </button>
        </div>
      </div>

      {cargandoSolicitudes && solicitudes.length === 0 ? (
        <div className="m-4 p-4 flex justify-center px-2 text-xl lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
          <p className="bg-socios-primary flex w-full lg:w-3/4 justify-center flex-col items-center text-white text-center lg:px-40 py-4 rounded">
            Cargando Solicitudes
            <svg
              className="animate-spin mt-2 h-7 w-7 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </p>
        </div>
      ) : solicitudes.length > 0 ? (
        solicitudes.map((solicitud) => (
          <BoxSolicitud
            estados={estados}
            data={solicitud}
            loading={cargandoSolicitudes}
          />
        ))
      ) : (
        <div className="m-4 p-4 flex justify-center text-xl px-2 lg:text-3xl border-2 border-socios-primary lg:py-20 flex-col items-center h-40 lg:h-80">
          <div className="bg-socios-primary w-full lg:w-3/4 flex justify-center flex-col items-center text-white lg:px-40 text-center py-4 rounded">
            <p>
              No se encontraron solicitudes con estado "
              {textoSeleccionadoMuestra}"
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 lg:h-10 lg:w-10 mt-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      )}
      {modalAceptar ? <ModalAceptar /> : null}
      {modalRechazar ? <ModalRechazar /> : null}
      {modalVer ? <ModalVer estados={estados} /> : null}
    </div>
  );
}

export default Solicitudes;
