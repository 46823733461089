import React, { useState, useContext } from "react";
import BuscadorContext from "../context/buscador/buscadorContext";
import MainButton from "./UiComponents/MainButton";

function BoxDirecciones({ data, botonEditar }) {
  const buscadorContext = useContext(BuscadorContext);
  const { datosTablas } = buscadorContext;
  const [openData, setOpenData] = useState(false);
  const {
    ID,
    TIPO_DIRE,
    ESTADO,
    CALLE,
    NUMERO,
    PISO,
    DEPARTAMENTO,
    NOMBREPAIS,
    CODPAIS,
    NOMBREPROVINCIA,
    CP,
    TIPOCOMPLEMENTO,
    COMPLEMENTO,
    DESCRIPCION,
    OBSERVACIONES,
  } = data;

  console.log(data);

  return (
    <>
      <div className="border-2 border-socios-primary p-4 mb-4">
        <div className="xl:grid xl:grid-cols-5 gap-4">
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">TIPO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {TIPO_DIRE.toUpperCase()}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">DIRECCIÓN</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {CALLE + " Nº" + NUMERO}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">PISO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {PISO}
            </p>
          </div>
          <div className=" xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">DEPARTAMENTO</p>
            <p className="text-sm font-mono font-medium text-gray-600">
              {DEPARTAMENTO}
            </p>
          </div>
          <div className="hidden xl:flex xl:items-center xl:justify-end">
            <div className="mr-2">
              <MainButton
                fullBackground={true}
                onClick={botonEditar}
                texto={"EDITAR"}
              />
            </div>
            <div>
              <MainButton
                fullBackground={true}
                onClick={() => setOpenData(!openData)}
                texto={
                  !openData ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )
                }
              />
            </div>
          </div>
        </div>
        {openData ? (
          <div className="mt-4 border-t-2 border-gray-500">
            <div className="xl:grid xl:grid-cols-5 gap-4 mt-3">
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">ESTADO</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {ESTADO.toUpperCase()}
                </p>
              </div>
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">PAIS</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {
                    datosTablas.PAIS.filter(
                      (pais) => pais.nombre === NOMBREPAIS
                    )[0]["nombre"]
                  }
                </p>
              </div>
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">PROVINCIA</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {
                    datosTablas.PROVINCIA.filter(
                      (prov) => prov.nombre === NOMBREPROVINCIA
                    )[0]["nombre"]
                  }
                </p>
              </div>
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">CÓDIGO POSTAL</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {CP}
                </p>
              </div>
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">COMPLEMENTO</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {TIPOCOMPLEMENTO + " " + COMPLEMENTO}
                </p>
              </div>
              <div className=" xl:m-0 mb-2">
                <p className="text-sm font-bold text-gray-800">DESCRIPCIÓN</p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {DESCRIPCION}
                </p>
              </div>
            </div>
            {OBSERVACIONES !== "" ? (
              <div className="bg-gray-300 p-4 mt-4">
                <p className="text-sm font-bold text-gray-800">
                  OBSERVACIONES:
                </p>
                <p className="text-sm font-mono font-medium text-gray-600">
                  {OBSERVACIONES}
                </p>
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="xl:hidden flex items-center justify-items-start mt-4">
          <div className="mr-2">
            <MainButton
              fullBackground={true}
              onClick={botonEditar}
              texto={"EDITAR"}
            />
          </div>
          <div>
            <MainButton
              fullBackground={true}
              onClick={() => setOpenData(!openData)}
              texto={
                !openData ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default BoxDirecciones;
