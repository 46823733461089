import React, { useState, useContext } from 'react';
import FrontiniLogo from '../../../assets/img/frontini-logo.svg'
import AuthContext from '../../../context/auth/authContext'
import axiosClient from '../../../config/axios';

export default function Nav({ open, setOpen }) {
    const authContext = useContext(AuthContext);
    const { header,servicioSelect,  estadoPtoServicio, setEstadoPtoServicio} = authContext;

    if(estadoPtoServicio.estado=="1"){
        open=true;
    }else{
        open=false;
    }

    const handleSubmit = (e) => {
        setOpen(!open);
        if(e.target.name=="0"){
            setEstadoPtoServicio(0); 
            
            const body = {
                idPunto: servicioSelect.nombre,
                estado: "0",
                observacion: "se cierra"                
            }   
            axiosClient
            .post('set_ps_estado', body, header)
            .then(response => {                
                console.log(response)
            }) 
        }
    }
    return (
        <div className='m-5 flex justify-between'>
            <div className='mx-4 my-2 w-1/2 flex justify-start'>
                <img src={FrontiniLogo} alt="frontini-logo" className='w-1/4' />
            </div>
            <div className='mx-4 my-2 w-1/6 flex justify-end'>
                {
                    !open ?
                        <div className="w-full flex justify-between items-center">
                            <p className='font-light'>
                                Abierto última vez ayer 20:51 hr.
                            </p>
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/32/32533.png"
                                alt="puerta-cerrada"
                                className="w-1/4"
                                name="1"
                                onClick={e=> handleSubmit(e)}
                            />
                        </div>
                        :
                        <div className="w-full flex justify-between items-center">
                            <p className='font-light'>
                                Abierto desde las 08:25 hr.
                            </p>
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/59/59805.png"
                                alt="puerta-abierta"
                                className="w-1/4"
                                name="0"
                                onClick={e=> handleSubmit(e)}
                            />
                        </div>
                }
            </div>
        </div>
    )
}