import { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function BoxTotales(props) {
  const buscadorContext = useContext(BuscadorContext);
  const { formatImporte } = buscadorContext;
  const data = props.data;
  const tipoLiquidacion = props.tipoLiqui;
  const cols = tipoLiquidacion === "mensual" ? 8 : 10;

  return (
    <div className="border-2 border-socios-primary m-4 p-4">
      <div className={`flex flex-col gap-4 lg:grid lg:grid-cols-${cols}`}>
        <div className={`xl:m-0 mb-2 col-span-6`}></div>
        {tipoLiquidacion === "servicios" ? (
          <div className="xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">SUBTOTAL SUMADO</p>
            <p className="text-lg font-mono font-bold text-gray-900">
              {formatImporte(data.subtotalCompleto)}
            </p>
          </div>
        ) : null}
        {tipoLiquidacion === "servicios" ? (
          <div className="xl:m-0 mb-2">
            <p className="text-sm font-bold text-gray-800">
              TOTAL BONIFICACIÓN
            </p>
            <p className="text-lg font-mono font-bold text-gray-900">
              {formatImporte(data.totalBonificacion)}
            </p>
          </div>
        ) : null}
        <div className="xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">TOTAL SUMADO</p>
          <p className="text-lg font-mono font-bold text-gray-900">
            {formatImporte(data.totalCompleto)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BoxTotales;
