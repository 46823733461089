import React from "react";

function ToggleButton({ texto, value, setEditar }) {
 
  
  return (
    <div className="flex items-center justify-end w-full">
      {texto ? <div className="mr-3 uppercase">{texto}</div> : null}
     {/*  {value ? <div>AAA</div> : <div>BBB</div> } */}
      <label htmlFor="toggleB" className="flex items-center cursor-pointer">
        <div className="relative">
          <input type="checkbox" id="toggleB" className="sr-only" checked={!value} onClick={() => setEditar(!value)} value={value}/>
          <div className="block bg-gray-900 w-14 h-8 "></div>
          <div className="dot absolute left-1 top-1 bg-white w-6 h-6  transition"></div>
        </div>
      </label>
    </div>
  );
}

export default ToggleButton;
