import React, { useContext, useEffect, useState } from 'react';
import axiosClient from '../../../../config/axios';
import AuthContext from '../../../../context/auth/authContext';
import ItemEstadistica from '../ItemEstadistica';
import DetalleEstadisticas from './DetallesEstadisticas';

export default function Estadisticas({ setEstadisticas }) {
    const authContext = useContext(AuthContext);
    const { header } = authContext;
    const [data, setData] = useState([])
    const [seleccionado, setSeleccionado] = useState(null);

    const itemSelect = (id) => {
        setSeleccionado(data.filter(x => x.id === id)[0])
    }

    useEffect(() => {
        try {
            axiosClient
                .post("get_ps_estadisticas", {} ,header)
                .then((response) => {
                    setData(response.data.data)
                })
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto py-0">
            <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    {
                        seleccionado ?
                            <DetalleEstadisticas setSeleccionado={setSeleccionado} seleccionado={seleccionado} setEstadisticas={setEstadisticas} /> : (
                                <>
                                    <div className='flex justify-end'>
                                        <button onClick={() => setEstadisticas(false)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                                            <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                                        </button>
                                    </div>
                                    <div className='grid grid-cols-2 gap-3 p-5'>
                                        {
                                            data.length ? data.map(el => {
                                                return (
                                                    <ItemEstadistica el={el} itemSelect={itemSelect} />
                                                )
                                            }) : null
                                        }
                                    </div>
                                </>
                            )
                    }
                </div>
            </div>
        </div>
    )
}