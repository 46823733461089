import React, { useContext, useState, useEffect } from "react";
import MainButton from "../UiComponents/MainButton";
import EmptyData from "../UiComponents/EmptyData";
import BuscadorContext from "../../context/buscador/buscadorContext";
import BoxServicio from "../BoxServicio";
import ModalNuevoServicio from "./Modals/ModalNuevoServicio";

function Servicios() {
  const buscadorContext = useContext(BuscadorContext);
  const {
    datosSocio,
    serviciosSocio,
    buscarNuevoServicioSocio,
    listaServicios,
    datosPersona,
    buscarServiciosSocio,
  } = buscadorContext;

  const [modalNuevoServicio, setModalNuevoServicio] = useState(false);
  const [cargandoServicios, setCargandoServicios] = useState(true);

  const handleClick = (servicio) => {
    alert("baja servicio");
  };

  useEffect(() => {
    buscarServiciosSocio(datosPersona.ID_PERSONA).then(() => {
      setCargandoServicios(false);
    });
  }, []);

  useEffect(() => {
    console.log("cambiando");
  }, [serviciosSocio]);

  if (
    datosSocio &&
    Object.keys(datosSocio).length !== 0 &&
    datosSocio.constructor === Object
  ) {
    const { NOMBRE, APELLIDO, ID_SOCIO, TIPO_DOCUMENTO, NRO_DOCUMENTO } =
      datosSocio.socio;
    const { ID_PERSONA } = datosPersona;
    return (
      <>
        <div className="p-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-lg font-bold text-gray-700">Servicios</h1>
              <p className="font-medium font-mono text-gray-500">
                {NOMBRE} {APELLIDO} - {TIPO_DOCUMENTO} {NRO_DOCUMENTO}
              </p>
            </div>
            <div>
              <MainButton
                fullBackground={true}
                onClick={() => {
                  setModalNuevoServicio(true);
                  buscarNuevoServicioSocio(ID_SOCIO);
                }}
                texto={"Nuevo"}
              />
            </div>
          </div>
          {cargandoServicios ? (
            <p className="pt-6 text-2xl font-bold">Cargando...</p>
          ) : null}
          {serviciosSocio.length > 0 && !cargandoServicios ? (
            <div className="mt-10">
              {serviciosSocio.map((s) => (
                <BoxServicio data={s} botonBaja={() => handleClick(s)} />
              ))}
            </div>
          ) : null}
        </div>
        {modalNuevoServicio ? (
          <ModalNuevoServicio
            setModalNuevoServicio={setModalNuevoServicio}
            data={listaServicios}
            idPersona={ID_PERSONA}
          />
        ) : null}
      </>
    );
  } else {
    return <EmptyData texto={"Buscar un socio para comenzar"} />;
  }
}
export default Servicios;
