import React from "react";
import Button from "../../UiComponents/Button";
import Formulario from "../../UiComponents/Formulario";

function ModalEditarEstado({ setModalEditarEstados, data, editarEstadoSocio,categoriasPosibles }) {
  const formData = {
    cantColumnas: 4,
    textoSubmit: "GUARDAR",
    style:
      "mt-1 block w-full leading-6 text-sm text-gray-600 bg-gray-100 border-2 border-gray-900 p-2 mb-4 transition ease-in-out disabled:bg-gray-400 disabled:text-gray-900 disabled:border-gray-500 duration-300 opacity-100",
    styleButton:
      "bg-green-500 disabled:bg-gray-500 disabled:border-gray-500 disabled:text-gray-200 text-white uppercase px-8 text-sm py-1 border-green-500 border-2 hover:bg-green-600 hover:border-green-600 transition duration-300 ease-in-out",
    styleButtonSubmitting:
      "bg-gray-500 px-4 py-2 m-2 mt-4 flex justify-center text-gray-200 w-40",
    styleLabel: "uppercase text-gray-800",
    disabled: false,
    campos: [
      {
        nombre: "Fecha",
        apiName: "fecha",
        type: "date",
        required: true,
        value: "",
        span: 2,
      },
      {
        nombre: "MOTIVO",
        apiName: "motivo",
        type: "select",
        span: 2,
        value: "",
        opciones: categoriasPosibles,
      },
      {
        nombre: "OBSERVACIONES",
        apiName: "observaciones",
        type: "textarea",
        span: 4,
        rows: 5,
        value: "",
      },
    ],
  };

  const handleEditar = (valores, setSubmitting) => {
    console.log("Hola");
    editarEstadoSocio(valores);
    setSubmitting(false);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto py-10">
      <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-2/6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="border-b-2 border-gray-500 p-4 flex items-center justify-between">
            <div>
              <h3 className="text-lg font-bold text-gray-800">Editar Estado</h3>
              <p className="text-sm font-mono font-medium text-gray-600">
                Editar Estado del Socio
              </p>
            </div>
            <div>
              <button
                className="border-2 border-gray-500 p-2"
                onClick={() => {
                  setModalEditarEstados(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-gray-200 px-4 my-2 pb-4 sm:p-6 sm:pb-4">
            <Formulario data={formData} funcionForm={handleEditar} />
          </div>
          {/*           <div className="border-t-2 border-gray-500 p-4 flex items-center justify-end">
            <Button
              onClick={() => alert("hola")}
              fullBackground={true}
              texto="guardar"
              background="green-500"
              hoverBackground="green-600"
              color="white"
              hoverColor="white"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default ModalEditarEstado;
