import { useContext } from "react";
import BuscadorContext from "../../../context/buscador/buscadorContext";

function BoxSolicitud(props) {
  const buscadorContext = useContext(BuscadorContext);
  const {
    formatDate,
    setDataSolicitud,
    setModalAceptar,
    setModalRechazar,
    setModalVer,
  } = buscadorContext;
  const data = props.data;

  const estadoSoli = props.estados.filter((e) => e.key === data.estado)[0][
    "value"
  ];

  return (
    <div className="border-2 border-socios-primary m-4 p-4">
      <div className="flex flex-col gap-4 lg:grid lg:grid-cols-6">
        <div className=" xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">ESTADO SOLICITUD</p>
          <p
            className={
              estadoSoli === "Aceptada"
                ? "text-sm border-socios-primary border font-bold font-mono bg-green-500 p-1 text-center  w-5/12 text-white"
                : estadoSoli === "Rechazada"
                ? "text-sm border-socios-primary border font-bold font-mono bg-red-500 p-1 text-center  w-5/12 text-white"
                : "text-sm border-socios-primary border font-bold font-mono bg-yellow-500 p-1 text-center  w-5/12 text-white"
            }
          >
            {estadoSoli}
          </p>
        </div>
        <div className=" xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">TIPO SOLICITUD</p>
          <p
            className={
              data.tipo === "Reincorporacion"
                ? "text-sm font-mono font-medium text-red-600"
                : "text-sm font-mono font-medium text-gray-600"
            }
          >
            {data.tipo === "Nuevo"
              ? "ALTA"
              : data.tipo !== null
              ? "REINC."
              : "NO ESPECIFICADA"}
          </p>
        </div>
        <div className=" xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">FECHA SOLICITUD</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {formatDate(data.fecha.date.slice(0, 10))}
          </p>
        </div>
        <div className=" xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">NOMBRE</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.nombre + " " + data.apellido}
          </p>
        </div>
        <div className=" xl:m-0 mb-2">
          <p className="text-sm font-bold text-gray-800">DNI</p>
          <p className="text-sm font-mono font-medium text-gray-600">
            {data.dni}
          </p>
        </div>

        <div className="xl:m-0 mb-2 flex justify-between gap-1">
          <button
            className="flex w-full mb-2 bg-blue-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-blue-600  border-socios-primary tracking-wide px-2"
            onClick={() => {
              setDataSolicitud(data);
              setModalVer(true);
            }}
          >
            Ver
          </button>
          <button
            className={
              estadoSoli === "Aceptada" || estadoSoli === "Rechazada"
                ? "flex w-full mb-2 bg-gray-500 cursor-not-allowed text-white justify-center items-center h-8 border-2 border-socios-primary tracking-wide px-2"
                : "flex w-full mb-2 bg-green-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-green-600  border-socios-primary tracking-wide px-2"
            }
            onClick={() => {
              setDataSolicitud(data);
              setModalAceptar(true);
            }}
            disabled={estadoSoli === "Aceptada" || estadoSoli === "Rechazada"}
          >
            Aceptar
          </button>
          <button
            className={
              estadoSoli === "Rechazada" || estadoSoli === "Aceptada"
                ? "flex w-full mb-2 bg-gray-500 text-white justify-center items-center h-8 border-2 cursor-not-allowed  border-socios-primary tracking-wide px-2"
                : "flex w-full mb-2 bg-red-500 text-white justify-center items-center h-8 border-2 transition duration-300 hover:bg-red-600  border-socios-primary tracking-wide px-2"
            }
            onClick={() => {
              setDataSolicitud(data);
              setModalRechazar(true);
            }}
            disabled={estadoSoli === "Aceptada" || estadoSoli === "Rechazada"}
          >
            Rechazar
          </button>
        </div>
      </div>
    </div>
  );
}

export default BoxSolicitud;
