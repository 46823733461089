import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/styles.css";
import "../src/assets/css/solicitudStyles.css";
import App from "./App";

/*
 * Agregar estilos si los borra el PurgeCSS en el build:
 * bg-red-300
 * bg-yellow-300
 * bg-green-300
 * bg-yellow-400
 * bg-purple-400
 * bg-green-400
 * grid-cols-1
 * grid-cols-2
 * grid-cols-3
 * grid-cols-4
 * grid-cols-5
 * grid-cols-6
 * grid-cols-7
 * grid-cols-8
 * grid-cols-9
 */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
