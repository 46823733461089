import React, { useState, useEffect } from 'react';
import axiosClient from '../../../../config/axios';
import AuthContext from '../../../../context/auth/authContext';

export default function Asistencia({ setAsistencia }) {
    const [data, setData] = useState([])

    useEffect(() => {

    }, [])

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto py-0">
            <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom bg-gray-200 text-left overflow-hidden shadow-xl lg:align-middle transform transition-all w-full lg:w-3/6"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className='flex justify-end w-full'>
                        <button onClick={() => setAsistencia(false)} className='rounded-full flex items-center justify-center' style={{ width: '5rem', height: '5rem', opacity: '.85' }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/1828/1828774.png" alt="Cerrar" className='w-1/3' />
                        </button>
                    </div>
                    <div className='grid grid-cols-1 grid-rows-1 gap-1 p-0 w-full'>
                        <div className='m-auto py-3 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2 className='text-red'> FUERA DE TIEMPO </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                        <div className='mx-auto my-2 mb-4 w-full flex justify-evenly items-center'>
                            <img src="https://cdn-icons-png.flaticon.com/512/4305/4305453.png" alt="reloj" style={{ width: '2rem' }} />
                            <div className='w-3/4 flex items-center'>
                                <h2> HOY </h2>
                                <p className='text-sm'>&nbsp; | &nbsp; Días de pileta / Nicolás Frontini</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}