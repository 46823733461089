import React, { useState } from 'react';

export default function Timer() {
    const [time, setTime] = useState(null);
    let day = new Date().getDay();

    const timer = () => {
        let minutes = new Date().getMinutes();
        let hours = new Date().getHours();
        if (minutes < 10) minutes = '0' + minutes;
        if (hours < 10) hours = '0' + hours;
        setTime(hours + ' : ' + minutes)
    }

    return (
        <div className='w-1/2 flex items-center justify-center'>
            <div style={{ display: 'none' }}>
                {setInterval(() => {
                    timer()
                }, 1500)}
            </div>
            <div className='flex flex-col'>
                <span className='text-5xl'>{time}</span>
                <span>
                    {
                        new Date().getDay() === 0 ? 'Domingo' :
                            new Date().getDay() === 1 ? 'Lunes' :
                                new Date().getDay() === 2 ? 'Martes' :
                                    new Date().getDay() === 3 ? 'Miércoles' :
                                        new Date().getDay() === 4 ? 'Jueves' :
                                            new Date().getDay() === 5 ? 'Viernes' : 'Sábado'
                    }
                    {', '}
                    {new Date().getDate() + ' '}
                    {
                        new Date().getMonth === 0 ? 'Enero' :
                            new Date().getMonth === 1 ? 'Febrero' :
                                new Date().getMonth === 2 ? 'Marzo' :
                                    new Date().getMonth === 3 ? 'Abril' :
                                        new Date().getMonth === 4 ? 'Mayo' :
                                            new Date().getMonth === 5 ? 'Junio' :
                                                new Date().getMonth === 6 ? 'Julio' :
                                                    new Date().getMonth === 0 ? 'Agosto' :
                                                        new Date().getMonth === 0 ? 'Septiembre' :
                                                            new Date().getMonth === 0 ? 'Octubre' :
                                                                new Date().getMonth === 0 ? 'Noviembre' : 'Diciembre'
                    }
                </span>
            </div>
        </div>
    )
}