import React from "react";
import LogoFrontini from "../assets/img/frontini-logo.svg";

function Footer() {
  return (
    <div className="flex justify-center border-t border-gray-400 py-2 bg-white">
      <div className="text-gray-800 text-center">
        <img src={LogoFrontini} className="h-14" alt="Logo Frontini y asoc" />
        <p className="font-bold text-sm">Frontini S.R.L</p>
        <p className="font-light text-sm">Dirección Calle 14 N° 622 1/2 </p>
        <p className="font-light text-sm">La Plata, Bs. As. – Argentina</p>
      </div>
    </div>
  );
}
export default Footer;
